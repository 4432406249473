import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommunicationService } from '@services/communication.service';
import { RestService } from '@services/rest.service';
import { EditorConfig } from '@utils/wysiwygEditorConfig';
import { envAccordion } from '@classes/envAccordion';
import { envContact } from '@classes/envContact';
import { envCommuneDate } from '@classes/envCommuneDate';
import { envPageData } from '@classes/envPageData';
import { Subject } from 'rxjs';
import { envPicture } from '@classes/envPicture';
import { environment } from '@environments/environment';

import { MatDialog } from '@angular/material/dialog';
import { AdmModalAssetsComponent } from '@modals/adm-modal-assets/adm-modal-assets.component';
import { ModalPromptComponent } from '@modals/modal-prompt/modal-prompt.component';

@Component({
    selector: 'app-accordion-edit',
    templateUrl: './accordion-edit.component.html',
    styleUrls: ['./accordion-edit.component.sass'],
})
export class AccordionEditComponent implements OnInit {
    @Input() accordionData: envAccordion;
    @Output() accordionDataChange = new EventEmitter();

    @Input() pageData: envPageData;
    @Input() communeLists: envCommuneDate[];
    @Output() communeListsChange = new EventEmitter();

    @Input() defaultAsset: any;
    @Input() type: string = 'default';

    contacts: envContact[];
    contactShell: envContact[];

    dataSource: Function;

    dateText: string;
    selectedItem: string = null;
    isDropdownActive: boolean = false;
    domElement: HTMLElement;
    placeholder = [
        {
            name: 'Downloads',
            args: null,
        },
        {
            name: 'EMail',
            args: ['an', 'betreff', 'text'],
        },
        {
            name: 'Amtsbezeichnung',
            args: null,
        },
        {
            name: 'Nutzer',
            args: null,
        },
        {
            name: 'Hebesatz',
            args: null,
        },
    ];

    private selectSubject: Subject<any>;
    restURL = environment.restBaseURL;

    constructor(
        private readonly communicationService: CommunicationService,
        private readonly restService: RestService,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        if (this.accordionData !== undefined) {
            this.contactShell = [
                {
                    name: '',
                    position: 1,
                    addressdata: '',
                    picture: this.defaultAsset,
                },
            ];
            if (this.accordionData.type === 'contactType') {
                this.contacts = this.accordionData.body.contacts !== '' ? this.accordionData.body.contacts : this.contactShell;
            }
            if (this.accordionData.type === 'dateType') {
                this.dateText = this.accordionData.body;
                this.selectedItem = this.communeLists[0].communeName;
            }
            this.reorderContactsArray();
        }
    }

    clearEditor(position: number) {
        const element = document.getElementsByClassName('ql-editor');
        element[+position + 1].innerHTML = '';
    }

    getModelChangeItem(tmpItem) {
        this.emitAccordionBody(tmpItem.value);
    }

    emitAccordionBody(body: string) {
        if (this.accordionData.type === 'dateType') {
            if (this.selectedItem === this.communeLists[0].communeName) {
                this.accordionData.body = body;
                this.accordionDataChange.emit(this.accordionData);
            } else {
                for (const commune of this.communeLists) {
                    if (commune.communeName === this.selectedItem) {
                        commune.dateText = body;
                    }
                }
                this.communeListsChange.emit(this.communeLists);
            }
        } else {
            this.accordionData.body = body;
            this.accordionDataChange.emit(this.accordionData);
        }
    }

    reorderContactsArray() {
        if (typeof this.contacts === 'undefined' || this.contacts.length <= 0) {
            return false;
        }
        this.contacts.sort((e1, e2) => {
            if (e1.position > e2.position) {
                return 1;
            }
            if (e1.position < e2.position) {
                return -1;
            }

            return 0;
        });
        this.contacts.forEach((el, i) => {
            el.position = i;
        });
    }
    setContacts(a: any[]): void {
        this.contacts = a;
    }

    voteContactUp(el: any): void {
        const currPos = el.position; // old position value
        const newPos = currPos - 1; // new position value
        const oldEl = this.contacts[newPos]; // old element on new position
        const newEl = el; // current element on old position
        // reset position flags for reordering in fn reorderAccordionArray
        oldEl.position = currPos;
        newEl.position = newPos;

        this.reorderContactsArray();
    }
    voteContactDown(el: any): void {
        const currPos = el.position; // old position value
        const newPos = currPos + 1; // new position value
        const oldEl = this.contacts[newPos]; // old element on new position
        const newEl = el; // current element on old position
        // reset position flags for reordering in fn reorderAccordionArray
        oldEl.position = currPos;
        newEl.position = newPos;

        this.reorderContactsArray();
    }

    deleteContact(el: any): void {
        const dialogRef = this.dialog.open(ModalPromptComponent, {
            width: 'auto',
            disableClose: true,
            data: {
                title: 'Ansprechpartner wirklich löschen?',
                bodyText: '',
                buttonText1: 'abbrechen',
                buttonClass1: 'btn btn-secondary',
                buttonText2: 'löschen',
                buttonClass2: 'btn btn-danger',
                isDecision: true,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined && result === 2) {
                this.deleteCallback(el);
            }
        });
    }

    deleteCallback(el: any): void {
        const index: number = this.contacts.indexOf(el, 0);
        if (index > -1) {
            this.contacts.splice(index, 1);
        }
        this.reorderContactsArray();
    }

    addContact(): void {
        if (this.contacts !== undefined) {
            this.contacts.push({
                name: '',
                position: this.contacts.length,
                addressdata: '',
                picture: this.defaultAsset,
            });
        } else {
            this.contacts = this.contactShell;
        }

        this.reorderContactsArray();
    }

    setPosition = function (pos: number): void {
        this.contacts.position = pos;
    };
    getPosition = function (): number {
        return this.accordionData.position;
    };

    // funktion zum auswählen des Kontacktbilder oder anderer asstets
    editFiles(curFile: envPicture, type: string, contactPosition: number): void {
        // let files = this.restService.getAdminFiles(type);
        this.selectSubject = new Subject<any>();
        const currentFile = curFile;
        this.communicationService.setCurrentfile(curFile);

        // subscribe to event
        this.selectSubject.subscribe({
            next: (file) => {
                if (typeof file !== 'undefined') {
                    // copy some props:
                    currentFile.name = file.name;
                    currentFile.id = file.id;
                    currentFile.url = file.url;
                    currentFile.type = file.type;
                    currentFile.date = file.date;
                    this.selectSubject.unsubscribe();
                }
            },
        });

        // öffnet asset dialog
        const dialogRef = this.dialog.open(AdmModalAssetsComponent, {
            width: 'auto',
            data: {
                type,
                currentFile: curFile,
                headlineText: 'Ansprechpartner editieren',
                useSimpleUpload: false,
            },
        });

        // ausgewähltes asset hinterlegen
        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                if (result.type === 'CONTACT') {
                    this.accordionData.body.contacts[contactPosition].picture = result.selectedFile;
                }
                // this.toastr.error('Die hochgeladene Datei hat nicht die selbe Dateiendung.')
            }
        });
    }

    setCommuneDateText(commune) {
        if (commune === null || commune === '' || commune === this.communeLists[0].communeName) {
            this.dateText = this.accordionData.body;
        } else {
            for (const currCommune of this.communeLists) {
                if (currCommune.communeName === commune) {
                    this.dateText = currCommune.dateText;
                }
            }
        }
    }

    // Dropdown schließen / öffnen
    changeDropdown() {
        this.isDropdownActive = !this.isDropdownActive;
        this.domElement = document.getElementById('envCustomer');
        const customerElem = document.getElementById('customer');

        if (!this.isDropdownActive) {
            this.domElement.classList.remove('activeList');
            this.domElement.classList.add('inactiveList');
        } else {
            this.domElement.classList.remove('inactiveList');
            this.domElement.classList.add('activeList');
        }
        if (this.selectedItem === undefined || this.selectedItem === null || this.selectedItem.trim() === '') {
            customerElem.classList.add('invalidField');
        } else {
            customerElem.classList.remove('invalidField');
        }
    }

    // setzt das angeklickte feld im Dropdown und wechselt die animation
    setActiveDropdown(commune) {
        this.selectedItem = commune;
        this.setCommuneDateText(commune);
        this.changeDropdown();
    }
}
