<app-content-header></app-content-header>
<div class="envPageContentWrapper">
    @if (!wasEmailSend && !userIsLocked) {
    <div class="envPageContent">
        <div class="envContentText">
            Bitte geben Sie Ihren Benutzernamen für das KommunalPortal in das Eingabefeld ein und bestätigen Sie mit dem Button "Neues
            Initial-Passwort zusenden". Wir senden Ihnen umgehend ein neues Initial-Passwort an Ihre E-Mail-Adresse.
        </div>
        <div class="row">
            <div class="col"></div>
            <div class="col-auto envForgotAccessInputWrapper">
                <div class="envMailInputWrapper">
                    <input
                        [(ngModel)]="username"
                        class="envForgotInput"
                        id="usernameInput"
                        [ngClass]="isUsernameFieldEmpty ? 'envEmptyInputField' : ''"
                        placeholder="Ihr Benutzername" />
                </div>
                <button class="envMailButton" (click)="setInput('username')">neues Initial-Passwort zusenden</button>
                <!-- <button class="envMailButton" (click)="setInput('username')">Benutzernamen erneut zusenden</button> -->
            </div>
            <div class="col"></div>
        </div>
        <div class="row">
            <div class="col"></div>
            <div class="envContentHeaderSubheadline col-auto envRowPlaceholder">Benutzername vergessen</div>
            <div class="col"></div>
        </div>
        <div class="envContentText">
            Bitte geben Sie Ihre E-Mail-Adresse für das KommunalPortal in das Eingabefeld ein und bestätigen Sie mit dem Button
            "Benutzernamen zusenden". Wir senden Ihnen umgehend Ihren Benutzernamen an Ihre E-Mail-Adresse.
        </div>
        <div class="row">
            <div class="col"></div>
            <div class="col-auto envForgotAccessInputWrapper">
                <div class="envMailInputWrapper">
                    <input
                        [(ngModel)]="mail"
                        class="envForgotInput"
                        id="mailInput"
                        [ngClass]="isMailFieldEmpty ? 'envEmptyInputField' : ''"
                        placeholder="Ihre E-Mail-Adresse" />
                </div>
                <button class="envMailButton" (click)="setInput('mail')">Benutzernamen zusenden</button>
                <!-- <button class="envMailButton" (click)="setInput('username')">Benutzernamen erneut zusenden</button> -->
            </div>
            <div class="col"></div>
        </div>
    </div>
    } @if (wasEmailSend && !userIsLocked) {
    <div class="envPageContent row">
        <div class="col"></div>
        <div class="col-auto envContentHelpText">
            Es wurde eine E-Mail an die angegebene Adresse gesendet. Bitte überprüfen Sie Ihr E-Mail-Fach.
        </div>
        <div class="col"></div>
    </div>
    } @if (!wasEmailSend && userIsLocked) {
    <div class="envPageContent row">
        <div class="col"></div>
        <div class="col-auto envContentHelpText">
            Ihr Benutzer ist gesperrt. Bitte wenden Sie sich an einen KommunalPortal Administrator.
        </div>
        <div class="col"></div>
    </div>
    }
</div>
<!-- <ng-template #emailSend>
<div class="envPageContent row">
  <div class="col"></div>
  <div class="col-auto envContentHelpText">
    Es wurde eine E-Mail an die angegebene Adresse gesendet. Bitte überprüfen Sie Ihr E-Mail-Fach.
  </div>
  <div class="col"></div>
</div>
</ng-template>
<ng-template #userLocked>
  <div class="envPageContent row">
    <div class="col"></div>
    <div class="col-auto envContentHelpText">
      Ihr Benutzer ist gesperrt. Bitte wenden Sie sich an einen KommunalPortal Administrator.
    </div>
    <div class="col"></div>
  </div>
</ng-template> -->
