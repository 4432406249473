import { Component, OnInit, Input, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { envAccordion } from '@classes/envAccordion';
import { envPageData } from '@classes/envPageData';
import { envContact } from '@classes/envContact';
import { envCommuneDate } from '@classes/envCommuneDate';
import { RestService } from '@services/rest.service';

import { MatDialog } from '@angular/material/dialog';
import { ModalPromptComponent } from '@modals/modal-prompt/modal-prompt.component';

@Component({
    selector: 'app-accordion-edit-manager',
    templateUrl: './accordion-edit-manager.component.html',
    styleUrls: ['./accordion-edit-manager.component.sass'],
})
export class AccordionEditManagerComponent implements OnInit {
    @Input() accordionData: envAccordion[];
    @Input() newTitle: string;
    @Input() pageData: envPageData;
    @Input() communeLists: envCommuneDate[];
    @Input() type: string = 'default';
    @Input() accordionCategory: string = undefined;

    defaultAsset: any = undefined;

    constructor(private readonly restService: RestService, public dialog: MatDialog) {}

    ngOnInit() {
        this.newTitle = typeof this.newTitle === 'undefined' ? 'Neue Klappbox anlegen' : this.newTitle;
        if (this.type === 'contact') {
            this.restService.getDefaultAsset('CONTACT');
            this.restService.$defaultAssetResponse.subscribe((data) => {
                if (data !== undefined) {
                    if (data.id === 'CONTACT') {
                        this.defaultAsset = data;
                        this.restService.resetDefaultAssetResponse();
                        this.setData();
                    }
                }
            });
        } else {
            this.setData();
        }
    }

    setData() {
        if (this.type === 'contact' && this.pageData.siteName === 'propertyManagement') {
            if (this.accordionData === undefined || this.accordionData.length !== 0 || this.accordionData.length === 0) {
                const tempContact: any = {
                    headline: '',
                    position: 0,
                    isOpen: false,
                    hasDownloads: false,
                    downloads: [],
                    body: {
                        name: '',
                        position: 0,
                        addressdata: '',
                        picture: this.defaultAsset,
                    },
                    type: 'contactType',
                };
                this.accordionData.push(tempContact);
            }
        }
        this.reorderAccordionArray();
    }

    reorderAccordionArray() {
        if (typeof this.accordionData === 'undefined' || this.accordionData.length <= 0) {
            return false;
        }

        this.accordionData.sort((e1, e2) => {
            if (e1.position > e2.position) {
                return 1;
            }
            if (e1.position < e2.position) {
                return -1;
            }

            return 0;
        });
        this.accordionData.forEach((el, i) => {
            el.position = i;
        });
    }
    setAccordionData(a: envAccordion[]): void {
        this.accordionData = a;
    }

    voteAccordionUp(el: envAccordion): void {
        const currPos = el.position; // old position value
        const newPos = currPos - 1; // new position value
        const oldEl = this.accordionData[newPos]; // old element on new position
        const newEl = el; // current element on old position
        // reset position flags for reordering in fn reorderAccordionArray
        oldEl.position = currPos;
        newEl.position = newPos;

        this.reorderAccordionArray();
    }
    voteAccordionDown(el: envAccordion): void {
        const currPos = el.position; // old position value
        const newPos = currPos + 1; // new position value
        const oldEl = this.accordionData[newPos]; // old element on new position
        const newEl = el; // current element on old position
        // reset position flags for reordering in fn reorderAccordionArray
        oldEl.position = currPos;
        newEl.position = newPos;

        this.reorderAccordionArray();
    }

    deleteAccordion(el: envAccordion): void {
        const dialogRef = this.dialog.open(ModalPromptComponent, {
            width: 'auto',
            disableClose: true,
            data: {
                title: 'Klappbox wirklich löschen?',
                bodyText: '',
                buttonText1: 'Nein',
                buttonClass1: 'btn btn-secondary',
                buttonText2: 'Ja',
                buttonClass2: 'btn btn-danger',
                isDecision: true,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined && result === 2) {
                this.deleteCallback(el);
            }
        });
    }

    deleteCallback(el: envAccordion): void {
        const index: number = this.accordionData.indexOf(el, 0);
        if (index > -1) {
            this.accordionData.splice(index, 1);
        }
        this.reorderAccordionArray();
    }

    addAccordion(): void {
        let body: any | envContact[];
        let type: string;

        if (this.pageData.siteName === 'contact' || (this.pageData.siteName === 'propertyManagement' && this.type === 'contact')) {
            body = {
                contacts: [
                    {
                        name: '',
                        position: 0,
                        addressdata: '',
                        picture: this.defaultAsset,
                    },
                ],
            };
            type = 'contactType';
        } else {
            body = '';
            type = 'html';
        }

        this.accordionData.push({
            headline: '',
            position: this.accordionData.length,
            isOpen: false,
            hasDownloads: false,
            downloads: [],
            type,
            body,
        });

        this.reorderAccordionArray();
    }

    isShown(type) {
        if (this.accordionData !== undefined && this.pageData !== undefined) {
            if (
                (this.type === 'contact' && this.pageData.siteName === 'propertyManagement') ||
                (this.accordionCategory === 'subjectArea' && type === 'new')
            ) {
                return false;
            }
        }

        return true;
    }

    getClass() {
        if (this.pageData.siteName === 'propertyManagement' && this.type === 'contact') {
            return 'envFullSizeComponent';
        }
        if (this.pageData.siteName === 'propertyManagement' && this.type === 'subjectArea') {
            return 'envSmallSizeComponent';
        }

        return '';
    }
}
