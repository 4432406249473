import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.sass'],
})
export class DropdownComponent implements OnInit {
    @Input() type: string = 'default'; // optional - default, searchable, multiselect
    @Input() size: string = 'default'; // optional - default, small
    @Input() placeholder: string = ''; // optional
    @Input() data: any[]; // Pfilchtfeld - Daten für Dropbox
    @Input() defaultselected: number = undefined; // optional
    @Input() anzeigevalue: string = undefined; // Pfilchtfeld - Name des anzuzeigenden Wertes in "data"-Objekt
    @Input() additionalValue: string = undefined; // optional
    @Input() defaultmultipleselected: any = undefined; // optional
    @Input() spazialFunction: any = undefined; // optional

    _deleteselected: boolean = true;
    @Input() set deleteselected(value: boolean) {
        this._deleteselected = value;
        this.dltselected(this._deleteselected);
    }
    get deleteselected(): boolean {
        return this._deleteselected;
    }

    _setselected: number = undefined;
    @Input() set setselected(value: any) {
        this._setselected = value;
        if (this._setselected !== undefined) {
            if (typeof this._setselected === 'number') {
                this.selectedItem = this.data[this._setselected];
                if (this.type === 'searchable') {
                    this.searchItem = this.data[this._setselected][this.anzeigevalue];
                }
                this.selectedName = this.data[this._setselected][this.anzeigevalue];
                this.selectedNamechanged.emit(this.selectedName);
                this.selectedItemchanged.emit(this.selectedItem);
                this.envInvalidInput = false;
                this.invalidFieldEmit.emit(this.envInvalidInput);
            }
            if (typeof this._setselected === 'object') {
                for (const dataPoint of this.data) {
                    if (this._setselected && dataPoint[this.anzeigevalue] === this._setselected[this.anzeigevalue]) {
                        this.selectedItem = dataPoint;
                        if (this.type === 'searchable') {
                            this.searchItem = dataPoint[this.anzeigevalue];
                        }
                        this.selectedName = dataPoint[this.anzeigevalue];
                        this.selectedNamechanged.emit(this.selectedName);
                        this.selectedItemchanged.emit(this.selectedItem);
                        this.envInvalidInput = false;
                        this.invalidFieldEmit.emit(this.envInvalidInput);
                    }
                }
            }
        }
    }

    _disabled: boolean = false;
    @Input() set disabled(value: boolean) {
        this._disabled = value;
    }

    @Output() selectedItemchanged = new EventEmitter();
    @Output() selectedNamechanged = new EventEmitter();
    @Output() invalidFieldEmit = new EventEmitter();

    dropdownList: any = undefined;

    selectedItem: any = undefined;
    selectedName: any = undefined;

    searchItem: any = undefined;

    isDropdownActive: boolean = false;
    invalidField: boolean = false;
    envInvalidInput: boolean = false;

    ngOnInit() {
        if (typeof this.defaultselected !== 'undefined') {
            this.selectedItem = this.data[this.defaultselected];
            if (this.type === 'searchable') {
                this.searchItem = this.selectedItem[this.anzeigevalue];
            }
            this.selectedName = this.selectedItem[this.anzeigevalue];
            this.selectedNamechanged.emit(this.selectedName);
            this.selectedItemchanged.emit(this.selectedItem);
        }
        if (typeof this.defaultmultipleselected !== 'undefined') {
            const tempSelectedItem: any = [];
            const tempSelectedName: any = [];
            for (const selectedItem of this.defaultmultipleselected) {
                tempSelectedItem.push(this.data[selectedItem]);
            }
            this.selectedItem = tempSelectedItem;
            for (const item of this.selectedItem) {
                tempSelectedName.push(item[this.anzeigevalue]);
            }
            this.selectedName = tempSelectedName;
            if (this.type === 'searchable') {
                this.searchItem = tempSelectedName;
            }
            this.selectedName = tempSelectedName;
            this.selectedItemchanged.emit(this.selectedItem);
        }
    }

    // Dropdown schließen / öffnen
    changeDropdown(source, value?) {
        if (!this._disabled) {
            if (this.type === 'searchable') {
                this.dropdownInputSearch(value);
            } else {
                this.dropdownList = this.data;
            }

            if (this.type !== 'multiselect' || source !== 'setActiveDropdown') {
                this.isDropdownActive = !this.isDropdownActive;
            }

            this.invalidField = this.selectedItem === undefined || this.selectedItem === null; // Für Class invalidField

            if (source === 'CloseWrapper') {
                this.checkDropdownInput('');
            }
        }
    }

    // prüft den input bei dropdown feldern
    dropdownInputSearch(value: string) {
        if (value === undefined || value === '') {
            this.dropdownList = this.data;
        } else {
            this.dropdownList = [];
            for (const dataPoint of this.data) {
                if (
                    (
                        dataPoint[this.anzeigevalue].toLowerCase() +
                        (this.additionalValue !== undefined ? ' (' + dataPoint[this.additionalValue].toLowerCase() + ')' : '')
                    ).indexOf(value.toLowerCase()) !== -1
                ) {
                    this.dropdownList.push(dataPoint);
                }
            }
        }
    }

    // leeren des Inputfleds bei focus out
    checkDropdownInput(value) {
        if (this.selectedItem === undefined) {
            this.envInvalidInput = true;
            this.invalidFieldEmit.emit(this.envInvalidInput);
            this.selectedName = undefined;
            this.selectedItem = undefined;
            if (this.type === 'searchable') {
                this.searchItem = undefined;
            }
            this.selectedItemchanged.emit(this.selectedItem);
        } else {
            if (this.type === 'searchable') {
                if (
                    this.selectedItem[this.anzeigevalue] +
                        (this.additionalValue !== undefined ? ' (' + this.selectedItem[this.additionalValue] + ')' : '') !==
                    this.searchItem
                ) {
                    this.envInvalidInput = true;
                    this.invalidFieldEmit.emit(this.envInvalidInput);
                    this.selectedName = undefined;
                    this.searchItem = undefined;
                    this.selectedItem = undefined;
                    this.selectedItemchanged.emit(this.selectedItem);
                } else {
                    this.envInvalidInput = false;
                    this.selectedNamechanged.emit(this.selectedName);
                    this.invalidFieldEmit.emit(this.envInvalidInput);
                }
            } else {
                if (this.type !== 'multiselect') {
                    if (
                        this.selectedItem[this.anzeigevalue] +
                            (this.additionalValue !== undefined ? ' (' + this.selectedItem[this.additionalValue] + ')' : '') !==
                        this.selectedName + (this.additionalValue !== undefined ? ' (' + this.selectedItem[this.additionalValue] + ')' : '')
                    ) {
                        this.envInvalidInput = true;
                        this.invalidFieldEmit.emit(this.envInvalidInput);
                        this.selectedName = undefined;
                        this.selectedItem = undefined;
                        this.selectedItemchanged.emit(this.selectedItem);
                    } else {
                        this.envInvalidInput = false;
                        this.selectedNamechanged.emit(this.selectedName);
                        this.invalidFieldEmit.emit(this.envInvalidInput);
                    }
                }
            }
        }
    }

    // setzt das angeklickte feld im Dropdown und wechselt die Animation, sendet geänderte Werte an parent component
    setActiveDropdown(item) {
        if (this.type !== 'multiselect') {
            this.selectedItem = item;
            this.selectedName = item[this.anzeigevalue];
            if (this.type === 'searchable') {
                this.searchItem =
                    item[this.anzeigevalue] + (this.additionalValue !== undefined ? ' (' + item[this.additionalValue] + ')' : '');
            }
            this.changeDropdown('setActiveDropdown', '');
            this.selectedItemchanged.emit(this.selectedItem);
            if (this.type === 'searchable') {
                this.selectedNamechanged.emit(
                    this.selectedName + (this.additionalValue !== undefined ? ' (' + item[this.additionalValue] + ')' : '')
                );
            } else {
                this.selectedNamechanged.emit(
                    this.selectedName + (this.additionalValue !== undefined ? ' (' + item[this.additionalValue] + ')' : '')
                );
            }
            this.envInvalidInput = false;
            this.invalidFieldEmit.emit(this.envInvalidInput);
            if (this.spazialFunction === 'replaceVWG') {
                if (this.selectedItem.verwaltungsgemeinschaften !== undefined && this.selectedItem.verwaltungsgemeinschaften.length > 0) {
                    this.searchItem =
                        this.selectedItem.verwaltungsgemeinschaften[0].gemeinde +
                        (this.additionalValue !== undefined ? ' (' + item[this.additionalValue] + ')' : '');
                }
            }
        } else {
            if (typeof this.selectedItem === 'undefined') {
                this.selectedItem = [];
                this.selectedName = [];
            }

            const position = getobjposition(this.selectedItem, item, this.anzeigevalue);
            if (position === -1) {
                this.selectedItem.push(item);

                this.selectedName.push(item[this.anzeigevalue]);
            } else {
                this.selectedItem.splice(position, 1);
                this.selectedName.splice(position, 1);
            }
            this.selectedItemchanged.emit(this.selectedItem);
            this.selectedNamechanged.emit(
                this.selectedName + (this.additionalValue !== undefined ? ' (' + item[this.additionalValue] + ')' : '')
            );
            this.envInvalidInput = false;
            this.invalidFieldEmit.emit(this.envInvalidInput);
        }
    }

    checkmultiselect(item) {
        if (typeof this.selectedItem !== 'undefined') {
            return getobjposition(this.selectedItem, item, this.anzeigevalue) !== -1;
        }

        return false;
    }

    dltselected(bool: boolean) {
        if (this.data !== undefined) {
            if (bool) {
                this.selectedItem = undefined;
                if (this.type === 'searchable') {
                    this.searchItem = undefined;
                }
                this.selectedName = undefined;
                this.selectedNamechanged.emit(this.selectedName);
                this.selectedItemchanged.emit(this.selectedItem);
            }
        }
    }
}

function getobjposition(objArray: any[], obj: any, value: string) {
    for (let i = 0; i < objArray.length; i += 1) {
        if (objArray[i][value] === obj[value]) {
            return i;
        }
    }

    return -1;
}
