import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivationEnd } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { envPageData } from '@classes/envPageData';
import { RestService } from '@services/rest.service';
import { CommunicationService } from '@services/communication.service';
import { AuthService } from '@auth/auth.service';
import { KeycloakService } from 'keycloak-angular';
import { Navigation } from '@utils/navigation';

@Injectable({
    providedIn: 'root',
})
export class SiteDataService {
    constructor(
        private readonly router: Router,
        private readonly restService: RestService,
        private readonly authService: AuthService,
        private readonly communicationService: CommunicationService
    ) {}

    // Variablen
    isLoggedIn: boolean = false;
    pageData = new Subject<envPageData>();
    siteData: any = undefined;
    mapUrlModel: any = undefined;

    agsSubscription: Subscription;
    kpIdSubscription: Subscription;
    routerSubscription: Subscription;
    subscriptionApplicationOptions: Subscription;

    currentAgs: string = undefined;
    kpId: string = undefined;

    // Service initialisieren
    initializeRouteCheck() {
        this.mapUrlModel = Navigation.getMapUrlModel(); // Mapping abfragen
        this.buildCacheObject(); // Cache erstellen
        this.getLoggingStatus(); // Login Status abfragen
        this.getCurrentAgs();
        this.getCurrentKpId();

        // Auf Routerevents warten
        if (!this.routerSubscription) {
            this.routerSubscription = this.router.events.subscribe((event: any) => {
                if (event.routerEvent instanceof ActivationEnd) {
                    this.getSiteData(this.router.url);
                    this.restService.cancelCurrentRequests();
                }
                if (event.routerEvent instanceof NavigationEnd) {
                    this.getSiteData(this.router.url); // Seitendaten laden nach erfolgreicher Navigation
                }
            });
        }
    }

    // Updated des Cache erzwingen durch reset
    forceCacheUpdate() {
        const siteName = this.mapUrlModel[this.router.url.replace('/', '')];
        this.siteData[siteName] = undefined;
        this.getSiteData(this.router.url);
    }

    // Seitendaten unabhängig von derzeitiger URL laden
    getDataFromDifferentSite(siteName: string) {
        this.getSiteData(this.router.url, false, siteName);
    }

    // Derzeitigen Loginstand von Service abrufen
    private getLoggingStatus() {
        this.communicationService.isLoggedIn$.subscribe((logStatus) => {
            this.isLoggedIn = logStatus;
        });
    }

    // Cache Objekt aus Mapping erstellen
    private buildCacheObject() {
        const tmp = {};

        for (const objKey of Object.keys(this.mapUrlModel)) {
            const objValue = this.mapUrlModel[objKey];
            tmp[objValue] = undefined;
        }

        this.siteData = tmp;
    }

    // Subscription um immer den aktuellen AGS zu erhalten
    private getCurrentAgs() {
        if (!this.agsSubscription) {
            this.agsSubscription = this.communicationService.$activeCommuneAGS.subscribe((ags) => {
                if (ags) {
                    this.currentAgs = ags;
                    this.getSiteData(this.router.url, true);
                }
            });
        }
    }

    // Subscription um immer den aktuellen AGS zu erhalten
    private getCurrentKpId() {
        if (!this.kpIdSubscription) {
            this.kpIdSubscription = this.communicationService.$activeCommuneKpId.subscribe((kpId) => {
                if (kpId) {
                    this.kpId = kpId;
                }
            });
        }
    }

    // Seitendaten laden
    private getSiteData(href: string, forceUpdate: boolean = false, providedSiteName?: string) {
        if (href.includes('?')) {
            href = href.substring(0, href.indexOf('?'));
        }
        const siteName = providedSiteName === undefined ? this.mapUrlModel[href.replace('/', '')] : providedSiteName;
        // wenn siteName gesetzt dan lade daten (wartungseite wird damit ausgeschlossen)
        if (siteName) {
            // überprüfung der appliicationsoptionen wie zum Beispiel. ob Wartungsmodus aktiv ist
            this.getApplicationOptions();
            if (siteName === 'myCommune') {
                this.checkForForceLogout();
            }

            // Wenn Nutzer eingeloggt ist
            if (this.isLoggedIn && siteName !== 'myCommune' && this.currentAgs) {
                // Wenn Cache leer oder forceUpdate
                if (this.siteData[siteName] === undefined || forceUpdate) {
                    // Onlinedaten abfragen
                    this.restService
                        .getOnlineContentData(siteName, this.currentAgs, this.kpId)
                        .pipe(takeUntil(this.restService.$cancelPendingRequests))
                        .subscribe((data: envPageData) => {
                            if (data !== undefined) {
                                this.siteData[siteName] = data; // im Cache speichern
                                this.pageData.next(data); // auf Observable legen
                            }
                        });
                }
                // Zugriff auf OfflineContent bei den beiden Seiten, die die Daten benötigen
            } else if (siteName === 'myCommune' || siteName === 'forgotAccessData') {
                // Im Ausgeloggten Zustand
                // Wenn Cache leer oder forceUpdate
                if (this.siteData[siteName] === undefined || forceUpdate) {
                    // Offlinecontent laden
                    this.restService
                        .getOfflineContentData(siteName, this.kpId)
                        .pipe(takeUntil(this.restService.$cancelPendingRequests))
                        .subscribe((data: envPageData) => {
                            if (data !== undefined) {
                                this.siteData[siteName] = data; // im Cache speichern
                                this.pageData.next(data); // auf Observable legen
                            }
                        });
                }
            }
        }
    }

    // Hollt alle informationen zur konfigutration der Seite sowie prüfung ob seite im Wartungsmopdus ist
    async getApplicationOptions() {
        this.restService.getApplicationOptions();

        if (!this.subscriptionApplicationOptions) {
            this.subscriptionApplicationOptions = this.restService.$applicationOptions.subscribe(async (data: any) => {
                if (data !== undefined) {
                    this.restService.resetApplicationOptions();
                    if (data.isMaintenanceMode) {
                        // Ausloggen vom Keycloak und sessionstorage leeren und auf wartungseite leiten
                        if (await this.authService.isUserLoggedIn()) {
                            this.communicationService.setLoginStatus(false);
                            await this.authService.logout();
                        }
                        this.router.navigateByUrl('/wartung');
                    }
                }
            });
        }
    }

    //Prüfung ob Logout getriggert wurde um wirklich auszuloggen
    checkForForceLogout() {
        if (sessionStorage.getItem('forceLogout') === 'true') {
            sessionStorage.clear();
            this.authService.logout();
        }
    }
}
