import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { LogoutRedirectOptions, WebAuth } from 'cidaas-javascript-sdk';
import { AccessTokenRequest, TokenIntrospectionEntity } from 'cidaas-javascript-sdk/dist/web-auth/Entities';
import { CommunicationService } from 'src/app/shared/services/communication.service';

export class SachsenEnergieCidaasFunctions {
    config: any;
    cidaas: WebAuth;
    accessTokenRequest: AccessTokenRequest;
    accessToken: string;
    idToken: string;
    http = inject(HttpClient);

    constructor(config, private readonly router: Router, private readonly communicationService: CommunicationService) {
        this.config = config;
        this.cidaas = new WebAuth(config);
        this.accessTokenRequest = new AccessTokenRequest();
        this.accessToken = undefined;
    }

    login() {
        this.cidaas.loginWithBrowser();
    }

    routeToMainPage() {
        this.router.navigate(['/meinekommune']);
    }

    async loginCallBack() {
        await this.cidaas
            .loginCallback()
            .then((response) => {
                if (response.access_token) {
                    this.communicationService.setUserBearerToken(response.access_token);
                    this.accessToken = response.access_token;
                    this.idToken = response.id_token;
                    localStorage.setItem('idToken', this.idToken);
                    localStorage.setItem('accessToken', this.accessToken);
                }
            })
            .catch(async (ex) => {
                console.log(ex);
            });
    }

    logoutAndRoute() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('idToken');
        this.communicationService.setLoginStatus(false);
        sessionStorage.clear();
        if (this.router) {
            if (this.router.url !== '/meinekommune') {
                sessionStorage.setItem('forceLogout', 'true');
                this.routeToMainPage();
            } else {
                this.logout();
            }
        } else {
            this.logout();
        }
    }

    setAccessTokenRequest(accessToken) {
        this.accessTokenRequest.code = accessToken;
    }

    async isLoggedInRoutine() {
        this.accessToken = localStorage.getItem('accessToken');
        this.idToken = localStorage.getItem('idToken');
        // Wenn kein gültiges Token vorhanden dann, loginCallback
        if (this.accessToken === undefined || this.accessToken === null) {
            await this.loginCallBack();
        } else {
            // Wenn Token vorhanden ist, prüfe den Token auf Gültigkeit
            let subscr = this.http.post(this.config.token_introspect_endpoint, { token: this.accessToken }).subscribe(
                (data: any) => {
                    if (data !== undefined) {
                        subscr.unsubscribe();
                        if (data.active) {
                            this.communicationService.setUserBearerToken(this.accessToken);
                            this.communicationService.setLoginStatus(true);
                        }
                        if (!data.active) {
                            this.logoutAndRoute();
                        }
                    }
                },
                (error) => {
                    this.logoutAndRoute();
                }
            );
        }
    }

    logout() {
        const logoutOptions: LogoutRedirectOptions = {
            post_logout_redirect_uri: this.config.post_logout_redirect_uri,
            id_token_hint: this.idToken,
        };
        this.cidaas.logout(logoutOptions);
    }

    getUserInfo() {
        return this.cidaas.getUserProfile({ access_token: this.accessToken });
    }

    checkUserPermission(adminType: string[], hasToBeLoggedOut: boolean) {
        const userGroups = this.communicationService.getUserGroups();
        if (hasToBeLoggedOut && !this.communicationService.getLoggingStatus()) {
            return true;
        }
        if (!hasToBeLoggedOut && this.communicationService.getLoggingStatus() && this.checkArrayOnMultipleValues(adminType, userGroups)) {
            return true;
        }
        if (!hasToBeLoggedOut && this.communicationService.getLoggingStatus() && adminType.length === 0) {
            return true;
        }

        this.routeToMainPage();
    }

    // Funktion prüft, ob eins von mehreren Values in einem Array sind. Falls alle nicht vorhanden --> false
    checkArrayOnMultipleValues(values: string[], array: string[]) {
        const wasFound: string[] = [];

        for (let i = 0; i < values.length; i += 1) {
            if (array.indexOf(values[i]) > -1) {
                wasFound.push(array[i]);
            }
        }

        return wasFound.length > 0 ? true : false;
    }

    getAccessToken() {
        return this.accessToken;
    }

    isUserLoggedIn() {
        if (this.accessToken !== undefined && this.accessToken !== null) return true;
        return false;
    }

    checkUserLogin() {
        this.cidaas.getUserInfo().then((resp) => {
            if (resp === null) {
                this.communicationService.setLoginStatus(undefined);
            } else {
                this.communicationService.setLoginStatus(true);
            }
        });
    }

    updateToken() {
        this.cidaas.renewToken(this.accessTokenRequest);
    }

    checkTokenValidity() {
        const introspect = new TokenIntrospectionEntity();
        introspect.token = this.accessToken;
        try {
            this.cidaas.validateAccessToken(introspect);
        } catch {
            //this.logout();
        }
    }

    updateTokenWithTimeSpan(timeSpan: number) {
        this.cidaas.renewToken(this.accessTokenRequest);
    }
}
