import { Injectable } from '@angular/core';

import { Observable, of, BehaviorSubject } from 'rxjs';

import { envCommunity } from '@classes/envCommunity';
import { envDeliveryPointChanges } from '@classes/envDeliveryPointChanges';
import { envDeliveryPointItem } from '@classes/envDeliveryPointItem';

@Injectable({
    providedIn: 'root',
})
// Service für die allgemeine Kommunikation zwischen Komponenten
export class CommunicationService {
    // Variablen
    private readonly activeCommuneName = new BehaviorSubject<string>('');
    $activeCommuneName = this.activeCommuneName.asObservable();

    private readonly activeCommuneAGS = new BehaviorSubject<string>('');
    $activeCommuneAGS = this.activeCommuneAGS.asObservable();

    private readonly activeCommuneKpId = new BehaviorSubject<string>('');
    $activeCommuneKpId = this.activeCommuneKpId.asObservable();

    private readonly activeCommuneData = new BehaviorSubject<envCommunity>(undefined);
    $activeCommuneData = this.activeCommuneData.asObservable();

    private readonly openDPChangeCommune = new BehaviorSubject<envDeliveryPointChanges>(undefined);
    $openDPChangeCommune = this.openDPChangeCommune.asObservable();

    private readonly doneDPChangeCommune = new BehaviorSubject<envDeliveryPointChanges>(undefined);
    $doneDPChangeCommune = this.doneDPChangeCommune.asObservable();

    private readonly listOfAllCommunes = new BehaviorSubject<any>(undefined);
    $listOfAllCommunes = this.listOfAllCommunes.asObservable();

    private readonly communeViewType = new BehaviorSubject<string>(undefined);
    $communeViewType = this.communeViewType.asObservable();

    private readonly forgottenItems = new BehaviorSubject<envDeliveryPointItem[]>(undefined);
    $forgottenItems = this.forgottenItems.asObservable();

    private datepickerMenu = new BehaviorSubject<any>(undefined);
    $datepickerMenu = this.datepickerMenu.asObservable();

    private readonly restList = new BehaviorSubject<any>(undefined);
    $restlist = this.restList.asObservable();

    private readonly openOverviewData = new BehaviorSubject<envDeliveryPointChanges[]>(undefined);
    $openOverviewData = this.openOverviewData.asObservable();

    private readonly doneOverviewData = new BehaviorSubject<envDeliveryPointChanges[]>(undefined);
    $doneOverviewData = this.doneOverviewData.asObservable();

    private readonly userData = new BehaviorSubject<any>(undefined);
    $userData = this.userData.asObservable();

    private fekData = new BehaviorSubject<any[]>(undefined);
    $fekData = this.fekData.asObservable();

    private bankData = new BehaviorSubject<any[]>(undefined);
    $bankData = this.bankData.asObservable();

    private dpHighlights = new BehaviorSubject<any>(undefined);
    $dpHighlights = this.dpHighlights.asObservable();

    private readonly isLoggedIn = new BehaviorSubject<boolean>(undefined);
    isLoggedIn$ = this.isLoggedIn.asObservable();

    private readonly isViewBlocked = new BehaviorSubject<boolean>(true);
    isViewBlocked$ = this.isViewBlocked.asObservable();

    private readonly userBearerToken = new BehaviorSubject<string>(undefined);
    $userBearerToken = this.userBearerToken.asObservable();

    private wasPasswordChanged: boolean = false;

    // private detailOverviewData = new BehaviorSubject<envDeliveryPointChanges>(undefined);
    // $detailOverviewData = this.detailOverviewData.asObservable();

    private readonly registerRestList: string[] = [];
    private userEmailAddress: string = undefined;
    private userGroups = new Array<string>();
    private admFullname: string = '';
    private clusterId: string = '';
    private currentFile: any = '';

    // Derzeit aktive Kommune setzen
    setActiveCommuneName(currCommune: string): void {
        this.activeCommuneName.next(currCommune);
    }

    // Aktive Kommune abrufen
    getActiveCommuneName(): string {
        return this.activeCommuneName.getValue();
    }

    setActiveCommuneAGS(ags: string): void {
        this.activeCommuneAGS.next(ags);
    }

    getActiveCommuneAGS(): string {
        return this.activeCommuneAGS.getValue();
    }

    setActiveCommuneKpId(kpId: string): void {
        this.activeCommuneKpId.next(kpId);
    }

    setListOfAllCommunes(list: any): void {
        this.listOfAllCommunes.next(list);
    }

    getListOfAllCommunes(): string {
        return this.listOfAllCommunes.getValue();
    }

    getActiveCommuneKpId(): string {
        return this.activeCommuneKpId.getValue();
    }

    // Daten der derzeit aktiven Kommune setzten
    setActiveCommuneData(data: envCommunity): void {
        this.activeCommuneData.next(data);
    }

    // Daten der derzeit aktiven Kommune setzten
    getActiveCommuneData() {
        return this.activeCommuneData.getValue();
    }

    // Nutzergruppe setzten
    setUserGroups(groups: string[]) {
        this.userGroups = groups;
    }

    // Nutzergruppe abfragen
    getUserGroups(): string[] {
        return this.userGroups;
    }

    // Nutzeremail setzten
    setUserEmail(email: string) {
        this.userEmailAddress = email;
    }

    // Nutzeremail abfragen
    getUserEmail(): string {
        return this.userEmailAddress;
    }

    setUserData(data: any): void {
        this.userData.next(data);
    }

    getUserData(): any {
        return this.userData.getValue();
    }

    // ausgewählte Kommune für Unterseite setzten
    setOpenDPChangeCommune(commune: envDeliveryPointChanges) {
        this.openDPChangeCommune.next(undefined);
        this.openDPChangeCommune.next(commune);
    }

    // ausgewählte Kommune für Unterseite setzten
    setDoneDPChangeCommune(commune: envDeliveryPointChanges) {
        this.doneDPChangeCommune.next(undefined);
        this.doneDPChangeCommune.next(commune);
    }

    // Typ der Tabellen auf der Einzelkommunenansichtsseite setzten
    setCommuneViewType(type: string) {
        this.communeViewType.next(undefined);
        this.communeViewType.next(type);
    }

    // Daten für neuen Eintrag zurücksetzten
    clearCommuneViewData() {
        this.communeViewType.next(undefined);
        this.doneDPChangeCommune.next(undefined);
        this.openDPChangeCommune.next(undefined);
    }

    setAdmFullName(name: string) {
        this.admFullname = name;
    }

    getAdmFullName(): string {
        return this.admFullname;
    }

    setForgottenItems(items: envDeliveryPointItem[]) {
        this.forgottenItems.next(items);
    }

    setClusterId(id: string) {
        this.clusterId = id;
    }

    getClusterId(): string {
        return this.clusterId;
    }

    setDatepickerMenu(menu: any): void {
        this.datepickerMenu = menu;
    }

    getDatepickerMenu(menu: any): any {
        return this.datepickerMenu;
    }

    setCurrentfile(file: any): any {
        this.currentFile = file;
    }

    getCurrentFile(): any {
        return this.currentFile;
    }

    setOpenOverviewData(data: envDeliveryPointChanges[]) {
        this.openOverviewData.next(undefined);
        this.openOverviewData.next(data);
    }

    setDoneOverviewData(data: envDeliveryPointChanges[]) {
        this.doneOverviewData.next(undefined);
        this.doneOverviewData.next(data);
    }

    setFekData(data: any): void {
        this.fekData = data;
    }

    getFekData(): any {
        return this.fekData;
    }

    setBankData(data: any): void {
        this.bankData = data;
    }

    getBankData(): any {
        return this.bankData;
    }

    getDpHighlights(): any {
        return this.dpHighlights;
    }

    setDpHighlights(value) {
        this.dpHighlights = value;
    }

    // Setzt den Logging Status auf den übergebenen Wert
    setLoginStatus(value: boolean): void {
        this.isLoggedIn.next(value);
    }

    // Blocker Status ändern
    setBlockerStatus(value: boolean): void {
        this.isViewBlocked.next(value);
    }

    // userBearerToken abspeichern
    setUserBearerToken(token: string): void {
        this.userBearerToken.next(token);
    }

    // Abfrage des derzeitigen Logging Status
    getLoggingStatus(): boolean {
        return this.isLoggedIn.getValue();
    }

    // Flag setzten, dass beim Redirect auf Meine Kommune sofort eingeloggt wird
    setPasswordChange(state: boolean) {
        this.wasPasswordChanged = state;
    }

    // Flag abrufen
    getPasswordChange(): boolean {
        return this.wasPasswordChanged;
    }
}
