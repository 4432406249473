import { Component, OnInit, OnDestroy } from '@angular/core';

import { AuthService } from '@auth/auth.service';
import { RestService } from '@services/rest.service';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '@environments/environment';

import { Subscription } from 'rxjs';
import { CommunicationService } from 'src/app/shared/services/communication.service';
@Component({
    selector: 'app-maintenance-mode',
    templateUrl: './maintenance-mode.component.html',
    styleUrls: ['./maintenance-mode.component.sass'],
})
export class MaintenanceModeComponent implements OnInit, OnDestroy {
    errorType: string = '';
    reason: string = '';
    isHandledError: boolean = false;
    isUnhandledError: boolean = false;
    hasError: boolean = false;
    maintenanceMode: boolean = false;
    isLoggedIn: boolean = false;

    HandledErrorTexts: string[] = ['Folgender Fehler ist aufgetreten:', ''];
    UnhandledErrorTexts: string[] = ['Beim Laden der folgenden Daten ist ein Fehler aufgetreten:', ''];

    errorText: string[];
    subscriptionApplicationOptions: Subscription;
    incidentMail = environment.incidentMail;
    incidentMailAddress = environment.incidentMailAddress;

    constructor(
        private readonly authService: AuthService,
        private readonly communicationService: CommunicationService,
        private readonly restService: RestService,
        private readonly router: Router,
        private readonly keycloakService: KeycloakService
    ) {}

    // Jeweiligen Errortyp aussteuern
    initialiseSite() {
        if (this.isUnhandledError) {
            switch (this.errorType) {
                case 'myCommunities':
                    this.reason = 'Kommunendaten';
                    break;
                case 'getKorabDocuments':
                    this.reason = 'Kommunalrabattdokumente';
                    break;
                case 'getKaDocuments':
                    this.reason = 'Konzessionsabgabendokumente';
                    break;
                case 'getOfflineContentData':
                    this.reason = 'Seitendaten';
                    break;
                case 'getOnlineContentData':
                    this.reason = 'Seitendaten';
                    break;
                case 'getUserData':
                    this.reason = 'Nutzerdaten';
                    break;
                case 'dpElectro':
                    this.reason = 'Lieferstellen Strom';
                    break;
                case 'dpGas':
                    this.reason = 'Lieferstellen Gas';
                    break;
                default:
            }
        } else {
            this.reason = this.errorType;
        }
    }

    // Error von Service abfragen
    getUnhandledError() {
        this.isUnhandledError = true;
        this.errorType = sessionStorage.getItem('errorTypeUnhandled');
        this.errorText = this.UnhandledErrorTexts;
        sessionStorage.removeItem('errorTypeUnhandled');
        this.initialiseSite();
    }

    // Error von Service abfragen
    getHandledError() {
        this.isHandledError = true;
        this.errorType = sessionStorage.getItem('errorTypeHandled');
        this.errorText = this.HandledErrorTexts;
        sessionStorage.removeItem('errorTypeHandled');
        this.initialiseSite();
    }

    ngOnInit() {
        // Wenn Error vorhanden
        if (sessionStorage.getItem('errorTypeHandled') !== null || sessionStorage.getItem('errorTypeUnhandled') !== null) {
            this.hasError = true;
            this.communicationService.setBlockerStatus(false);
            // Je nach Typ weiterleiten
            if (sessionStorage.getItem('errorTypeHandled') !== null) {
                this.getHandledError();
            }
            if (sessionStorage.getItem('errorTypeUnhandled') !== null) {
                this.getUnhandledError();
            }
        } else {
            // überprüfung der appliicationsoptionen wie zum Beispiel. ob Wartungsmodus aktiv ist
            this.getApplicationOptions();
        }
    }

    // LogIn Status
    async getLoggingStatus() {
        if (await this.keycloakService.isLoggedIn()) {
            this.isLoggedIn = true;
        }
    }

    // Hollt alle informationen zur konfigutration der Seite sowie prüfung ob seite im Wartungsmopdus ist
    getApplicationOptions() {
        this.restService.getApplicationOptions();

        if (!this.subscriptionApplicationOptions) {
            this.subscriptionApplicationOptions = this.restService.$applicationOptions.subscribe((data: any) => {
                if (data !== undefined) {
                    this.restService.resetApplicationOptions();
                    if (data.isMaintenanceMode.value) {
                        this.communicationService.setBlockerStatus(false);
                        this.maintenanceMode = true;
                    } else {
                        // wenn kein Fehler und kein wartungsmodus dann zurück zur hauptseite
                        this.routeBackToMainPage();
                    }
                }
            });
        }
    }

    routeBackToMainPage() {
        this.authService.routeToMainPage();
    }

    ngOnDestroy() {
        if (this.subscriptionApplicationOptions) {
            this.subscriptionApplicationOptions.unsubscribe();
        }
        if (!this.isLoggedIn) {
            this.communicationService.setBlockerStatus(true);
        } else {
            this.communicationService.setBlockerStatus(false);
        }
        this.isUnhandledError = false;
        this.isHandledError = false;
        sessionStorage.clear();
    }
}
