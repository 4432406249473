import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { Subscription } from 'rxjs';

import { CommunicationService } from '@services/communication.service';
import { SiteDataService } from '@services/site-data.service';

import { envPageData } from '@classes/envPageData';
import { envUserData } from '@classes/envUserData';
import { environment } from '@environments/environment';

import { Navigation } from '@utils/navigation';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ResponsiveService } from '@services/responsive.service';

@Component({
    selector: 'app-content-header',
    templateUrl: './content-header.component.html',
    styleUrls: ['./content-header.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class ContentHeaderComponent implements OnInit, OnDestroy {
    // Variablen
    isLoading: boolean = true;
    headline: string = '';
    userData: envUserData = undefined;
    subheadline: string = '';
    contentHeadline: string = '';
    description: string = '';
    additionalDescription: string = '';
    loggingStatus: boolean = false;
    contentData: envPageData[] = undefined;
    activeCommune: string = '';
    href: string = ''; // aktueller Pfad
    keyVisual = {
        type: '',
        src: '',
    };

    isUserAdmin: boolean = false;
    mapUrlModel: any = undefined;
    siteData: envPageData = undefined;

    //Responsive
    showImage: boolean = undefined;
    isMobilePortraitView = undefined;
    isMobileLandscapeView = undefined;
    isTabletPortraitView = undefined;
    isDesktopLandscapeView = undefined;
    isTabletLandscapeView = undefined;

    private siteDataSubscription: Subscription;
    private userDataSubscription: Subscription;
    private communeDataSubscription: Subscription;
    private loggingStatusSubscription: Subscription;

    @Output() isContentHeaderLoading = new EventEmitter();

    constructor(
        private readonly router: Router,
        private readonly communicationService: CommunicationService,
        private readonly siteDataService: SiteDataService,
        private readonly domSanitizationService: DomSanitizer,
        private readonly responsive: ResponsiveService
    ) {}

    ngOnInit() {
        this.href = this.router.url; // aufrufende URL
        this.mapUrlModel = Navigation.getMapUrlModel(); // URL Mapping aus utils holen
        this.getLoggingStatus();

        this.responsive.$showHeaderImage.subscribe((data) => {
            if (data !== undefined) {
                this.showImage = data;
            }
        });
        this.responsive.$isMobilePortraitView.subscribe((data) => {
            if (data !== undefined) {
                this.isMobilePortraitView = data;
            }
        });
        this.responsive.$isMobileLandscapeView.subscribe((data) => {
            if (data !== undefined) {
                this.isMobileLandscapeView = data;
            }
        });
        this.responsive.$isTabletLandscapeView.subscribe((data) => {
            if (data !== undefined) {
                this.isTabletLandscapeView = data;
            }
        });
        this.responsive.$isTabletPortraitView.subscribe((data) => {
            if (data !== undefined) {
                this.isTabletPortraitView = data;
            }
        });
        this.responsive.$isDesktopLandscapeView.subscribe((data) => {
            if (data !== undefined) {
                this.isDesktopLandscapeView = data;
            }
        });
    }

    // On Destroy alle Subscriptions beenden
    ngOnDestroy() {
        if (this.siteDataSubscription) {
            this.siteDataSubscription.unsubscribe();
        }
        if (this.userDataSubscription) {
            this.userDataSubscription.unsubscribe();
        }
        if (this.communeDataSubscription) {
            this.communeDataSubscription.unsubscribe();
        }
        if (this.loggingStatusSubscription) {
            this.loggingStatusSubscription.unsubscribe();
        }
    }

    getSiteData() {
        // URL und Mapping aus utils holen
        const currUrl = this.router.url.replace('/', '');
        const identifier = this.mapUrlModel[currUrl];

        // Wenn Cache leer
        if ((this.siteDataService.siteData !== undefined && this.siteDataService.siteData[identifier]) === undefined) {
            // Wenn Subscription noch nicht existiert
            if (!this.siteDataSubscription) {
                // Seitendaten von Service holen
                this.siteDataSubscription = this.siteDataService.pageData.subscribe((data: envPageData) => {
                    if (data !== undefined) {
                        this.siteData = data[identifier];
                        this.getAdditionalOnlineInformation();
                    }
                });
            }
        } else {
            // Wenn im Cache vorhanden
            // Seitendaten aus Cache anhand Identifier abfragen
            // Zweiter Identifier Zugriff, da komplette JSON aus Backend in Cache gespeichert wird
            // In der JSON liegen die Seitendaten und Attribut mit Namen der Seite
            if (this.siteDataService.siteData !== undefined) {
                this.siteData = this.siteDataService.siteData[identifier][identifier];
                this.getAdditionalOnlineInformation();
            }
        }
    }

    // Zusätzliche Daten für Header holen
    getAdditionalOnlineInformation() {
        // Wenn Nutzer eingeloggt und kein Admin
        if (this.loggingStatus && !this.isUserAdmin) {
            // Kommunendaten abfragen
            if (!this.communeDataSubscription) {
                this.communeDataSubscription = this.communicationService.$activeCommuneData.subscribe((commune) => {
                    if (commune !== undefined) {
                        this.activeCommune = commune.address2;
                        // Nutzerdaten abfragen
                        if (!this.userDataSubscription) {
                            this.userDataSubscription = this.communicationService.$userData.subscribe((data: any) => {
                                if (data !== undefined) {
                                    this.userData = data;
                                    // Ansonsten gleich zu getHeadInformation
                                    this.getHeadInformation();
                                }
                            });
                        }
                    }
                });
            }
        } else {
            // Ansonsten gleich zu getHeadInformation
            this.getHeadInformation();
        }
    }

    // Derzeitigen Loginstand von Service abrufen
    getLoggingStatus() {
        if (!this.loggingStatusSubscription) {
            this.loggingStatusSubscription = this.communicationService.isLoggedIn$.subscribe((logStatus) => {
                this.loggingStatus = logStatus;

                // Adminstatus abfragen
                const userGroups = this.communicationService.getUserGroups();
                this.isUserAdmin =
                    userGroups.indexOf(environment.userGroups.admin) > -1 ||
                    userGroups.indexOf(environment.userGroups.superAdmin) > -1 ||
                    userGroups.indexOf(environment.userGroups.deliveryPointAdmin) > -1;
                if (!this.isUserAdmin) {
                    this.getSiteData(); // Weiterleiten, wenn LoginStatus vorhanden und Nutzer kein Admin
                } else {
                    this.getAdditionalOnlineInformation(); // Ansonsten gleich weiter zu zusätzlichen Informationen
                }
            });
        }
    }

    // Header Daten der einzelnen Contentseiten aussteuern nach derzeitigem Routing
    getHeadInformation() {
        if (this.siteData) {
            this.headline = this.siteData.headline;
            this.subheadline = this.siteData.subHeadline !== undefined ? this.siteData.subHeadline : '';
            this.description = this.siteData.content !== undefined ? this.siteData.content : '';
            this.keyVisual.type = this.siteData.keyVisual.type;
            this.keyVisual.src = environment.restBaseURL + environment.assetPathBackend + this.siteData.keyVisual.url;
        }

        switch (this.href) {
            case '/meinekommune':
                this.headline = this.loggingStatus ? this.activeCommune : this.siteData.headline;
                this.contentHeadline = this.userData !== undefined ? this.getGreeting('headline', false) : this.siteData.contentHeadline;
                this.additionalDescription = this.userData !== undefined ? this.getGreeting('description', false) : '';
                break;
            case '/admin/cockpit':
                this.contentHeadline = this.userData !== undefined ? this.getGreeting('headline', true) : '';
                this.additionalDescription = this.userData !== undefined ? this.getGreeting('description', true) : '';
                break;
            default:
                this.additionalDescription = '';
                this.contentHeadline = this.siteData.contentHeadline;
        }
        this.isContentHeaderLoading.emit(false);
        this.isLoading = false;
    }

    // Erstellt die individuelle Willkommensnachricht aus den Nutzerdaten
    // Zweimaliger Aufruf der Funktion und Aussteuerung innerhalb --> Überschrift und zusätzliche Beschreibung für letzten Login
    getGreeting(type: string, isAdmin: boolean): string {
        const datum = this.userData.lastLoginDate;
        const zeit = this.userData.lastLoginTime;
        switch (type) {
            case 'headline':
                const name = isAdmin
                    ? this.communicationService.getAdmFullName()
                    : this.userData.salutation + ' ' + this.userData.title + ' ' + this.userData.firstname + ' ' + this.userData.lastname;
                const welcome = datum !== '' && zeit !== '' ? 'Willkommen zurück' : 'Herzlich Willkommen';

                return welcome + ': ' + name;
            // case 'description':
            // 	return datum !== '' && zeit !== '' ? 'Ihr letzter Login war am ' + datum + ' um ' + zeit + ' Uhr.' : '';
            default:
                return '';
        }
    }
}
