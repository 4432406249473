import { Injectable } from '@angular/core';

import { Subscription } from 'rxjs';

import { envExcelData } from '@classes/envExcelData';

import { RestService } from '@services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { envDownloadedDoc } from '../classes/envDownloadedDoc';
import { envDocument } from '@classes/envDocument';
import { AuthService } from '@auth/auth.service';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root',
})

// Service kümmert sich um den Download von Dokumenten
export class DownloadDocumentService {
    private subscription: Subscription;

    constructor(private readonly restService: RestService, private auth: AuthService, private readonly toastr: ToastrService) {}

    // Aus Base64 String und ContentType ein Blob erstellen
    private base64ToBlob(base64Data) {
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const byteLength = byteCharacters.length;
        const sliceCount = Math.ceil(byteLength / sliceSize);
        const byteArray = new Array(sliceCount);

        for (let sliceIndex = 0; sliceIndex < sliceCount; sliceIndex++) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, byteLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; i++, offset++) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArray[sliceIndex] = new Uint8Array(bytes);
        }

        return new Blob(byteArray);
    }

    // Aussteuerung der Downloadart nach aufrufender Komponente
    downloadDocument(kpId: string, docId: string, location: string, data: any, category: string = null) {
        switch (location) {
            case 'downloadComponent':
                this.downloadStaticDocument(docId);
                break;
            case 'timeline':
                this.downloadTimelineDocument(kpId, docId);
                break;
            case 'deliveryTable':
                this.downloadExcelDelivery(data);
                break;
            case 'countingValueTable':
                this.downloadExcelCountingValue(data);
                break;
            case 'userLogin':
                this.downloadExcelUserLogin();
                break;
            case 'fek':
                this.downloadFekDocument(kpId, docId, data);
                break;
            case 'agbDsUser':
                this.downloadCurrentAgbDsUser(docId, category);
                break;
            case 'agbDsAdmin':
                this.downloadCurrentAgbDsAdmin(docId, category);
                break;
            case 'addedValues':
                this.downloadAddedValuesDocumentPerYear(kpId, docId);
                break;
            case 'hardStaticDocument':
                this.downloadHardStaticDocument(docId);
                break;
            default:
        }
    }

    // Erstellt ein Linkelement mit den Daten des Dokuments und downloaded dieses durch Klick auf den Link
    showDocumentInBrowser(kpId, docId, method) {
        const a = document.createElement('a');
        const token = this.auth.getAccessToken();
        const appId = environment.appId;
        const restURL = environment.restBaseURL;
        const url = `${restURL}/${method}?kpId=${kpId}&docId=${docId}&generalFile=${false}&cache=${new Date().getTime()}&appId=${appId}&token=${token}`;
        a.style.display = 'none';
        a.href = url;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        });
    }

    // Erstellt ein Linkelement mit den Daten des Dokuments und downloaded dieses durch Klick auf den Link
    createLinkAndDownloadFile(docData, fileName, type) {
        if (fileName === undefined) {
            fileName = 'Kommunalportal-Download';
        }
        // Blob erstellen
        const blob = this.base64ToBlob(docData);
        // IE Sonderlocke
        const nav = window.navigator as any;
        if (nav && nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(blob, docData.name);
        } else {
            // PDF im neuen Tab öffnen
            let newBlob = new Blob([blob], { type: type });
            const url = URL.createObjectURL(newBlob);
            var anchor = document.createElement('a');
            anchor.download = fileName;
            anchor.href = url;
            anchor.click();
            anchor.remove();
        }
    }

    // Erstellt ein Linkelement mit den Daten des Dokuments und downloaded dieses durch Klick auf den Link
    createLinkAndDownloadZip(docData, fileName) {
        if (fileName === undefined) {
            fileName = 'Kommunalportal-Download';
        }
        // Blob erstellen
        const blob = this.base64ToBlob(docData);
        console.log(blob);
        // IE Sonderlocke
        const nav = window.navigator as any;
        if (nav && nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(blob, docData.name);
        } else {
            // PDF im neuen Tab öffnen
            let newBlob = new Blob([blob], { type: 'application/zip' });
            const url = URL.createObjectURL(newBlob);
            var anchor = document.createElement('a');
            anchor.download = fileName;
            anchor.href = url;
            anchor.click();
            anchor.remove();
        }
    }

    // Exceldownload aus DataTableComponent
    private downloadExcelDelivery(data: envExcelData) {
        this.restService.downloadExcelFromDpData(data);
        this.subscription = this.restService.$excelData.subscribe((excelData) => {
            if (excelData !== undefined) {
                //this.showDocumentInBrowser(excelData);
                this.restService.resetExcelDoc();
                this.subscription.unsubscribe();
            }
        });
    }

    // Exceldownload aus CountingValueTableComponent
    private downloadExcelCountingValue(data: any) {
        this.restService.downloadExcelFromCountingValue(data);
        this.subscription = this.restService.$excelDataCountingValue.subscribe((excelData) => {
            if (excelData !== undefined) {
                //this.showDocumentInBrowser(excelData);
                this.restService.resetExcelDocCountingValue();
                this.subscription.unsubscribe();
            }
        });
    }

    // asynchroner Exceldownload der User login infos
    private downloadExcelUserLogin() {
        this.restService.getLoginForAllUsers();
        this.subscription = this.restService.$loginForAllUsers.subscribe((data) => {
            if (data !== undefined) {
                if (data.status !== 200) {
                    this.toastr.error('Es gab einen Fehler beim Erstellen des Exports.', 'Fehler!');
                } else {
                    this.toastr.success(data.text);
                }
                this.subscription.unsubscribe();
                this.restService.resetLoginForAllUsers();
            }
        });
    }

    // Dokumentendownload aus TimelineComponent
    private downloadTimelineDocument(kpId: string, docId: string) {
        this.showDocumentInBrowser(kpId, docId, 'document');
    }

    // Statisches Dokumentendownload aus DownloadComponent
    private downloadStaticDocument(docId: string) {
        this.showDocumentInBrowser('', docId, 'staticDocument');
    }

    // Absolut Statisches Dokumentendownload aus DownloadComponent
    private downloadHardStaticDocument(docId: string) {
        this.restService.downloadHardStaticDoc(docId);
        this.subscription = this.restService.$downloadedHardStaticDoc.subscribe((docData) => {
            if (docData !== undefined) {
                //this.showDocumentInBrowser(docData);
                this.restService.resetStaticDoc();
                this.subscription.unsubscribe();
            }
        });
    }

    // Dokumentendownload aus Fek
    private downloadFekDocument(kpId: string, docId: string, fekId: string) {
        this.restService.downloadDocument(kpId, docId, true, fekId);

        this.subscription = this.restService.$downloadedDoc.subscribe((docData) => {
            if (docData !== undefined) {
                //this.showDocumentInBrowser(docData);
                this.restService.resetDoc();
                this.subscription.unsubscribe();
            }
        });
    }

    // Dokumentendownload aus Fek
    downloadDocumentsAsZip(kpId: string, documents: any[], fileName) {
        this.restService.downloadDocumentsAsZip(kpId, documents);
        this.subscription = this.restService.$downloadedZip.subscribe((docData) => {
            if (docData !== undefined) {
                this.createLinkAndDownloadZip(docData.data, fileName);
                this.restService.resetZip();
                this.subscription.unsubscribe();
            }
        });
    }

    // admin agb ds Dokumentendownload aus DownloadComponent
    private downloadCurrentAgbDsAdmin(type: string, category: string) {
        this.restService.downloadAgbdsDocAdmin(type, category);
        this.subscription = this.restService.$downloadedAgbdsDocAdmin.subscribe((docData) => {
            if (docData !== undefined) {
                //this.showDocumentInBrowser(docData);
                this.restService.resetAgbdsDocAdmin();
                this.subscription.unsubscribe();
            }
        });
    }

    // user agb ds Dokumentendownload aus DownloadComponent
    private downloadCurrentAgbDsUser(type: string, category: string) {
        this.restService.downloadAgbdsDocUser(type, category);
        this.subscription = this.restService.$downloadedAgbdsDocUser.subscribe((docData) => {
            if (docData !== undefined) {
                if (docData.name === '' && docData.daten === '') {
                    this.toastr.error('Die ausgewählte Datei konnte nicht heruntergeladen werden.', 'Fehler!');
                } else {
                    //this.showDocumentInBrowser(docData);
                }
                this.restService.resetAgbdsDocUser();
                this.subscription.unsubscribe();
            }
        });
    }

    // admin agb ds Dokumentendownload aus DownloadComponent
    private downloadAddedValuesDocumentPerYear(kpId: string, year: string) {
        this.restService.downloadAddedValuesDocumentPerYear(kpId, year);
        this.subscription = this.restService.$downloadedAddedValuesDocumentPerYear.subscribe((docData) => {
            if (docData !== undefined) {
                //this.showDocumentInBrowser(docData);
                this.restService.resetAddedValuesDocumentPerYear();
                this.subscription.unsubscribe();
            }
        });
    }
}
