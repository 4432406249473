<div class="envPageContentWrapper">
    <div class="envMaintenancePageContent">
        @if (hasError) {
        <div class="envMaintenanceWrapper">
            <div class="envMaintenanceHeadline">Fehler beim Laden der Seite</div>
            @if (errorType !== 'noCommunesExist' && errorType !== 'noUserRole' && errorType !== 'unknownUserRole') {
            <div class="envMaintenanceText">
                {{ errorText[0] }}
                <div class="envErrorText">{{ reason }}</div>
                {{ errorText[1] }}
            </div>
            } @if (errorType === 'noUserRole' || errorType === 'unknownUserRole' || errorType === 'noCommunesExist') {
            <div class="envMaintenanceText">
                Es gab einen Fehler mit dem Code: @if (errorType === 'noUserRole') {
                <div><br />KF21<br /></div>
                } @if (errorType === 'unknownUserRole') {
                <div><br />KF22<br /></div>
                } @if (errorType === 'noCommunesExist') {
                <div><br />KF23<br /></div>
                }
            </div>
            }
            <div class="envMaintenanceText">
                Versuchen Sie es später erneut oder melden Sie uns den Fehler an
                <a [href]="incidentMail">{{ incidentMailAddress }}</a
                >.
            </div>
        </div>
        } @if (maintenanceMode) {
        <div class="envMaintenanceWrapper">
            <div class="envMaintenanceHeadline">Das KommunalPortal befindet sich im Wartungsmodus.</div>
            <div class="envMaintenanceText">
                Versuchen Sie es später erneut oder wenden Sie sich an
                <a [href]="incidentMail">{{ incidentMailAddress }}</a
                >.
            </div>
        </div>
        }
    </div>
</div>
