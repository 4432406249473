import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { envModuleList, envModule } from '@classes/envModuleList';

@Injectable({
	providedIn: 'root',
})
export class ModularizationService {
	private readonly moduleList = new BehaviorSubject<envModuleList>(undefined);
	$moduleList = this.moduleList.asObservable();

	moduleListSubscription: Subscription;

	// Setter für ModuleList
	setModuleList(list: envModuleList) {
		this.moduleList.next(list);
	}

	// Initialisierung des sessionStorage Objekts, Update des SessionStorages sobald neue Daten vorhanden sind
	// SessionStorage wird genutzt, um bei Neuladen direkt eine ModuleList zur Authentifizierung zu haben
	initializeSessionStorage() {
		if (!this.moduleListSubscription) {
			this.moduleListSubscription = this.$moduleList.subscribe((list) => {
				if (list !== undefined) {
					sessionStorage.setItem('moduleList', JSON.stringify(list));
				}
			});
		}
	}

	// Komplette Variable abrufen
	getCompleteList(): envModuleList {
		return JSON.parse(sessionStorage.getItem('moduleList'));
	}

	// Anhand übergebener Route ein Modul abfragen
	getEntryFromList(entryRoute: string): envModule {
		const currModuleList = JSON.parse(sessionStorage.getItem('moduleList'));
		const entryInList: envModule = currModuleList.modules.filter((module) => module.id === entryRoute)[0];

		return entryInList;
	}
}
