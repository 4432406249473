<div class="envDialogWrapper">
    <div class="dialogContentWrapper">
        @if (hasNewAgb) {
        <div>
            <div class="envDialogHeadline">Neue Allgemeine Regelungen für das KommunalPortal</div>
        </div>
        }

        <!-- <div *ngIf="!hasNewAgb && hasNewDs">
    <div class="envDialogHeadline">
      Neue Datenschutzbestimmungen für das KommunalPortal
    </div>
  </div> -->

        @if (initialLoading) {
        <div>
            <mat-spinner class="envLoadSpinner" [diameter]="80"></mat-spinner>
        </div>
        } @if (!initialLoading) {
        <div>
            @if (hasNewAgb) {
            <div>
                <p class="envRowHeadline">
                    Die Allgemeinen Regelungen zur Nutzung des KommunalPortals der enviaM-Gruppe haben sich geändert. Für die weitere
                    Nutzung des KommunalPortals ist Ihre Zustimmung erforderlich.
                </p>
                <div class="envFormularWrapper">
                    <div class="envFormularRowWrapper row">
                        <div class="envFormularLablewrapper col-1">
                            <span class="icon-file-text2 envDocumentIcon" (click)="downloadDoc('currentAgb')"></span>
                        </div>
                        <div class="envFormularText col">Allgemeine Regelungen zur Nutzung des KommunalPortals der enviaM-Gruppe</div>
                    </div>
                    <div class="envFormularRowWrapper row">
                        <div class="envFormularLablewrapper col-1">
                            <span class="envInputButtonBox envRemoveTop">
                                <input class="envInputButtonRemover" type="checkbox" [(ngModel)]="agb" />
                                @if (agb) {
                                <span class="envInputButtonCheck"></span>
                                }
                            </span>
                        </div>
                        <div class="envFormularText col">
                            Ich habe die als Anlage beigefügten Allgemeinen Regelungen zur Nutzung des KommunalPortals von enviaM und MITGAS
                            gelesen und bin damit einverstanden.
                        </div>
                    </div>
                </div>
            </div>
            }
            <!-- <div *ngIf="hasNewDs">
      <p class="envRowHeadline">
        Neue Datenschutzbestimmungen für das KommunalPortal
      </p>
      <div *ngIf="hasNewDs" class="envFormularWrapper">
        <div class="envFormularRowWrapper row">
          <div class="envFormularLablewrapper col-1">
            <span class="icon-file-text2 envDocumentIcon" (click)="downloadDoc('currentDs')"></span>
          </div>
          <div class="envFormularText col">
            Datenschutzinformation
          </div>
        </div>
      </div>
    </div> -->
            @if (isLoading) {
            <div>
                <mat-spinner class="envLoadSpinner" [diameter]="80"></mat-spinner>
            </div>
            } @else { @if (hasNewAgb) {
            <div>
                <div class="envButtonWrapper row">
                    <div class="col">
                        <button
                            class="envStandardButton envSaveButton"
                            (click)="acceptNewAgbDs()"
                            [disabled]="!agb"
                            [ngClass]="agb ? '' : 'envDisabledButton'">
                            Bestätigen
                        </button>
                    </div>
                </div>
                <div class="envButtonWrapper row">
                    <div class="col">
                        <button class="envStandardButton envCloseButton" (click)="declineNewAgb()">Nicht zustimmen</button>
                    </div>
                </div>
            </div>
            }
            <!-- <div *ngIf="!hasNewAgb && hasNewDs">
      <div class="envButtonWrapper row">
        <div class="col">
          <button class="envStandardButton envSaveButton" (click)="acceptNewAgbDs()">OK</button>
        </div>
      </div>
    </div> -->
            }
        </div>
        }
    </div>
</div>
