// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//import { KeycloakConfig } from 'keycloak-angular';

import { responsiveSachsenEnergieConfig } from 'src/app/responsive/responsiveSachsenEnergieConfig';

const idp = {
    idpName: 'cidaas',
    mandant: 'SachsenEnergie',
    idpConfig: {
        authority: 'https://sachsenenergie-test.cidaas.eu',
        token_introspect_endpoint: 'https://sachsenenergie-test.cidaas.eu/token-srv/introspect',
        client_id: '8f2446ac-5a30-480e-afd2-23c4a2c595cc',
        redirect_uri: 'https://kp-test.sachsenenergie.de',
        post_logout_redirect_uri: 'https://kp-test.sachsenenergie.de',
        scopes: 'openid email roles profile',
    },
};

// groups setup
let userGroups = {
    user: 'Nutzer',
    admin: 'KomPo-Administrator',
    superAdmin: 'KomPo-Superadministrator',
    deliveryPointAdmin: 'Lieferstellenredakteur',
};

const footerBar = {
    imprint: 'https://www.sachsenenergie.de/wps/portal/energie/cms/menu_main/privatkunden',
    dataPrivacy: 'https://www.sachsenenergie.de/wps/portal/energie/cms/menu_main/privatkunden',
    generalRegulations: 'https://www.sachsenenergie.de/wps/portal/energie/cms/menu_main/privatkunden',
    legalInformation: 'https://www.sachsenenergie.de/wps/portal/energie/cms/menu_main/privatkunden',
    contact: 'https://www.sachsenenergie.de/wps/portal/energie/cms/menu_main/privatkunden',
};

export const environment = {
    production: true,
    idp: idp,
    responsiveConf: responsiveSachsenEnergieConfig,
    userManagmentUrl:
        'https://gisu1233.enviamgroup.de:10033/auth/realms/Kommunal-Portal-Test/protocol/openid-connect/auth?client_id=security-admin-console',
    userGroups: userGroups,
    assetPathBackend: '/assets/',
    restBaseURL: '/ibis/rest/rc/kompo_intern',
    restAdminBaseURL: '/ibis/rest/rc/kompo_intern/admin',
    restMockURL: '',
    useMockServer: false,
    footerBar: footerBar,
    appId: '51231-85320', //AppId wird im Backend einem Mandant zugeordnet
    topNavBarInfoText: 'SachsenEnergie-INFORMATIONEN für Kommunen',
    topNavBarInfoUrl: 'https://www.sachsenenergie.de/wps/portal/energie/cms/menu_main/kommunen', //noch zu setzen
    incidentMail: 'mailto:KommunalPortal@SachsenEnergie.de?subject=Kommunalportal - Störung melden',
    incidentMailAddress: 'KommunalPortal@SachsenEnergie.de',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
