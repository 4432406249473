import { Component, OnInit, Input } from '@angular/core';
import { envAccordion } from '@classes/envAccordion';
import { envPageData } from '@classes/envPageData';
import { envCommuneDate } from '@classes/envCommuneDate';
import { AdmModalSubjectAreaButtonComponent } from '@modals/adm-modal-subject-area-button/adm-modal-subject-area-button.component';
import { environment } from '@environments/environment';
import { envPicture } from '@classes/envPicture';
import { Subject } from 'rxjs';
import { CommunicationService } from '@services/communication.service';
import { RestService } from '@services/rest.service';
import { MatDialog } from '@angular/material/dialog';
import { AdmModalAssetsComponent } from '@modals/adm-modal-assets/adm-modal-assets.component';
import { ModalPromptComponent } from '@modals/modal-prompt/modal-prompt.component';

@Component({
    selector: 'app-adm-subject-area-edit',
    templateUrl: './adm-subject-area-edit.component.html',
    styleUrls: ['./adm-subject-area-edit.component.sass'],
})
export class AdmSubjectAreaEditComponent implements OnInit {
    @Input() newTitle: string;
    @Input() category: string;
    @Input() pageData: envPageData;
    @Input() subjectAreaData: any;
    @Input() inquiryTypeList: any;
    @Input() communeLists: envCommuneDate[];
    @Input() justEdit: boolean = false;
    @Input() usesInquiryTypes: boolean = false;

    private selectSubject: Subject<any>;
    restURL = environment.restBaseURL;
    assetPathBackend = environment.assetPathBackend;

    defaultAsset: any = undefined;

    constructor(
        private readonly restService: RestService,
        private readonly communicationService: CommunicationService,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        this.newTitle = typeof this.newTitle === 'undefined' ? 'Neue Klappbox anlegen' : this.newTitle;
        this.restService.getDefaultAsset('SUBJECTAREAPICTURE');
        this.restService.$defaultAssetResponse.subscribe((data) => {
            if (data !== undefined) {
                if (data.id === 'SUBJECTAREAPICTURE') {
                    this.defaultAsset = data;
                    this.restService.resetDefaultAssetResponse();
                    this.reorderAccordionArray();
                }
            }
        });
    }

    reorderAccordionArray() {
        if (typeof this.subjectAreaData === 'undefined' || this.subjectAreaData.length <= 0) {
            return false;
        }
        this.subjectAreaData.sort((e1, e2) => {
            if (e1.position > e2.position) {
                return 1;
            }
            if (e1.position < e2.position) {
                return -1;
            }

            return 0;
        });
        this.subjectAreaData.forEach((el, i) => {
            el.position = i;
        });
    }
    setAccordionData(a: envAccordion[]): void {
        this.subjectAreaData = a;
    }

    voteAccordionUp(el: envAccordion): void {
        const currPos = el.position; // old position value
        const newPos = currPos - 1; // new position value
        const oldEl = this.subjectAreaData[newPos]; // old element on new position
        const newEl = el; // current element on old position
        // reset position flags for reordering in fn reorderAccordionArray
        oldEl.position = currPos;
        newEl.position = newPos;

        this.reorderAccordionArray();
    }
    voteAccordionDown(el: envAccordion): void {
        const currPos = el.position; // old position value
        const newPos = currPos + 1; // new position value
        const oldEl = this.subjectAreaData[newPos]; // old element on new position
        const newEl = el; // current element on old position
        // reset position flags for reordering in fn reorderAccordionArray
        oldEl.position = currPos;
        newEl.position = newPos;

        this.reorderAccordionArray();
    }

    deleteAccordion(el: envAccordion): void {
        const dialogRef = this.dialog.open(ModalPromptComponent, {
            width: 'auto',
            disableClose: true,
            data: {
                title: 'Klappbox wirklich löschen?',
                bodyText: '',
                buttonText1: 'Nein',
                buttonClass1: 'btn btn-secondary',
                buttonText2: 'Ja',
                buttonClass2: 'btn btn-danger',
                isDecision: true,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined && result === 2) {
                this.deleteCallback(el);
            }
        });
    }

    deleteCallback(el: envAccordion): void {
        const index: number = this.subjectAreaData.indexOf(el, 0);
        if (index > -1) {
            this.subjectAreaData.splice(index, 1);
        }
        this.reorderAccordionArray();
    }

    addSubjectArea(): void {
        this.subjectAreaData.push({
            id: null,
            headline: '',
            position: this.subjectAreaData.length,
            contactMail: '',
            subjectareaPicture: this.defaultAsset,
            accordions: [
                {
                    headline: '',
                    position: 0,
                    isOpen: false,
                    type: 'html',
                    body: '',
                    hasDownloads: false,
                    downloadHeadline: '',
                    downloads: [],
                },
            ],
            description: '',
            inquiryTypes: this.inquiryTypeList,
        });

        this.reorderAccordionArray();
    }

    isInvalid = function (el): boolean {
        if (typeof this.pageData === 'undefined' || typeof this.pageData.error === 'undefined') {
            return false;
        }

        return typeof this.pageData.error[el] !== 'undefined';
    };

    clearEditor(position: number) {
        const element = document.getElementsByClassName('ql-editor');
        element[position].innerHTML = '';
    }

    editFiles(curFile: envPicture, type: string, areaPosition): void {
        // let files = this.restService.getAdminFiles(type);
        this.selectSubject = new Subject<any>();
        const currentFile = curFile;
        this.communicationService.setCurrentfile(curFile);

        // subscribe to event
        this.selectSubject.subscribe({
            next: (file) => {
                if (typeof file !== 'undefined') {
                    // copy some props:
                    currentFile.name = file.name;
                    currentFile.id = file.id;
                    currentFile.url = file.url;
                    currentFile.type = file.type;
                    currentFile.date = file.date;
                    this.selectSubject.unsubscribe();
                }
            },
        });

        // öffnet asset dialog
        const dialogRef = this.dialog.open(AdmModalAssetsComponent, {
            width: 'auto',
            data: {
                type,
                currentFile: curFile,
                headlineText: 'Themengruppe editieren',
                useSimpleUpload: false,
            },
        });

        // ausgewähltes asset hinterlegen
        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                if (result.type === 'SUBJECTAREAPICTURE') {
                    this.subjectAreaData[0].subjectareaPicture = result.selectedFile;
                }
                // this.toastr.error('Die hochgeladene Datei hat nicht die selbe Dateiendung.')
            }
        });
    }

    openModal(item) {
        const dialogRef2 = this.dialog.open(AdmModalSubjectAreaButtonComponent, {
            width: 'auto',
            data: {
                item,
            },
        });
        // Auswertung der Decision
        dialogRef2.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                for (const index in this.subjectAreaData) {
                    if (result.id === null) {
                        if (this.subjectAreaData[index].position === result.position) {
                            this.subjectAreaData[index] = result;
                        }
                    } else {
                        if (this.subjectAreaData[index].id === result.id) {
                            this.subjectAreaData[index] = result;
                        }
                    }
                }
            }
        });
    }

    getModelChangeItem(event, area) {
        if (event.value !== undefined) {
            area.description = event.value;
        }
    }
}
