import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MyCommuneComponent } from './start-pages/my-commune/my-commune.component';
import { ForgotAccessDataComponent } from './start-pages/forgot-access-data/forgot-access-data.component';
import { MaintenanceModeComponent } from './start-pages/maintenance-mode/maintenance-mode.component';
import { VerifyMailComponent } from './start-pages/verify-mail/verify-mail.component';
import { ConfirmRegistrationComponent } from './start-pages/confirm-registration/confirm-registration.component';

import { AuthGuard } from '@authConfig/authguard/app.authguard';
import { environment } from '@environments/environment';

// Navigationspunkte
export const routes: Routes = [
    // Offline- und Einstiegsseiten
    { path: 'meinekommune', component: MyCommuneComponent },
    { path: 'zugangsdatenvergessen', component: ForgotAccessDataComponent },
    { path: 'wartung', component: MaintenanceModeComponent },
    { path: 'verifyMail', component: VerifyMailComponent },
    { path: 'registierungbestaetigen', component: ConfirmRegistrationComponent },
    // Userbereich
    {
        path: 'user',
        //canActivate: [AuthGuard],
        data: { roles: [environment.userGroups.user] },
        loadChildren: () => import('./user-area/user-area.module').then((m) => m.UserAreaModule),
    },
    // Adminbereich
    {
        path: 'admin',
        //canActivate: [AuthGuard],
        data: {
            roles: [environment.userGroups.admin, environment.userGroups.superAdmin, environment.userGroups.deliveryPointAdmin],
        },
        loadChildren: () => import('./admin-area/admin-area.module').then((m) => m.AdminAreaModule),
    },
    // Wildcard
    { path: '**', redirectTo: '/meinekommune', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuard],
})
export class AppRoutingModule {}
