<div class="envDialogWrapper">
  <div class="dialogContentWrapper">
    <div class="envDialogHeadline">{{ headline }}</div>
    <p class="dialogText">{{ text }}</p>
    <div class="envFormularWrapper">
      @if (hasReason) {
        <div class="envFormularRowWrapper row">
          <div class="envFormularLablewrapper col-2">
            <div class="envFormularLable">Begründung:</div>
          </div>
          <div class="envFieldWrapper col">
            <div class="envInputFieldWrapper">
              <textarea
                rows="4"
                type="text"
                class="envInputAreaField form-control col"
                placeholder="Grund"
              [(ngModel)]="reasonText"></textarea>
            </div>
          </div>
        </div>
      }

      <div class="envButtonWrapper row">
        <div class="col">
          <button class="envStandardButton envCloseButton" (click)="closeDialog({ decision: false, reason: '' })">
            {{ buttonTextNo ? buttonTextNo : 'Abbrechen' }}
          </button>
          <button
            class="envStandardButton envSaveButton"
            (click)="saveDesicion()"
            [disabled]="!checkMandatoryFields()"
            [ngClass]="checkMandatoryFields() ? '' : 'envDisabledButton'">
            {{ buttonTextYes ? buttonTextYes : 'Bestätigen' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
