import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { RestService } from '@services/rest.service';
import { AuthService } from '@auth/auth.service';
import { KeycloakService } from 'keycloak-angular';
import { CommunicationService } from 'src/app/shared/services/communication.service';

@Component({
    selector: 'app-verify-mail',
    templateUrl: './verify-mail.component.html',
    styleUrls: ['./verify-mail.component.sass'],
})
export class VerifyMailComponent implements OnInit {
    // Variablen
    key: string = undefined;
    isLoading: boolean = false;
    errorTexts: string = undefined;
    registrationData: any = undefined;

    constructor(
        private readonly authService: AuthService,
        private readonly restService: RestService,
        private readonly toastr: ToastrService,
        private readonly communicationService: CommunicationService,
        private readonly keycloakService: KeycloakService,
        private readonly router: Router
    ) {}

    // Initialer Aufruf durch Nscale
    ngOnInit() {
        this.getLoggingStatus();
        this.communicationService.setBlockerStatus(false);
        this.key = this.getQueryParamy('key');
        this.restService.getAnmeldeDaten(this.key);
        this.restService.$getAnmeldeDatenResponse.subscribe((data: any) => {
            if (data !== undefined) {
                this.registrationData = data;
                this.restService.resetGetAnmeldeDatenResponse();
            }
        });
    }

    // Logging Status aus Service abfragen
    // Eingeloggt --> Umleitung auf "Meine Kommune"
    // Ausgeloggt --> Blocker entfernen
    async getLoggingStatus() {
        this.communicationService.setLoginStatus(await this.keycloakService.isLoggedIn());
        if (this.authService.checkUserPermission([], true)) {
            this.communicationService.setBlockerStatus(false);
        }
    }

    // Regex zum Heraussuchen von QueryParams
    getQueryParamy(name: string) {
        const url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    // Mail verifizieren
    verfiyMail() {
        this.isLoading = true;
        this.restService.getVerifyMail(this.key);
        this.restService.$verfiyMailResponse.subscribe((data: any) => {
            if (data !== undefined) {
                this.isLoading = false;
                if (data.status === 201) {
                    this.toastr.success('Ihre E-Mail wurde erfolgreich verifiziert.', '');
                    this.restService.resetGetVerifyMailResponse();
                    this.router.navigate(['/meinekommune']);
                } else {
                    this.toastr.error(data.text, 'Fehler');
                    this.restService.resetGetVerifyMailResponse();
                }
            }
        });
    }
}
