import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { envNavButton } from '@classes/envNavButton';
import { envCommunity } from '@classes/envCommunity';
import { RestService } from '@services/rest.service';

import { AuthService } from '@auth/auth.service';
import { CommunicationService } from '@services/communication.service';
import { ModularizationService } from '@services/modularization.service';
import { environment } from '@environments/environment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ResponsiveService } from '@services/responsive.service';

@Component({
    selector: 'app-my-commune',
    templateUrl: './my-commune.component.html',
    styleUrls: ['./my-commune.component.sass'],
})
export class MyCommuneComponent implements OnInit, OnDestroy {
    // Variablen
    navButtons: envNavButton[] = [];
    activeCommune: string = '';
    userData: any = undefined;
    newIcon = 'assets/images2/sternIcon_v2.png';

    fekNotification: boolean = false;
    isContentHeaderLoading: boolean = true;
    isLoading: boolean = true;
    userIsLoggedIn: boolean = false;
    newKADocuments: boolean = false;
    newKoRabDocuments: boolean = false;
    changesInMyData: boolean = false;
    newDeliveryPoints: boolean = false;

    activeCommuneSubscription: Subscription;
    loggingStatusSubscription: Subscription;

    //Responsive Vars
    boxesForMobileVersion = undefined;

    constructor(
        private readonly authService: AuthService,
        private readonly restService: RestService,
        private readonly router: Router,
        private readonly communicationService: CommunicationService,
        private readonly modService: ModularizationService,
        private readonly responsive: ResponsiveService
    ) {}

    // Einmalig beim Aufrufen der Seite
    ngOnInit() {
        // Überprüfung, ob passwordChange Flag gesetzt ist, wenn dann einloggen
        if (sessionStorage.getItem('wasPasswordChanged') === 'true') {
            sessionStorage.removeItem('wasPasswordChanged');
            this.authService.logoutAndRoute();
        }
        this.getCurrentLoggingStatus();

        this.responsive.$boxesForMobileVersion.subscribe((data) => {
            if (data !== undefined) {
                this.boxesForMobileVersion = data;
            }
        });
    }

    ngOnDestroy() {
        if (this.loggingStatusSubscription) {
            this.loggingStatusSubscription.unsubscribe();
        }
        if (this.activeCommuneSubscription) {
            this.activeCommuneSubscription.unsubscribe();
        }
    }

    // Derzeitigen LogIn Stand des Users abfragen und entsprechend die Struktur laden
    getCurrentLoggingStatus(): void {
        if (!this.loggingStatusSubscription) {
            this.loggingStatusSubscription = this.communicationService.isLoggedIn$.subscribe((loggingStatus) => {
                this.userIsLoggedIn = loggingStatus;
                // Wenn Nutzer eingeloggt, Seitendaten laden
                if (this.userIsLoggedIn) {
                    this.authService.checkTokenValidity();
                    this.getContentData();

                    // Ansonsten Offline-Navigation laden
                } else {
                    this.getAvailableNavButtons();
                    this.communicationService.setBlockerStatus(true);
                }
            });
        }
    }

    // Daten der aktiven Kommune und Nutzerdaten von Service holen
    getContentData(): void {
        if (!this.activeCommuneSubscription) {
            this.activeCommuneSubscription = this.communicationService.$activeCommuneData.subscribe((commune: envCommunity) => {
                if (commune !== undefined) {
                    this.newKADocuments = commune.newKADocuments;
                    this.newKoRabDocuments = commune.newKoRabDocuments;
                    this.newDeliveryPoints = commune.newDeliverypointsStrom || commune.newDeliverypointsGas ? true : false;
                    this.fekNotification = commune.newFek;
                    this.changesInMyData = commune.changesInMyData;
                    this.communicationService.$userData.subscribe((data: any) => {
                        if (data !== undefined) {
                            this.userData = data;
                            this.getAvailableNavButtons();
                        }
                    });
                }
            });
        }
    }

    // Verfügbare Navigationsknöpfe von Service holen und falls keine Rechte vorhanden sind sperren, oder Neuigkeiten anzeigen
    getAvailableNavButtons() {
        if (this.userIsLoggedIn) {
            this.navButtons = [];

            const possibleButtons = [
                {
                    name: 'Konzessionsabgabe',
                    id: 'kon',
                    className: 'green',
                    url: '/user/konzessionsabgabe',
                    isLocked: false,
                    notification: this.newKADocuments,
                    useLinebreaks: false,
                },
                {
                    name: 'Konzessionsvertrag',
                    id: 'concessionContract',
                    className: 'orangeRed',
                    url: '/user/konzessionsvertrag',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                {
                    name: 'Kommunalrabatt',
                    id: 'kom',
                    className: 'darkGreen',
                    url: '/user/kommunalrabatt',
                    isLocked: false,
                    notification: this.newKoRabDocuments,
                    useLinebreaks: false,
                },
                {
                    name: 'Lieferstellen Bearbeitung',
                    id: 'lib',
                    className: 'lightTurquoise',
                    url: '/user/lieferstellenbearbeitung',
                    isLocked: false,
                    notification: this.newDeliveryPoints,
                    useLinebreaks: false,
                },
                // {
                //     name: 'Fonds Energieeffizienz \nKommunen (FEK)',
                //     id: 'fek',
                //     className: 'lightYellow',
                //     url: '/user/fek',
                //     isLocked: false,
                //     notification: this.fekNotification,
                //     useLinebreaks: true,
                // },
                // {
                //     name: 'Dienstleistungen & Services',
                //     id: 'dus',
                //     className: 'red',
                //     url: '/user/dienstleistung',
                //     isLocked: false,
                //     notification: false,
                //     useLinebreaks: false,
                // },
                {
                    name: 'Kommunale Lieferstellen',
                    id: 'liz',
                    className: 'orange',
                    url: '/user/lieferstellenzaehlerstand',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                {
                    name: 'Aktionärsmanagement',
                    id: 'shareholderManagement',
                    className: 'red',
                    url: '/user/aktionaersmanagement',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                // {
                //     name: 'Wertschöpfung',
                //     id: 'wer',
                //     className: 'orangeRed',
                //     url: '/user/wertschoepfung',
                //     isLocked: false,
                //     notification: false,
                //     useLinebreaks: false,
                // },
                {
                    name: 'Energiewirtschaftliche Netzdaten',
                    id: 'ewnd',
                    className: 'orangeRed',
                    url: '/user/energienetzdaten',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                {
                    name: 'Gebietsbeirat',
                    id: 'kv',
                    className: 'darkBlue',
                    url: '/user/kommunalveranstaltungen',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                {
                    name: 'Meine Daten',
                    id: 'dat',
                    className: 'darkTurquoise',
                    url: '/user/meinedaten',
                    isLocked: false,
                    notification: this.changesInMyData,
                    useLinebreaks: false,
                },
                // {
                //     name: 'Umsatzsteuerdaten',
                //     id: 'ust',
                //     className: 'lightPurple',
                //     url: '/user/umsatzsteuerdaten',
                //     isLocked: false,
                //     notification: false,
                //     useLinebreaks: false,
                // },
                {
                    name: 'Gewerbesteuer',
                    id: 'businessTax',
                    className: 'lightYellow',
                    url: '/user/gewerbesteuer',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                {
                    name: 'Umsatzsteuer',
                    id: 'ustDataCommune',
                    className: 'lightPurple',
                    url: '/user/umsatzsteuer',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                {
                    name: 'Meine Ansprechpartner',
                    id: 'ans',
                    className: 'lightBlue',
                    url: '/user/meineansprechpartner',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                {
                    name: 'Häufig gestellte Fragen',
                    id: 'faq',
                    className: 'darkBlue',
                    url: '/user/faq',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                {
                    name: 'Onlineservices und Dienstleistungen',
                    id: 'online',
                    className: 'orangeRed',
                    url: '/user/onlineservices',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
            ];

            // Nur Buttons anzeigen, die freigeschaltet sind
            for (const button of possibleButtons) {
                const url = button.url.replace('/user/', 'user/');
                const currModule = this.modService.getEntryFromList(url);

                if (currModule && currModule.enabled) {
                    this.navButtons.push(button);
                }
            }
        } else {
            this.navButtons = [
                {
                    name: 'Konzessionsabgabe',
                    id: 'kon',
                    className: 'green',
                    url: '/meinekommune',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                {
                    name: 'Konzessionsvertrag',
                    id: 'concessionContract',
                    className: 'orangeRed',
                    url: '/user/konzessionsvertrag',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                {
                    name: 'Kommunalrabatt',
                    id: 'kom',
                    className: 'darkGreen',
                    url: '/meinekommune',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                {
                    name: 'Lieferstellen Bearbeitung',
                    id: 'lie',
                    className: 'lightTurquoise',
                    url: '/meinekommune',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                // {
                //     name: 'Fonds Energieeffizienz \nKommunen (FEK)',
                //     id: 'fek',
                //     className: 'lightYellow',
                //     url: '/meinekommune',
                //     isLocked: false,
                //     notification: false,
                //     useLinebreaks: true,
                // },
                // {
                //     name: 'Dienstleistungen & Services',
                //     id: 'dus',
                //     className: 'red',
                //     url: '/meinekommune',
                //     isLocked: false,
                //     notification: false,
                //     useLinebreaks: false,
                // },
                {
                    name: 'Kommunale Lieferstellen',
                    id: 'liz',
                    className: 'orange',
                    url: '/meinekommune',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                {
                    name: 'Aktionärsmanagement',
                    id: 'shareholderManagement',
                    className: 'red',
                    url: '/user/aktionaersmanagement',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                // {
                //     name: 'Wertschöpfung',
                //     id: 'wer',
                //     className: 'orangeRed',
                //     url: '/meinekommune',
                //     isLocked: false,
                //     notification: false,
                //     useLinebreaks: false,
                // },
                {
                    name: 'Energiewirtschaftliche Netzdaten',
                    id: 'ewnd',
                    className: 'orangeRed',
                    url: '/user/energienetzdaten',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                {
                    name: 'Gebietsbeirat',
                    id: 'kv',
                    className: 'darkBlue',
                    url: '/user/kommunalveranstaltungen',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                {
                    name: 'Meine Daten',
                    id: 'dat',
                    className: 'darkTurquoise',
                    url: '/meinekommune',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                // {
                //     name: 'Umsatzsteuerdaten',
                //     id: 'ust',
                //     className: 'lightPurple',
                //     url: '/meinekommune',
                //     isLocked: false,
                //     notification: false,
                //     useLinebreaks: false,
                // },
                {
                    name: 'Gewerbesteuer',
                    id: 'businessTax',
                    className: 'lightYellow',
                    url: '/user/gewerbesteuer',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                {
                    name: 'Umsatzsteuer',
                    id: 'ustDataCommune',
                    className: 'lightPurple',
                    url: '/meinekommune',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                {
                    name: 'Meine Ansprechpartner',
                    id: 'ans',
                    className: 'lightBlue',
                    url: '/meinekommune',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
                {
                    name: 'Häufig gestellte Fragen',
                    id: 'faq',
                    className: 'darkBlue',
                    url: '/meinekommune',
                    isLocked: false,
                    notification: false,
                    useLinebreaks: false,
                },
            ];
        }
        this.isLoading = false;
    }

    // Prüfung, ob Button für KoRab oder KA
    isKorabOrKa(navButton) {
        return navButton.name === 'Konzessionsabgabe' || navButton.name === 'Kommunalrabatt';
    }

    // Prüfung, ob Notification vorhanden
    isNotify(button): boolean {
        return button.id !== 'lie' ? button.notification : this.newDeliveryPoints;
    }
}
