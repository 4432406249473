<div class="envPageContentWrapper">
    <div class="envPageContent">
        <div class="entContentTextWrapper">
            <div class="envDialogHeadline">Anmeldung für das KommunalPortal</div>
            <p class="envDialogText">
                Bitte bestätigen Sie Ihre Anmeldung zum KommunalPortal durch Klick auf den Button.
                <!-- Den folgenden Text nur anzeigen, wenn ein Vollzugriff beantragt wurde -->
                @if (registrationData && registrationData.vollzugriff === 2) {
                <span> Nach der Bestätigung wird noch die Registrierung durch einen Kommunalverantwortlichen geprüft. </span>
                }
            </p>

            @if (isLoading) {
            <div>
                <mat-spinner class="envLoadSpinner" [diameter]="80"></mat-spinner>
            </div>
            } @else {
            <div class="envButtonWrapper row">
                <div class="col">
                    <button class="envStandardButton envSaveButton" (click)="verfiyMail()">Bestätigen</button>
                </div>
            </div>
            }
        </div>
    </div>
</div>
