<div class="envDialogWrapper">
    <div class="dialogContentWrapper">
        <div class="envDialogHeadline">Anmeldeformular für das KommunalPortal der enviaM-Gruppe</div>

        <p class="envDialogText">
            Das KommunalPortal der enviaM-Gruppe wurde für die digitale Bereitstellung Ihrer Dokumente zur Abrechnung der Konzessionsabgabe,
            des Kommunalrabatts sowie weiterer kommunaler Dienstleistungen entwickelt. Sie können fortan im Portal Ihre Unterlagen einsehen,
            downloaden und bei Bedarf ausdrucken. Weiterhin wird die jährliche Überprüfung der kommunalen Lieferstellen durch eine
            Online-Bearbeitung vereinfacht. Ab sofort sind auch die Anträge zum Fonds Energieeffizienz Kommunen über das KommunalPortal
            einzureichen. Für den erforderlichen Online-Zugang auf das KommunalPortal von enviaM und MITGAS ist eine Anmeldung durch den
            Kommune erforderlich. Daher bitten wir Sie, dieses Formular vollständig auszufüllen und abzusenden.
        </p>
        <div class="envFormularWrapper">
            @if (initalLoading) {
            <div>
                <mat-spinner class="envLoadSpinner" [diameter]="80"></mat-spinner>
            </div>
            } @if (!initalLoading) {
            <div>
                <div class="envFormularRowWrapper row">
                    <div class="envRowHeadline">Kommune:</div>
                </div>
                <div class="envFormularRowWrapper row">
                    <div class="envFormularLablewrapper col-2">
                        <div class="envFormularLable">Stadt/Gemeinde<br />Amt/VWG:</div>
                    </div>
                    <div class="envFieldWrapper col envCenterItem">
                        <app-dropdown
                            type="searchable"
                            anzeigevalue="gemeinde"
                            additionalValue="landkreis"
                            [data]="gemeinden"
                            spazialFunction="replaceVWG"
                            (selectedItemchanged)="getSelectedGemeindenInfos($event)"></app-dropdown>
                    </div>
                    <div class="col-1 envCenterItem">
                        <button class="envInfoButton" (click)="openInfoDialog('vwg')">
                            <span class="icon-info-circle"></span>
                        </button>
                    </div>
                </div>
                @if (isLoadingGemeindenInfos) {
                <div>
                    <mat-spinner class="envLoadSpinner" [diameter]="80"></mat-spinner>
                </div>
                } @if (selectedItemGemeinde !== undefined && selectedItemGemeinde.ortsgemeinden.length > 0) {
                <div class="envFormularRowWrapper row">
                    <div class="envFormularLablewrapper col-2">
                        <div class="envFormularLable">Ortsgemeinden:</div>
                    </div>
                    <div class="envFieldWrapper envCardWrapper col">
                        @for (ortsgemeide of selectedItemGemeinde.ortsgemeinden; track ortsgemeide; let i = $index) {
                        <div class="envCard row">
                            <div class="envFormularLablewrapper col-2">
                                <span class="envInputButtonBox envInputHelperBefor">
                                    <input
                                        class="envInputButtonRemover"
                                        type="checkbox"
                                        value="ortsgemeide.gemeinde"
                                        [(ngModel)]="ortsgemeide.checked" />
                                    @if (ortsgemeide.checked) {
                                    <span class="envInputButtonCheck"></span>
                                    }
                                </span>
                            </div>
                            <div class="envInputFieldWrapper col">
                                <div class="envFormularText envCardText">
                                    {{ ortsgemeide.gemeinde }}
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                }
                <div class="envFormularRowWrapper row">
                    <div class="envRowHeadline">Antragsteller:</div>
                </div>
                <div class="envFormularRowWrapper row">
                    <div class="envFormularLablewrapper col-2">
                        <div class="envFormularLable">Anrede:</div>
                    </div>
                    <div class="envFieldWrapper col">
                        <app-dropdown
                            size="small"
                            anzeigevalue="name"
                            [data]="anreden"
                            (selectedItemchanged)="this.selectedItemAnrede = $event"></app-dropdown>
                    </div>
                    <div class="envFormularLablewrapper col-2">
                        <div class="envFormularLable">Titel:</div>
                    </div>
                    <div class="envFieldWrapper col">
                        <app-dropdown
                            size="small"
                            anzeigevalue="value"
                            [data]="titles"
                            defaultselected="0"
                            (selectedItemchanged)="selectedItemTitle = $event"></app-dropdown>
                    </div>
                </div>
                <div class="envFormularRowWrapper row">
                    <div class="envFormularLablewrapper col-2">
                        <div class="envFormularLable">Name:</div>
                    </div>
                    <div class="envFieldWrapper col">
                        <div class="envInputFieldWrapper">
                            <input
                                class="envInputField"
                                [ngClass]="checkValidInput('name', name) ? '' : 'envInvalidInput'"
                                [(ngModel)]="name"
                                type="text" />
                        </div>
                    </div>
                    <div class="envInfoButtonWrapper col-1"></div>
                    <div class="envFormularLablewrapper col-2">
                        <div class="envFormularLable">Vorname:</div>
                    </div>
                    <div class="envFieldWrapper col">
                        <div class="envInputFieldWrapper">
                            <input
                                class="envInputField"
                                [ngClass]="checkValidInput('vorname', firstname) ? '' : 'envInvalidInput'"
                                [(ngModel)]="firstname"
                                type="text" />
                        </div>
                    </div>
                    <div class="envInfoButtonWrapper col-1"></div>
                </div>
                <div class="envFormularRowWrapper row">
                    <div class="envFormularLablewrapper col-2">
                        <div class="envFormularLable">Telefon:</div>
                    </div>
                    <div class="envFieldWrapper col">
                        <div class="envInputFieldWrapper">
                            <input
                                class="envInputField"
                                [ngClass]="checkValidInput('telefon', telefon) ? '' : 'envInvalidInput'"
                                [(ngModel)]="telefon"
                                type="text" />
                            @if (!isTelefonValid && telefon !== undefined && telefon !== '') {
                            <div class="envInvalidInput envInvalidFoneInput">Die Telefonnummer muss mit 0 beginnen</div>
                            }
                        </div>
                    </div>
                    <div class="envInfoButtonWrapper col-1"></div>
                    <div class="envFormularLablewrapper col-2">
                        <div class="envFormularLable">E-Mail:</div>
                    </div>
                    <div class="envFieldWrapper col">
                        <div class="envInputFieldWrapper">
                            <input
                                class="envInputField"
                                id="mailInput"
                                [ngClass]="checkValidInput('mail', mail) ? '' : 'envInvalidInputRegex'"
                                [(ngModel)]="mail"
                                type="text"
                                (focusout)="checkMail(mail)" />
                            <div class="envInvalidInput envInvalidText" id="envMailError">{{ this.isMailValidErrorText }}</div>
                        </div>
                    </div>
                    <div class="envInfoButtonWrapper col-1">
                        <button class="envInfoButton" (click)="openInfoDialog('mail')">
                            <span class="icon-info-circle"></span>
                        </button>
                    </div>
                </div>
                <div class="envFormularRowWrapper row">
                    <div class="envFormularLablewrapper col-2">
                        <div class="envFormularLable">Benutzername:</div>
                    </div>
                    <div class="envFieldWrapper col">
                        <div class="envInputFieldWrapper">
                            <input
                                class="envInputField"
                                id="usernameInput"
                                [ngClass]="checkValidInput('username', username) ? '' : 'envInvalidInputRegex'"
                                [(ngModel)]="username"
                                type="text"
                                (focusout)="checkUsername(username)" />
                            <div class="envInvalidInput envInvalidText" id="envUsernameError">
                                Der angegebene Benutzername ist bereits vergeben.
                            </div>
                        </div>
                    </div>
                    <div class="envInfoButtonWrapper col-1">
                        <button class="envInfoButton" (click)="openInfoDialog('username')">
                            <span class="icon-info-circle"></span>
                        </button>
                    </div>
                    <div class="envFieldWrapper col-6"></div>
                </div>
                <div class="envFormularRowWrapper row">
                    <div class="envFormularLablewrapper col-2">
                        <div class="envFormularLable">Funktion:</div>
                    </div>
                    <div class="envFieldWrapper col">
                        <app-dropdown
                            type="searchable"
                            anzeigevalue="value"
                            [data]="functions"
                            (selectedItemchanged)="selectedItemFunction = $event"></app-dropdown>
                    </div>
                    <div class="envInfoButtonWrapper col-1">
                        <button class="envInfoButton" (click)="openInfoDialog('function')">
                            <span class="icon-info-circle"></span>
                        </button>
                    </div>
                    <div class="envFieldWrapper col-6"></div>
                </div>
                <div class="envFormularRowWrapper row">
                    <div class="envFormularLablewrapper col-2">
                        <div class="envFormularLable">Benutzerrechte:</div>
                    </div>
                    <div class="col-1"></div>
                    <div class="envFieldWrapper col-2">
                        <div class="envFormularSubLable">Vollzugriff</div>
                        <span class="envInputButtonBox envInputHelperAfter envRadio">
                            <input
                                class="envInputButtonRemover"
                                type="radio"
                                name="benutzerrechte"
                                value="vollzugriff"
                                [(ngModel)]="zugriff" />
                            @if (zugriff === 'vollzugriff') {
                            <label for="benutzerrechte" class="envInputButtonCheck envRadio"></label>
                            }
                        </span>
                    </div>
                    <div class="envFieldWrapper col-1">
                        <button class="envInfoButton" (click)="openInfoDialog('vollzugriff')">
                            <span class="icon-info-circle"></span>
                        </button>
                    </div>
                    <div class="col-1"></div>
                    <div class="envFieldWrapper col-2">
                        <div class="envFormularSubLable">Teilzugriff</div>
                        <span class="envInputButtonBox envInputHelperAfter envRadio">
                            <input
                                class="envInputButtonRemover"
                                type="radio"
                                name="benutzerrechte"
                                value="teilzugriff"
                                [(ngModel)]="zugriff" />
                            @if (zugriff === 'teilzugriff') {
                            <label for="benutzerrechte" class="envInputButtonCheck envRadio"></label>
                            }
                        </span>
                    </div>
                    <div class="envFieldWrapper col-1">
                        <button class="envInfoButton" (click)="openInfoDialog('teilzugriff')">
                            <span class="icon-info-circle"></span>
                        </button>
                    </div>
                </div>
                <div class="envFormularRowWrapper row">
                    <div class="envFormularLablewrapper col-1">
                        <span class="icon-file-text2 envDocumentIcon" (click)="downloadDoc('currentAgb')"></span>
                    </div>
                    <div class="envFormularText col">Allgemeine Regelungen zur Nutzung des KommunalPortals der enviaM-Gruppe</div>
                </div>
                <div class="envFormularRowWrapper row envCenterAlign">
                    <div class="envFormularLablewrapper col-1">
                        <span class="envInputButtonBox envRemoveTop">
                            <input class="envInputButtonRemover" type="checkbox" [(ngModel)]="agb" />
                            @if (agb) {
                            <span class="envInputButtonCheck"></span>
                            }
                        </span>
                    </div>
                    <div class="envFormularText col">
                        Ich habe die als Anlage beigefügten Allgemeinen Regelungen zur Nutzung des KommunalPortals von enviaM und MITGAS
                        gelesen und bin damit einverstanden.
                    </div>
                </div>
                <div class="envFormularRowWrapper row envCenterAlign">
                    <div class="envFormularLablewrapper col-1">
                        <span class="envInputButtonBox envRemoveTop">
                            <input class="envInputButtonRemover" type="checkbox" [(ngModel)]="contact" />
                            @if (contact) {
                            <span class="envInputButtonCheck"></span>
                            }
                        </span>
                    </div>
                    <div class="envFormularText col">
                        Ich willige ein, im Rahmen der Kommunalbetreuung zu aktuellen Informationen aus der enviaM-Gruppe und zur Pflege der
                        Geschäftsbeziehungen über meine angegebene Telefonnummer und E-Mail-Adresse von enviaM bzw. MITGAS kontaktiert zu
                        werden. Ich kann diese Einwilligung jederzeit formlos ohne Angabe von Gründen widerrufen.
                    </div>
                </div>
                <div class="envFormularRowWrapper row">
                    <div class="envFormularLablewrapper col-1">
                        <span class="icon-file-text2 envDocumentIcon" (click)="downloadDoc('currentDs')"></span>
                    </div>
                    <div class="envFormularText col">Datenschutzinformation</div>
                </div>
                <p class="envDialogText">
                    Die Datenschutzinformation von enviaM und MITGAS zur Verarbeitung personenbezogener Daten im KommunalPortal sind Anlage
                    zu diesem Anmeldeformular.
                </p>
            </div>
            } @if (isLoading) {
            <div>
                <mat-spinner class="envLoadSpinner" [diameter]="80"></mat-spinner>
            </div>
            } @else {
            <div class="envButtonWrapper row">
                <div class="col">
                    <button class="envStandardButton envCloseButton" (click)="closeDialog(undefined)">Abbrechen</button>
                    <button
                        class="envStandardButton envSaveButton"
                        (click)="saveFormular()"
                        [disabled]="!checkMandatoryFields()"
                        [ngClass]="checkMandatoryFields() ? '' : 'envDisabledButton'">
                        Bestätigen
                    </button>
                </div>
            </div>
            }
        </div>
    </div>
</div>
