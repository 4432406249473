import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResponsiveService } from '@services/responsive.service';

@Component({
    selector: 'app-modal-communities',
    templateUrl: './modal-communities.component.html',
    styleUrls: ['./modal-communities.component.sass'],
})
export class ModalCommunitiesComponent implements OnInit {
    activeCommune: string = '';
    isModalDropdownActive: boolean = false;
    myCommunities: string[] = [];

    //Responsive Variables
    isMobilePortraitView = undefined;

    constructor(
        public dialogRef: MatDialogRef<ModalCommunitiesComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        public responsive: ResponsiveService
    ) {}

    ngOnInit(): void {
        this.myCommunities = this.dialogData.myCommunities;

        this.responsive.$isMobilePortraitView.subscribe((data) => {
            this.isMobilePortraitView = data;
        });
    }

    // Schließen des Dialogs
    closeDialog(tmpItem: any) {
        this.dialogRef.close(tmpItem);
    }

    changeDropdown() {
        this.isModalDropdownActive = !this.isModalDropdownActive;
    }

    // Derzeit aktive Kommune auf Variable speichern und Dropdown anpassen
    setActiveCommune(clickedCommune: string) {
        this.activeCommune = clickedCommune;
        this.changeDropdown();
    }

    checkActiveCommune() {
        if (this.activeCommune !== '') {
            this.closeDialog(this.activeCommune);
        }
    }
}
