<div class="envDownloadEditorWrapper content-area">
  <div class="form-group row element">
    <label class="col-form-label col-sm-3">Überschrift Downloads:</label>
    <div class="col-sm-9">
      <input type="text" class="form-control" [(ngModel)]="downloadHeadline" placeholder="Downloads" name="downloadHeadline" (ngModelChange)="emitDownloadHeadline(downloadHeadline)" />
    </div>
  </div>
  <div class="orderingArea">
    @for (download of downloads; track download; let downloadPosition = $index) {
      <div class="component" id="download{{ download.position }}">
        <div [ngClass]="pageData.siteName === 'propertyManagement' ? 'downloadSmall' : 'download'">
          <input type="text" class="form-control" placeholder="Name der Verknüpfung" [(ngModel)]="download.name" name="name" />
        </div>
        <div class="row">
          <div class="float-left col">
            <button type="button" class="btn btn-secondary button-edit" (click)="editDownload(download, 'DOWNLOAD', downloadPosition)"><i class="icon-pencil"></i></button>
          </div>
          <div class="col">
            <div>
              <button type="button" class="btn btn-secondary button-position-up" [disabled]="download.position === 0" (click)="voteDownloadUp(download)">
                <i class="icon-angle-up"></i>
              </button>
            </div>
            <div>
              <button type="button" class="btn btn-secondary button-position-down" [disabled]="download.position === downloads.length - 1" (click)="voteDownloadDown(download)">
                <i class="icon-angle-down"></i>
              </button>
            </div>
          </div>
          <div class="float-right col">
            <button type="button" class="btn btn-danger button-delete" (click)="deleteDownload(download)"><i class="icon-minus-circle"></i></button>
          </div>
        </div>
      </div>
    }
    <div class="component">
      <div class="headline btn">neues Download-Dokument verknüpfen</div>
      <div class="button">
        <button class="btn btn-success button-add" (click)="addDownload()"><i class="icon-plus-circle"></i></button>
      </div>
    </div>
  </div>
</div>
