<div class="envPageContentWrapper">
    <div class="envPageContent">
        @if (initialLoading) {
        <div>
            <div class="envLoadSpinnerWrapper">
                <mat-spinner class="envLoadSpinner" [diameter]="80"></mat-spinner>
            </div>
        </div>
        } @if (!initialLoading) {
        <div class="entContentTextWrapper">
            <div class="envDialogHeadline">Anmeldungsbestätigung für das KommunalPortal der enviaM-Gruppe</div>
            <p class="envDialogText">
                Es hat sich der unten stehende Nutzer für das KommunalPortal für ihre Kommune angemeldet. Bitte bestätigen Sie den Nutzer
                oder lehnen Sie ihn ab.
            </p>
            <div class="envFormularRowWrapper row">
                <div class="envFormularLableWrapper col-2">
                    <div class="envFormularLable">Gemeinde:</div>
                </div>
                <div class="envFieldWrapper col">
                    <div class="envFormularText">
                        {{ registrationData ? registrationData.gemeinde : '' }}
                    </div>
                </div>
                <div class="envInfoButtonWrapper col-1"></div>
                <div class="envFormularLableWrapper col-2">
                    <div class="envFormularLable">Ortsgemeinde:</div>
                </div>
                <div class="envFieldWrapper col">
                    <div class="envFormularText">
                        {{ registrationData ? registrationData.ortsgemeinden : '' }}
                    </div>
                </div>
            </div>
            <div class="envFormularRowWrapper row">
                <div class="envFormularLableWrapper col-2">
                    <div class="envFormularLable">Anrede:</div>
                </div>
                <div class="envFieldWrapper col">
                    <div class="envFormularText">
                        {{ registrationData ? registrationData.anrede : '' }}
                    </div>
                </div>
                <div class="envInfoButtonWrapper col-1"></div>
                <div class="envFormularLableWrapper col-2">
                    <div class="envFormularLable">Titel:</div>
                </div>
                <div class="envFieldWrapper col">
                    <div class="envFormularText">
                        {{ registrationData ? registrationData.titel : '' }}
                    </div>
                </div>
            </div>
            <div class="envFormularRowWrapper row">
                <div class="envFormularLableWrapper col-2">
                    <div class="envFormularLable">Vorname:</div>
                </div>
                <div class="envFieldWrapper col">
                    <div class="envFormularText">
                        {{ registrationData ? registrationData.vorname : '' }}
                    </div>
                </div>
                <div class="envInfoButtonWrapper col-1"></div>
                <div class="envFormularLableWrapper col-2">
                    <div class="envFormularLable">Nachname:</div>
                </div>
                <div class="envFieldWrapper col">
                    <div class="envFormularText">
                        {{ registrationData ? registrationData.nachname : '' }}
                    </div>
                </div>
            </div>
            <div class="envFormularRowWrapper row">
                <div class="envFormularLableWrapper col-2">
                    <div class="envFormularLable">Funktion:</div>
                </div>
                <div class="envFieldWrapper col">
                    <div class="envFormularText">
                        {{ registrationData ? registrationData.funktion : '' }}
                    </div>
                </div>
                <div class="envInfoButtonWrapper col-1"></div>
                <div class="envFormularLableWrapper col-2">
                    <div class="envFormularLable">E-Mail:</div>
                </div>
                <div class="envFieldWrapper col">
                    <div class="envFormularText">
                        {{ registrationData ? registrationData.email : '' }}
                    </div>
                </div>
            </div>
            <div class="envFormularRowWrapper row">
                <div class="envFormularLableWrapper col-2">
                    <div class="envFormularLable">Telefon:</div>
                </div>
                <div class="envFieldWrapper col">
                    <div class="envFormularText">
                        {{ registrationData ? registrationData.telefon : '' }}
                    </div>
                </div>
                <div class="envInfoButtonWrapper col-1"></div>
                <div class="envFormularLableWrapper col-2">
                    <div class="envFormularLable">Zugriff:</div>
                </div>
                <div class="envFieldWrapper col">
                    <div class="envFormularText">
                        {{ registrationData ? (registrationData.vollzugriff === 1 ? 'Vollzugriff' : 'Teilzugriff') : '' }}
                    </div>
                </div>
            </div>
            <!--  <div class="envFormularRowWrapper row">
        <div class="envFormularLableWrapper col-2">
          <div class="envFormularLable">
            Benutzername:
          </div>
        </div>
        <div class="envFieldWrapper col">
          <div class="envFormularText">
            {{registrationData?registrationData.benutzername:''}}
          </div>
        </div>
      </div> -->
            @if (isLoading) {
            <div>
                <mat-spinner class="envLoadSpinner" [diameter]="80"></mat-spinner>
            </div>
            } @else {
            <div class="envButtonWrapper row">
                <div class="col">
                    <button class="envStandardButton envCloseButton" (click)="openConfirmDialog()">Ablehnen</button>
                    <button class="envStandardButton envSaveButton" (click)="confirmRegistration(true)">Bestätigen</button>
                </div>
            </div>
            }
        </div>
        }
    </div>
</div>
