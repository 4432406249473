import { Component, OnInit, Inject } from '@angular/core';

import { RestService } from '@services/rest.service';
import { DownloadDocumentService } from '@services/download-document.service';

import { ModalInfoComponent } from '../modal-info/modal-info.component';

import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { Subscription } from 'rxjs';

@Component({
    selector: 'app-modal-registration',
    templateUrl: './modal-registration.component.html',
    styleUrls: ['./modal-registration.component.sass'],
})
export class ModalRegistrationComponent implements OnInit {
    domElement: HTMLElement;

    selectedItemGemeinde: any = undefined;
    setSelectedItemGemeinde: any = undefined;
    // selectedItemVwg: any = undefined;
    selectedItemTitle: any = undefined;
    selectedItemAnrede: any = undefined;
    selectedItemFunction: any = undefined;
    isDropdownActiveGemeinde: boolean = false;
    isDropdownActiveVwg: boolean = false;
    isDropdownActiveTitle: boolean = false;
    isDropdownActiveAnrede: boolean = false;
    isDropdownActiveFunction: boolean = false;

    isUsernameValid: boolean = false;
    isMailValid: boolean = false;
    isMailRegexValid: boolean = false;
    isMailValidErrorText: string = '';
    isTelefonValid: boolean = false;

    // isGemeinde: boolean = false;
    gemeinde: any = undefined;
    // vwg: any = undefined;
    // isVwg: boolean = false;

    gemeinden: any = undefined;
    ortsgemeiden: any = undefined;
    titles: any = undefined;
    functions: any = undefined;
    function: any = undefined;

    dropdownList: any = undefined;

    private subscription: Subscription;

    anreden: any = [
        {
            name: 'Herr',
            value: 'M',
        },
        {
            name: 'Frau',
            value: 'F',
        },
    ];

    name: string = '';
    firstname: string = '';
    telefon: string = '';
    mail: string = '';
    nachname: string = '';
    username: string = '';
    zugriff: string = '';
    agb: boolean = false;
    contact: boolean = false;

    invalidInput: boolean = true;
    isLoading: boolean = false;
    isLoadingGemeindenInfos: boolean = false;
    initalLoading: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<ModalRegistrationComponent>,
        private readonly restService: RestService,
        private readonly toastr: ToastrService,
        private readonly downloadDocumentService: DownloadDocumentService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.getSiteData();
    }

    getSiteData() {
        this.restService.getOfflineContentData('myCommune', 'not needed').subscribe((data: any) => {
            if (data !== undefined) {
                this.functions = data.diganFunction;
                this.titles = data.diganTitle;
                this.getGemeinden();
            }
        });
    }

    getGemeinden() {
        this.restService.getGemeindeList('', 'digan');
        this.restService.$getGemeinden.subscribe((data: any) => {
            if (data !== undefined) {
                this.restService.resetGetGemeindeListResponse();
                this.gemeinden = data;
                this.initalLoading = false;
            }
        });
    }

    // open
    openInfoDialog(fieldName) {
        let headline: string;
        let text: string;

        switch (fieldName) {
            case 'gemeinde':
                headline = 'Stadt/Gemeinde';
                text =
                    'In diesem Feld wählen Sie Ihre Kommune aus. Es kann nur eine Kommune ausgewählt werden. Ist bei Ämtern und Verwaltungsgemeinschaften der Zugriff auf mehrere Kommunen erforderlich bitte das Eingabefeld Amt/VWG benutzen.';
                break;
            case 'vwg':
                headline = 'Amt/VWG';
                text =
                    'In diesem Feld können Sie für Ihr Amt bzw. Verwaltungsgemeinschaft die zugehörigen Kommunen einzeln in dem sich öffnenden Fenster auswählen. ';
                break;
            case 'mail':
                headline = 'E-Mail';
                text =
                    'In diesem Feld tragen Sie Ihre persönliche, kommunale E-Mail-Adresse ein. Sammel-E-Mail-Adressen, bei denen mehrere Personen Zugriff haben, z.B. info@stadt.de, bauamt@stadt.de sind nicht zugelassen.';
                break;
            case 'username':
                headline = 'Benutzername';
                text =
                    'Für die Anmeldung am KommunalPortal benötigen Sie einen eigenen Benutzernamen. Diesen Benutzernamen können Sie selbst auswählen. Er muss mindestens 6 Zeichen lang sein und darf nur Buchstaben (groß/klein) und Zahlen enthalten.';
                break;
            case 'function':
                headline = 'Funktion';
                text = 'In diesem Feld wählen Sie Ihre dienstliche Funktion in der Kommune aus.';
                break;
            case 'vollzugriff':
                headline = 'Vollzugriff';
                text =
                    'Der Online-Zugang zum KommunalPortal berücksichtigt verschiedene Benutzerrechte. Bei einem Vollzugriff erhält der Benutzer den Zugang zu allen im KommunalPortal - zu seiner Kommune - eingestellten Informationen und Bearbeitungsmöglichkeiten. Dies umfasst u.a. die Abrechnungen von Konzessionsabgabe und Kommunalrabatt, die Prüfung und Bearbeitung kommunaler Lieferstellen sowie die Antragstellung zum „Fonds Energieeffizienz für Kommunen“. ';
                break;
            case 'teilzugriff':
                headline = 'Teilzugriff';
                text =
                    'Bei einem Teilzugriff hat der Benutzer keinen Zugang zu den Abrechnungen von Konzessionsabgabe und Kommunalrabatt. Alle anderen Informationen und Bearbeitungsmöglichkeiten wie der Prüfung und Bearbeitung kommunaler Lieferstellen und der Antragstellung zum „Fonds Energieeffizienz für Kommunen“ stehen zur Verfügung.';
                break;
            default:
        }

        const dialogRef = this.dialog.open(ModalInfoComponent, {
            width: 'auto',
            disableClose: true,
            data: {
                headline,
                bodyText: text,
            },
        });
        dialogRef.afterClosed().subscribe();
    }

    saveFormular() {
        if (this.checkMandatoryFields() && !this.invalidInput && this.isUsernameValid) {
            this.isLoading = true;
            const body: any = {
                gemeinden: this.selectedItemGemeinde,
                anrede: this.selectedItemAnrede,
                titel: this.selectedItemTitle,
                nachname: this.name,
                vorname: this.firstname,
                telefon: this.telefon,
                email: this.mail,
                benutzername: this.username,
                funktion: this.selectedItemFunction,
                vollzugriff: this.zugriff === 'vollzugriff' ? 1 : 2,
                newsletter: this.contact,
            };
            this.restService.sendRegistration(body);
            this.restService.$registrationResponse.subscribe((data: any) => {
                if (data !== undefined) {
                    if (data.status === 201) {
                        this.restService.resetSendRegistrationResponse();
                        this.toastr.success('Registrierung erfolgreich abgesendet!', '');
                        this.closeDialog(201);
                    } else {
                        this.toastr.error(data.text, 'Fehler!');
                        this.isLoading = false;
                    }
                }
            });
        }
    }

    checkMandatoryFields() {
        if (!this.selectedItemGemeinde) {
            return false;
        } else {
            if (this.selectedItemGemeinde.parentorgaid !== undefined && this.selectedItemGemeinde.parentorgaid === '-1') {
                let isChecked = false;
                for (const currOrtsgemeinde of this.selectedItemGemeinde.ortsgemeinden) {
                    if (currOrtsgemeinde.checked === true) {
                        isChecked = true;
                    }
                }
                if (!isChecked) {
                    return false;
                }
            }
        }

        if (!this.selectedItemAnrede) {
            return false;
        }

        if (!this.name) {
            return false;
        }

        if (!this.firstname) {
            return false;
        }

        if (!this.mail || !this.isMailValid || !this.isMailRegexValid) {
            return false;
        }

        if (!this.username || !this.isUsernameValid) {
            return false;
        }

        if (!this.selectedItemFunction) {
            return false;
        }

        if (!this.telefon || !this.isTelefonValid) {
            return false;
        }

        if (!this.zugriff) {
            return false;
        }

        if (!this.agb) {
            return false;
        }

        if (!this.contact) {
            return false;
        }

        return true;
    }

    checkValidInput(fieldname: string, value: string): boolean {
        let isValidInput = false;
        if (value !== undefined && value !== '') {
            switch (fieldname) {
                case 'vorname':
                    isValidInput = value.length > 2 && value.length <= 32;
                    break;
                case 'name':
                    isValidInput = value.length > 2 && value.length <= 32;
                    break;
                case 'mail':
                    isValidInput = value.length > 6 && value.length <= 255 && value.match('.+[@]{1}.+[.]{1}.+') !== null;
                    this.isMailRegexValid = value.length > 6 && value.length <= 255 && value.match('.+[@]{1}.+[.]{1}.+') !== null;
                    break;
                case 'telefon':
                    isValidInput = value.length <= 50 && value.match('^0') !== null;
                    this.isTelefonValid = value.length <= 50 && value.match('^0') !== null;
                    break;
                case 'username':
                    isValidInput = value.length <= 128;
                    break;
                default:
            }
            this.invalidInput = !isValidInput;
        } else {
            this.invalidInput = true;
            isValidInput = true;
        }

        return isValidInput;
    }

    checkUsername(name) {
        this.domElement = document.getElementById('usernameInput') as HTMLInputElement;
        const errorText = document.getElementById('envUsernameError') as HTMLInputElement;
        if (name !== '') {
            this.isLoading = true;
            this.restService.checkUsernameExists(this.encodeString(name));
            this.restService.$checkUsername.subscribe((data: any) => {
                if (data !== undefined) {
                    this.isLoading = false;
                    this.restService.resetCheckUsernameExistsResponse();
                    if (data.status === '200') {
                        this.domElement.classList.remove('envInvalidInput');
                        errorText.style.visibility = 'hidden';
                        errorText.style.display = 'none';
                        this.isUsernameValid = true;
                    } else {
                        this.domElement.classList.add('envInvalidInput');
                        this.isUsernameValid = false;
                        errorText.style.visibility = 'visible';
                        errorText.style.display = 'block';
                    }
                }
            });
        } else {
            this.domElement.classList.remove('envInvalidInput');
            errorText.style.visibility = 'hidden';
            errorText.style.display = 'none';
            this.isUsernameValid = false;
        }
    }

    // String in Base64 umwandeln wegen IE
    encodeString(name: string): string {
        return btoa(encodeURIComponent(name));
    }

    checkMail(mail) {
        this.domElement = document.getElementById('mailInput') as HTMLInputElement;
        const errorText = document.getElementById('envMailError') as HTMLInputElement;

        // check if there is whitespace in mail (KOM-194)
        if (mail.indexOf(' ') !== -1) {
            this.domElement.classList.add('envInvalidInput');
            this.isMailValid = false;
            errorText.style.visibility = 'visible';
            errorText.style.display = 'block';
            this.isMailValidErrorText = 'Die angegebene E-Mail enthält ein Leerzeichen.';

            return;
        }

        // check if mail exists in KeyCloak
        if (mail.length > 5) {
            this.isLoading = true;
            this.restService.checkMailExists(this.encodeString(mail));
            this.restService.$checkMail.subscribe((data: any) => {
                if (data !== undefined) {
                    this.isLoading = false;
                    this.restService.resetCheckMailExistsResponse();
                    if (data.status === '200') {
                        this.domElement.classList.remove('envInvalidInput');
                        this.isMailValid = true;
                        errorText.style.visibility = 'hidden';
                        errorText.style.display = 'none';
                        this.isMailValidErrorText = '';
                    } else {
                        this.domElement.classList.add('envInvalidInput');
                        this.isMailValid = false;
                        errorText.style.visibility = 'visible';
                        errorText.style.display = 'block';
                        this.isMailValidErrorText = 'Die angegebene E-Mail ist bereits vergeben.';
                    }
                }
            });
        } else {
            this.isMailValid = false;
            errorText.style.visibility = 'visible';
            errorText.style.display = 'block';
            this.isMailValidErrorText = 'Die angegebene E-Mail ist zu kurz.';
        }
    }

    // Download des angeklickten Dokuments
    downloadDoc(type: string) {
        this.downloadDocumentService.downloadDocument(undefined, type, 'agbDsUser', undefined, 'KomPo');
    }

    getSelectedGemeindenInfos(event) {
        if (event !== undefined) {
            this.isLoadingGemeindenInfos = true;
            this.selectedItemGemeinde = undefined;
            this.restService.getClickedGemeindeInfos(event.ags, event.orgaid, event.parentorgaid);
            this.subscription = this.restService.$getGemeindeInfos.subscribe((data: any) => {
                if (data !== undefined) {
                    this.subscription.unsubscribe();
                    this.restService.resetGetClickedGemeindeInfosResponse();
                    this.isLoadingGemeindenInfos = false;
                    this.selectedItemGemeinde = data[0];
                    for (const currOrtsgemeinde of this.selectedItemGemeinde.ortsgemeinden) {
                        currOrtsgemeinde.checked = currOrtsgemeinde.ags === event.ags || currOrtsgemeinde.gemeinde === event.gemeinde;
                    }
                }
            });
        }
    }

    // Schließen des Dialogs
    closeDialog(tmpItem: any) {
        this.dialogRef.close(tmpItem);
    }
}
