import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import Quill from 'quill';

@Component({
    selector: 'app-quill-wrapper',
    templateUrl: './quill-wrapper.component.html',
    styleUrls: ['./quill-wrapper.component.sass'],
})
export class QuillWrapperComponent implements OnInit {
    @Input() body: any;
    @Input() size: string = 'default';
    @Output() modelChange = new EventEmitter<any>();
    quill = undefined;
    options = {
        modules: {
            toolbar: '#toolbar-container',
        },
        theme: 'snow',
    };

    ngOnInit(): void {
        this.quill = new Quill('editor-container', this.options);
    }

    emitModelChange(value, label) {
        const tmpItem = {
            value,
            label,
        };

        this.modelChange.emit(tmpItem);
    }
}
