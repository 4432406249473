import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-modal-confirm',
    templateUrl: './modal-confirm.component.html',
    styleUrls: ['./modal-confirm.component.sass'],
})
export class ModalConfirmComponent implements OnInit {
    // Variablen
    headline: string = '';
    text: string = '';
    hasReason: boolean = false;
    isReasonMandatory: boolean = false;
    buttonTextNo: any = false;
    buttonTextYes: any = false;

    reasonText: string = '';

    constructor(
        public dialogRef: MatDialogRef<ModalConfirmComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.setVariables();
    }

    setVariables() {
        this.headline = this.data.headline;
        this.text = this.data.text;
        this.hasReason = this.data.hasReason;
        this.isReasonMandatory = this.data.isReasonMandatory;
        this.buttonTextNo = this.data.buttonTextNo ? this.data.buttonTextNo : this.buttonTextNo;
        this.buttonTextYes = this.data.buttonTextYes ? this.data.buttonTextYes : this.buttonTextYes;
    }

    closeDialog(tmpItem: any) {
        this.dialogRef.close(tmpItem);
    }

    saveDesicion() {
        let tempItem: any;
        if (this.hasReason) {
            tempItem = {
                decision: true,
                reason: this.reasonText,
            };
        } else {
            tempItem = {
                decision: true,
                reason: '',
            };
        }
        this.closeDialog(tempItem);
    }

    checkMandatoryFields() {
        let bool = false;
        if (this.hasReason) {
            if (this.isReasonMandatory) {
                if (this.reasonText !== '') {
                    bool = true;
                }
            } else {
                bool = true;
            }
        } else {
            bool = true;
        }

        return bool;
    }
}
