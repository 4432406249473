import { HttpParams } from '@angular/common/http';

const ll = {
    name: 'Lieferstellen-Listen',
    id: 'lil',
    className: 'lightPurple',
    isLocked: false,
    notification: false,
    url: '/admin/lieferstellenlisten',
    useLinebreaks: false,
};
const lb = {
    name: 'Lieferstellen-Bemerkungsfeld-Texte',
    id: 'lib',
    className: 'green',
    isLocked: false,
    notification: false,
    url: '/admin/lieferstellenbemerkung',
    useLinebreaks: false,
};
const lz = {
    name: 'Lieferstellen-Zählerstand',
    id: 'liz',
    className: 'yellow',
    isLocked: false,
    notification: false,
    url: '/admin/lieferstellenzaehlerstand',
    useLinebreaks: false,
};

export const Navigation = {
    // Online-Navigation erstellen und zurückliefern
    getNavigation(moduleList) {
        const possibleSites = [
            { name: 'Meine Kommune', url: '/meinekommune', className: 'blue' },
            { name: 'Konzessionsabgabe', url: '/user/konzessionsabgabe', className: 'green' },
            { name: 'Konzessionsvertrag', url: '/user/konzessionsvertrag', className: 'green' },
            { name: 'Kommunalrabatt', url: '/user/kommunalrabatt', className: 'darkGreen' },
            {
                name: 'Lieferstellen Bearbeitung',
                url: '/user/lieferstellenbearbeitung',
                className: 'lightTurquoise',
                unterseiten: [
                    { name: 'Lieferstellenübersicht Strom', url: '/user/lieferstellenbearbeitung/uebersichtstrom' },
                    { name: 'Lieferstellenübersicht Gas', url: '/user/lieferstellenbearbeitung/uebersichtgas' },
                ],
            },
            {
                name: 'Dienstleistungen & Services',
                url: '/user/dienstleistung',
                className: 'red',
                unterseiten: [
                    { name: 'e-Mobility erFAHRen', url: '/user/dienstleistung/emobility' },
                    { name: 'Präsenz auf Stadtfesten', url: '/user/dienstleistung/stadtfest' },
                    { name: 'Geschwindigkeitsmessanlage', url: '/user/dienstleistung/geschwindigkeitsmessanlage' },
                    { name: 'Immobilienmanagement', url: '/user/dienstleistung/immobilienmanagement' },
                ],
            },
            { name: 'Fonds Energieeffizienz Kommunen (FEK)-Anträge', url: '/user/fek', className: 'lightYellow' },
            {
                name: 'Lieferstellen Zählerstand',
                url: '/user/lieferstellenzaehlerstand',
                className: 'orange',
                unterseiten: [
                    { name: 'Zählerstände Strom', url: '/user/lieferstellenzaehlerstand/zaehlerstaendestrom' },
                    { name: 'Zählerstände Gas', url: '/user/lieferstellenzaehlerstand/zaehlerstaendegas' },
                ],
            },
            {
                name: 'Aktionärsmanagement',
                url: '/user/aktionaersmanagement',
                className: 'blue',
            },
            { name: 'Energiewirtschaftliche Netzdaten', url: '/user/energienetzdaten', className: 'orangeRed' },
            { name: 'Wertschöpfung', url: '/user/wertschoepfung', className: 'orangeRed' },
            { name: 'Meine Daten', url: '/user/meinedaten', className: 'darkTurquoise' },
            { name: 'Gewerbesteuer', url: '/user/gewerbesteuer', className: 'lightYellow' },
            { name: 'Umsatzsteuerdaten', url: '/user/umsatzsteuerdaten', className: 'lightPurple' },
            { name: 'Umsatzsteuer', url: '/user/umsatzsteuer', className: 'lightPurple' },
            { name: 'Meine Ansprechpartner', url: '/user/meineansprechpartner', className: 'lightBlue' },
            { name: 'Häufig gestellte Fragen', url: '/user/faq', className: 'darkBlue' },
            { name: 'Onlineservices und Dienstleistungen', url: '/user/onlineservices', className: 'darkTurquoise' },
        ];

        const accessableSites = [];
        // Für jede theoretisch aufrufbare Seite die Rechte prüfen
        for (const site of possibleSites) {
            const entryRoute = site.url.replace('/user/', 'user/');
            const currModule = moduleList.modules.filter((module) => module.id === entryRoute)[0];
            // Wenn Startseite, oder Rechte vorhanden zur Navigation hinzufügen
            if (site.url === '/meinekommune' || (currModule && currModule.enabled)) {
                accessableSites.push(site);
            }
        }

        return accessableSites;
    },

    // Offline-Navigation abfragen
    getOfflineNavigation() {
        return [
            { name: 'Meine Kommune', url: '/meinekommune', className: 'blue' },
            { name: 'Zugangsdaten vergessen', url: '/zugangsdatenvergessen', className: 'green' },
        ];
    },

    // Navigation für Adminbereich erstellen
    buildAdminNavigation(userGroups, moduleList) {
        let possibleNavButtons = [];

        if (userGroups.indexOf('KomPo-Administrator') > -1) {
            const adminButtons = Navigation.getAdminNavigation();
            possibleNavButtons.push(...adminButtons);
        }

        if (userGroups.indexOf('Lieferstellenredakteur') > -1) {
            const liefButtons = Navigation.getEditorNavigation();
            possibleNavButtons.push(...liefButtons);
        }

        if (userGroups.indexOf('KomPo-Superadministrator') > -1) {
            const superAdminButtons = Navigation.getSuperAdminNavigation();
            possibleNavButtons.push(...superAdminButtons);
        }

        let accessableSites = [];
        // Für jede mögliche Seite Rechteprüfung
        for (const button of possibleNavButtons) {
            const url = button.url.replace('/admin/', 'admin/');

            const currModule = moduleList.modules.filter((module) => module.id === url)[0];
            if (currModule) {
                if (currModule.enabled) {
                    accessableSites.push(button);
                }
            } else {
                this.toastr.error('Fehler beim Laden der Modulliste.');
            }
        }

        return accessableSites;
    },

    // Lieferstellenredakteur-Navigation abfragen
    getEditorNavigation() {
        return [ll, lb];
    },

    // Superadministrator-Navigation abfragen
    getSuperAdminNavigation() {
        return [
            {
                name: 'Nutzerverwaltung',
                id: 'usr',
                className: 'red',
                isLocked: false,
                notification: false,
                url: '/admin/nutzerverwaltung',
                openBlank: true,
                useLinebreaks: false,
            },
        ];
    },

    // Admin-Navigation abfragen
    getAdminNavigation() {
        return [
            {
                name: 'Konzessionsabgabe',
                id: 'kon',
                className: 'blue',
                isLocked: false,
                notification: false,
                url: '/admin/konzessionsabgabe',
                useLinebreaks: false,
            },
            {
                name: 'Kommunalrabatt',
                id: 'kom',
                className: 'blue',
                isLocked: false,
                notification: false,
                url: '/admin/kommunalrabatt',
                useLinebreaks: false,
            },
            {
                name: 'Konzessionsvertrag',
                id: 'concessionContract',
                className: 'blue',
                isLocked: false,
                notification: false,
                url: '/admin/konzessionsvertrag',
                useLinebreaks: false,
            },
            {
                name: 'Lieferstellen Bearbeitung',
                id: 'lif',
                className: 'blue',
                isLocked: false,
                notification: false,
                url: '/admin/lieferstellenbearbeitung',
                useLinebreaks: false,
            },
            // {
            //     name: 'Fonds Energieeffizienz Kommunen (FEK)-Anträge',
            //     id: 'fek',
            //     className: 'blue',
            //     isLocked: false,
            //     notification: false,
            //     url: '/admin/fek',
            //     useLinebreaks: false,
            // },
            {
                name: 'Lieferstellen Zählerstand',
                id: 'liz',
                className: 'blue',
                isLocked: false,
                notification: false,
                url: '/admin/lieferstellenzaehlerstand',
                useLinebreaks: false,
            },
            {
                name: 'Aktionärsmanagement',
                id: 'shareholderManagement',
                className: 'blue',
                isLocked: false,
                notification: false,
                url: '/admin/aktionaersmanagement',
                useLinebreaks: false,
            },
            {
                name: 'Gebietsbeirat',
                id: 'communityEvents',
                className: 'blue',
                isLocked: false,
                notification: false,
                url: '/admin/kommunalveranstaltungen',
                useLinebreaks: false,
            },
            // {
            //     name: 'Dienstleistungen & Services',
            //     id: 'duw',
            //     className: 'blue',
            //     isLocked: false,
            //     notification: false,
            //     url: '/admin/dienstleistung',
            //     useLinebreaks: false,
            // },
            // {
            //     name: 'e-Mobility erFAHRen',
            //     id: 'emo',
            //     className: 'blue',
            //     isLocked: false,
            //     notification: false,
            //     url: '/admin/emobility',
            //     useLinebreaks: false,
            // },
            // {
            //     name: 'Präsenz auf Stadtfesten',
            //     id: 'cty',
            //     className: 'blue',
            //     isLocked: false,
            //     notification: false,
            //     url: '/admin/stadtfest',
            //     useLinebreaks: false,
            // },
            // {
            //     name: 'Geschwindigkeitsmessanlage',
            //     id: 'spd',
            //     className: 'blue',
            //     isLocked: false,
            //     notification: false,
            //     url: '/admin/geschwindigkeitsmessanlage',
            //     useLinebreaks: false,
            // },
            // {
            //     name: 'Immobilienmanagement',
            //     id: 'immo',
            //     className: 'blue',
            //     isLocked: false,
            //     notification: false,
            //     url: '/admin/immobilienmanagement',
            //     useLinebreaks: false,
            // },
            // {
            //     name: 'Wertschöpfung',
            //     id: 'wer',
            //     className: 'blue',
            //     isLocked: false,
            //     notification: false,
            //     url: '/admin/wertschoepfung',
            //     useLinebreaks: false,
            // },
            {
                name: 'Häufig gestellte Fragen',
                id: 'faq',
                className: 'blue',
                isLocked: false,
                notification: false,
                url: '/admin/faq',
                useLinebreaks: false,
            },
            {
                name: 'Mein Ansprechpartner',
                id: 'con',
                className: 'blue',
                isLocked: false,
                notification: false,
                url: '/admin/meineansprechpartner',
                useLinebreaks: false,
            },
            {
                name: 'Gewerbesteuer',
                id: 'businessTax',
                className: 'blue',
                isLocked: false,
                notification: false,
                url: '/admin/gewerbesteuer',
                useLinebreaks: false,
            },
            {
                name: 'Umsatzsteuerdaten',
                id: 'con',
                className: 'blue',
                isLocked: false,
                notification: false,
                url: '/admin/umsatzsteuerdaten',
                useLinebreaks: false,
            },
            {
                name: 'Umsatzsteuer',
                id: 'ustDataCommune',
                className: 'blue',
                isLocked: false,
                notification: false,
                url: '/admin/umsatzsteuer',
                useLinebreaks: false,
            },
            {
                name: 'Startseite',
                id: 'mai',
                className: 'blue',
                isLocked: false,
                notification: false,
                url: '/admin/meinekommune',
                useLinebreaks: false,
            },
            {
                name: 'Onlineservices und Dienstleistungen',
                id: 'online',
                className: 'blue',
                isLocked: false,
                notification: false,
                url: '/admin/onlineservices',
                useLinebreaks: false,
            },
            // {
            //     name: 'Mailtexte',
            //     id: 'msg',
            //     className: 'green',
            //     isLocked: false,
            //     notification: false,
            //     url: '/admin/mailvorlagen',
            //     useLinebreaks: false,
            // },
            // {
            //     name: 'Zentraler Mailversand',
            //     id: 'zmv',
            //     className: 'green',
            //     isLocked: false,
            //     notification: false,
            //     url: '/admin/mailversand',
            //     useLinebreaks: false,
            // },
            {
                name: 'Bilder/Dokumente/Texte',
                id: 'med',
                className: 'orange',
                isLocked: false,
                notification: false,
                url: '/admin/assets',
                useLinebreaks: false,
            },
            // {
            //     name: 'Auswertung Login-Informationen',
            //     id: 'log',
            //     className: 'red2',
            //     isLocked: false,
            //     notification: false,
            //     url: '/admin/auswertunglogininformationen',
            //     useLinebreaks: false,
            // },
            // {
            //     name: 'Allgemeine Regelungen/DS Texte',
            //     id: 'adt',
            //     className: 'darkBlue',
            //     isLocked: false,
            //     notification: false,
            //     url: '/admin/bestimmungen',
            //     useLinebreaks: false,
            //     unterseiten: [
            //         { name: 'Kommunal-Portal', url: '/admin/bestimmungen/kompo', queryParam: 'KomPo', useLinebreaks: false },
            //         { name: 'Stichtagsablesung', url: '/admin/bestimmungen/stichtag', queryParam: 'Stichtag', useLinebreaks: false },
            //         { name: 'App', url: '/admin/bestimmungen/app', queryParam: 'App', useLinebreaks: false },
            //     ],
            // },
        ];
    },

    // URL-zu-Seitenname Mapping
    getMapUrlModel() {
        return {
            'meinekommune': 'myCommune',
            'zugangsdatenvergessen': 'forgotAccessData',
            'user/konzessionsabgabe': 'concessionFee',
            'user/konzessionsvertrag': 'concessionContract',
            'user/kommunalrabatt': 'communeDiscount',
            'user/fek': 'fek',
            'user/dienstleistung': 'services',
            'user/dienstleistung/immobilienmanagement': 'propertyManagement',
            'user/dienstleistung/emobility': 'emobility',
            'user/dienstleistung/stadtfest': 'cityFestivals',
            'user/dienstleistung/geschwindigkeitsmessanlage': 'speedMeasuring',
            'user/wertschoepfung': 'addedValue',
            'user/lieferstellenbearbeitung': 'deliveryPoints',
            'user/lieferstellenbearbeitung/uebersichtstrom': 'overviewElectricity',
            'user/lieferstellenbearbeitung/uebersichtgas': 'overviewGas',
            'user/lieferstellenzaehlerstand': 'countingValueAcquisition',
            'user/lieferstellenzaehlerstand/zaehlerstaendestrom': 'countingValueElectricity',
            'user/lieferstellenzaehlerstand/zaehlerstaendegas': 'countingValueGas',
            'user/faq': 'faq',
            'user/meineansprechpartner': 'contact',
            'user/gewerbesteuer': 'businessTax',
            'user/umsatzsteuerdaten': 'ustData',
            'user/umsatzsteuer': 'ustDataCommune',
            'user/energienetzdaten': 'energy-network-data',
            'user/kommunalveranstaltungen': 'community-events',
            'user/onlineservices': 'onlineservices',
            'user/meinedaten': 'myData',
            'user/aktionaersmanagement': 'shareholderManagement',
            'admin/cockpit': 'adminMainPage',
            'admin/lieferstellenlisten': 'deliveryPointLists',
        };
    },
};
