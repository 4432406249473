<div id="editor-container" [ngClass]="{ envEditorContainerDefault: size === 'default', envEditorContainerBig: size === 'big' }">
	<quill-editor [(ngModel)]="body" class="quillEditor" (ngModelChange)="emitModelChange(body, 'body')">
		<div id="toolbar-container" quill-editor-toolbar>
			<span class="ql-formats">
				<span class="ql-formats">
					<button class="ql-bold"></button>
					<button class="ql-italic"></button>
					<button class="ql-underline"></button>
					<button class="ql-strike"></button>
				</span>
				<select class="ql-align" [title]="'Aligment'">
					<option selected></option>
					<option value="center"></option>
					<option value="right"></option>
					<option value="justify"></option>
				</select>
				<span class="ql-formats">
					<button class="ql-list" value="ordered"></button>
					<button class="ql-list" value="bullet"></button>
					<button class="ql-indent" value="-1"></button>
					<button class="ql-indent" value="+1"></button>
				</span>
				<select class="ql-size"></select>
				<select class="ql-font">
					<option value="arial" selected>Arial</option>
					<option value="sans-serif">Sans Serif</option>
					<option value="calibri">Calibri</option>
				</select>
			</span>
		</div>
	</quill-editor>
</div>