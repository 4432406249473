import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { ModularizationService } from '@services/modularization.service';
import { envModule } from '@classes/envModuleList';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(public router: Router, public modService: ModularizationService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const currModule: envModule = this.modService.getEntryFromList(state.url.replace('/user/', 'user/').replace('/admin/', 'admin/'));
        if (!currModule || !currModule.enabled) {
            this.router.navigateByUrl('/meinekommune');

            return false;
        }

        return true;
    }
}
