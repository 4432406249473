<div class="envDialogWrapper">
  <div class="dialogContentWrapper">
    <div class="envDialogHeadline">{{ headline }}</div>
    <p class="dialogText">{{ bodyText }}</p>
    @if (additionalFunction === 'donwnloadStaticKoRabDocument') {
      <span
        class="icon-file-text2 envDocumentIcon"
      (click)="downloadDoc('KORAB_INFO')"></span>
    }
    <!-- <div class="envFormularRowWrapper row" *ngIf="additionalFunction === 'donwnloadStaticKoRabDocument'">
    <div class="envFormularLablewrapper col">
      <span class="icon-file-text2 envDocumentIcon envFloatRight" (click)="downloadDoc('currentAgb')"></span>
    </div>
    <div class="dialogText col">
      Dokumentenname
    </div>
  </div> -->
  <div class="buttonWrapper">
    <button class="envStandardButton envCloseButton" (click)="closeDialog()">{{ buttonText }}</button>
  </div>
</div>
</div>
