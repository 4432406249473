<div class="envDialogWrapper">
    <div class="dialogContentWrapper">
        <div class="envDialogHeadline row">Anfrage bearbeiten</div>
        <div class="row envFormularRowWrapper">
            <div class="col-2 envFormularLable">Liste</div>
            <div class="col">
                <app-dropdown
                    type="multiselect"
                    anzeigevalue="name"
                    [defaultmultipleselected]="getSelectedIndices()"
                    placeholder="Bitte wählen"
                    [data]="workingSubjectArea.inquiryTypes"
                    (selectedItemchanged)="setSelectetItems($event)"></app-dropdown>
            </div>
        </div>
        <div class="row envFormularRowWrapper">
            <div class="col envFormularLable">Kontakt E-Mail-Adresse</div>
            <div class="envInputFieldWrapper">
                <input
                    class="envInputField col"
                    placeholder="Kontakt E-Mail-Adresse"
                    [(ngModel)]="workingSubjectArea.contactMail"
                    (focusout)="checkMail(workingSubjectArea.contactMail)"
                    type="text" />
                <div class="envInvalidInput envInvalidText" id="envMailError">{{ this.isMailValidErrorText }}</div>
            </div>
        </div>
        <div class="envButtonWrapper row">
            <div class="col">
                <button class="envStandardButton envCloseButton" (click)="closeDialog(undefined)">Abbrechen</button>
                <button
                    class="envStandardButton envSaveButton"
                    [ngClass]="isModalValid() ? '' : 'envDisabledButton'"
                    [disabled]="!isModalValid()"
                    (click)="saveFormular()">
                    Übernehmen
                </button>
            </div>
        </div>
    </div>
</div>
