import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-modal-prompt',
    templateUrl: './modal-prompt.component.html',
    styleUrls: ['./modal-prompt.component.sass'],
})
export class ModalPromptComponent implements OnInit {
    title: string = '';
    bodyText: string = '';

    buttonText1: string = '';
    buttonText2: string = '';

    buttonClass1: string = '';
    buttonClass2: string = '';

    isDecision: boolean = false;

    constructor(public dialogRef: MatDialogRef<ModalPromptComponent>, @Inject(MAT_DIALOG_DATA) public dialogData: any) {}

    ngOnInit(): void {
        this.title = this.dialogData.title;
        this.bodyText = this.dialogData.bodyText;
        this.buttonText1 = this.dialogData.buttonText1;
        this.buttonClass1 = this.dialogData.buttonClass1;
        this.buttonText2 = this.dialogData.buttonText2;
        this.buttonClass2 = this.dialogData.buttonClass2;
        this.isDecision = this.dialogData.isDecision;
    }

    // Schließen des Dialogs
    closeDialog(decision) {
        this.dialogRef.close(decision);
    }
}
