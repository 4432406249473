<div class="orderingArea">
    @for (area of subjectAreaData; track area; let areaPosition = $index) {
    <div class="component" id="accordion{{ area.position }}">
        <div class="form-group row element row" id="themenüberschrift">
            <label class="col-form-label col-sm-3">Themengruppenüberschrift:</label>
            <div class="col-sm-9">
                <input
                    type="text"
                    class="form-control"
                    placeholder="Themenüberschrift"
                    [(ngModel)]="area.headline"
                    name="contentHeadline"
                    [ngClass]="{ 'is-invalid': isInvalid('contentHeadline') }" />
            </div>
        </div>
        <div class="envContactImgWrapper envPictureEditorWapper form-group">
            <button
                type="button"
                class="btn btn-secondary button-edit envPictureEditButton"
                (click)="editFiles(area.subjectareaPicture, 'SUBJECTAREAPICTURE', areaPosition)">
                <i class="icon-pencil"></i>
            </button>
            <img class="envPictureEditor" src="{{ restURL + assetPathBackend + area.subjectareaPicture.url }}" />
        </div>
        <div class="form-group row element row envQuillEditorWrapper">
            <div class="envClearEditorButtonWrapper">
                <button class="envClearEditorButton" (click)="clearEditor(0)" title="Vollständiges leeren des Textfeldes">
                    Editor leeren
                </button>
            </div>
            <app-quill-wrapper
                [(body)]="area.description"
                (modelChange)="getModelChangeItem($event, area)"
                [ngClass]="{ 'is-invalid': isInvalid('content') }"></app-quill-wrapper>
        </div>
        @if (category !== 'subjectArea') {
        <div class="row">
            <app-accordion-edit-manager
                [(pageData)]="pageData"
                [(accordionData)]="area.accordions"
                [newTitle]="'neuen Klappbox anlegen'"
                type="subjectArea"></app-accordion-edit-manager>
        </div>
        } @if (usesInquiryTypes) {
        <div class="row">
            <div class="col"></div>
            <div class="col">
                <button class="envGreenButton" (click)="openModal(area)">Jetzt unverbindlich anfragen</button>
            </div>
            <div class="col"></div>
        </div>
        } @if (category !== 'subjectArea') {
        <div class="btn-group-vertical">
            @if (!justEdit) {
            <button type="button" class="btn btn-danger button-delete" (click)="deleteAccordion(area)">
                <i class="icon-minus-circle"></i>
            </button>
            }
            <button
                type="button"
                class="btn btn-secondary button-position-up"
                [disabled]="area.position === 0"
                (click)="voteAccordionUp(area)">
                <i class="icon-angle-up"></i>
            </button>
            <button
                type="button"
                class="btn btn-secondary button-position-down"
                [disabled]="area.position === subjectAreaData.length - 1"
                (click)="voteAccordionDown(area)">
                <i class="icon-angle-down"></i>
            </button>
        </div>
        }
    </div>
    } @if (!justEdit) {
    <div class="component">
        <div class="headline btn">{{ newTitle }}</div>
        <div class="button">
            <button class="btn btn-success button-add" (click)="addSubjectArea()"><i class="icon-plus-circle"></i></button>
        </div>
    </div>
    }
</div>
