import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { QuillModule } from 'ngx-quill';
import { NgxChartsModule } from '@swimlane/ngx-charts';

// Komponenten
import { ContentHeaderComponent } from './components/content-header/content-header.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { DownloadComponent } from './components/download/download.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { AccordionEditComponent } from './components/accordion-edit/accordion-edit.component';
import { AccordionEditManagerComponent } from './components/accordion-edit-manager/accordion-edit-manager.component';
import { DownloadEditManagerComponent } from './components/download-edit-manager/download-edit-manager.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { AdmStatusBarComponent } from './components/adm-status-bar/adm-status-bar.component';
import { AdmContentHeaderComponent } from './components/adm-content-header/adm-content-header.component';
import { AdmSubjectAreaEditComponent } from './components/adm-subject-area-edit/adm-subject-area-edit.component';
import { ContactComponent } from './components/contact/contact.component';
import { QuillWrapperComponent } from '@shared/components/quill-wrapper/quill-wrapper.component';
import { TableContentForMobileViewComponent } from './components/table-content-for-mobile-view/table-content-for-mobile-view.component';
import { MatCardModule } from '@angular/material/card';
import { CommuneTextsComponent } from './components/commune-texts/commune-texts.component';
import { NgxChartVerticalStackedComponent } from './components/ngxChartVerticalStacked/ngx-chart-vertical-stacked.component';
import { NgxChartPie } from './components/ngxChartPie/ngx-chart-pie.component';

@NgModule({
    declarations: [
        ContentHeaderComponent,
        AccordionComponent,
        TimelineComponent,
        DownloadComponent,
        DatePickerComponent,
        AccordionEditComponent,
        AccordionEditManagerComponent,
        AccordionEditManagerComponent,
        DownloadEditManagerComponent,
        LoadingIndicatorComponent,
        DropdownComponent,
        AdmStatusBarComponent,
        AdmContentHeaderComponent,
        AdmSubjectAreaEditComponent,
        ContactComponent,
        QuillWrapperComponent,
        TableContentForMobileViewComponent,
        CommuneTextsComponent,
        NgxChartVerticalStackedComponent,
        NgxChartPie
    ],
    imports: [
        CommonModule,
        QuillModule,
        NgbModule,
        FormsModule,
        MatProgressSpinnerModule,
        MomentModule.forRoot({
            relativeTimeThresholdOptions: {
                m: 59,
            },
        }),
        MatCardModule,
        NgxChartsModule
    ],
    exports: [
        ContentHeaderComponent,
        AccordionComponent,
        TimelineComponent,
        DownloadComponent,
        DatePickerComponent,
        AccordionEditComponent,
        AccordionEditManagerComponent,
        AccordionEditManagerComponent,
        DownloadEditManagerComponent,
        LoadingIndicatorComponent,
        DropdownComponent,
        AdmStatusBarComponent,
        AdmContentHeaderComponent,
        AdmSubjectAreaEditComponent,
        ContactComponent,
        QuillWrapperComponent,
        TableContentForMobileViewComponent,
        CommuneTextsComponent,
        NgxChartVerticalStackedComponent,
        NgxChartPie
    ],
})
export class SharedModule {}
