import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { catchError, map, tap, takeUntil } from 'rxjs/operators';

import { envValidAddress } from '@classes/envValidAddress';
import { envValidAddressResponse } from '@classes/envValidAddressResponse';
import { envUserData } from '@classes/envUserData';
import { envDownloadedDoc } from '@classes/envDownloadedDoc';
import { environment } from '@environments/environment';
import { envExcelData } from '@classes/envExcelData';
import { envDeliveryPointItem } from '@classes/envDeliveryPointItem';
import { envDeliveryPointChanges } from '@classes/envDeliveryPointChanges';
import { envAdmDeliveryPointItem } from '@classes/envAdmDeliveryPointItem';
import { envModuleList } from '@classes/envModuleList';

import { envPageData } from '@classes/envPageData';
import { Router } from '@angular/router';
import { envCommunity } from '../classes/envCommunity';
import { envTimelineSegment } from '../classes/envTimelineSegment';
import { CommunicationService } from './communication.service';
import { envCommuneTextType } from '../classes/envCommuneTextType';
import { envCommuneText } from '../classes/envCommuneText';

@Injectable({
    providedIn: 'root',
})
// Service für die Rest Anfragen an das Backend und ablegen der Daten
export class RestService {
    constructor(
        private readonly http: HttpClient,
        private readonly communicationService: CommunicationService,
        private readonly router: Router
    ) {}

    // Variablen
    private readonly myCommunitiesData = new BehaviorSubject<envCommunity[]>(undefined);
    $myCommunitiesData = this.myCommunitiesData.asObservable();

    private readonly activeCommunityData = new BehaviorSubject<envCommunity>(undefined);
    $activeCommunityData = this.activeCommunityData.asObservable();

    private readonly userRoles = new BehaviorSubject<string[]>(undefined);
    $userRoles = this.userRoles.asObservable();

    private readonly moduleList = new BehaviorSubject<envModuleList>(undefined);
    $moduleList = this.moduleList.asObservable();

    private readonly korabDocumentsData = new BehaviorSubject<envTimelineSegment[]>(undefined);
    $korabDocumentsData = this.korabDocumentsData.asObservable();

    private readonly kaDocumentsData = new BehaviorSubject<envTimelineSegment[]>(undefined);
    $kaDocumentsData = this.kaDocumentsData.asObservable();

    private readonly documentsData = new BehaviorSubject<any>(undefined);
    $documentsData = this.documentsData.asObservable();

    private readonly userData = new BehaviorSubject<envUserData>(undefined);
    $userData = this.userData.asObservable();

    private readonly adminUserData = new BehaviorSubject<envUserData>(undefined);
    $adminUserData = this.adminUserData.asObservable();

    private readonly downloadedDoc = new BehaviorSubject<envDownloadedDoc>(undefined);
    $downloadedDoc = this.downloadedDoc.asObservable();

    private readonly downloadedZip = new BehaviorSubject<any>(undefined);
    $downloadedZip = this.downloadedZip.asObservable();

    private readonly downloadedStaticDoc = new BehaviorSubject<envDownloadedDoc>(undefined);
    $downloadedStaticDoc = this.downloadedStaticDoc.asObservable();

    private readonly downloadedHardStaticDoc = new BehaviorSubject<envDownloadedDoc>(undefined);
    $downloadedHardStaticDoc = this.downloadedHardStaticDoc.asObservable();

    private readonly checkAddressResponse = new BehaviorSubject<envValidAddressResponse>(undefined);
    $checkAddressResponse = this.checkAddressResponse.asObservable();

    private readonly deliveryPointElectroData = new BehaviorSubject<envDeliveryPointItem[]>(undefined);
    $deliverPointElectroData = this.deliveryPointElectroData.asObservable();

    private readonly deliveryPointGasData = new BehaviorSubject<envDeliveryPointItem[]>(undefined);
    $deliverPointGasData = this.deliveryPointGasData.asObservable();

    private readonly excelData = new BehaviorSubject<any>(undefined);
    $excelData = this.excelData.asObservable();

    private readonly deliveryPointsChangedData = new BehaviorSubject<envDeliveryPointChanges[]>(undefined);
    $deliveryPointsChangedData = this.deliveryPointsChangedData.asObservable();

    private readonly resetPwResponse = new BehaviorSubject<any>(undefined);
    $resetPwResponse = this.resetPwResponse.asObservable();

    private readonly resetUsernameForUserResponse = new BehaviorSubject<any>(undefined);
    $resetUsernameForUserResponse = this.resetUsernameForUserResponse.asObservable();

    private readonly dpCommuneViewItems = new BehaviorSubject<envAdmDeliveryPointItem[]>(undefined);
    $dpCommuneViewItems = this.dpCommuneViewItems.asObservable();

    private readonly checkClusterBlockResponse = new BehaviorSubject<any>(undefined);
    $checkClusterBlockResponse = this.checkClusterBlockResponse.asObservable();

    private readonly updateClusterBlockResponse = new BehaviorSubject<any>(undefined);
    $updateClusterBlockResponse = this.updateClusterBlockResponse.asObservable();

    private readonly changePasswordFlag = new BehaviorSubject<any>(undefined);
    $changePasswordFlag = this.changePasswordFlag.asObservable();

    private readonly customerListData = new BehaviorSubject<any>(undefined);
    $customerListData = this.customerListData.asObservable();

    private readonly deliveryPointsChangeResponse = new BehaviorSubject<any[]>(undefined);
    $deliveryPointsChangeResponse = this.deliveryPointsChangeResponse.asObservable();

    private readonly sendUploadResponse = new BehaviorSubject<any[]>(undefined);
    $sendUploadResponse = this.sendUploadResponse.asObservable();

    private readonly sendDeleteResponse = new BehaviorSubject<any[]>(undefined);
    $sendDeleteResponse = this.sendDeleteResponse.asObservable();

    private readonly sendPageDataChangeResponse = new BehaviorSubject<any>(undefined);
    $sendPageDataChangeResponse = this.sendPageDataChangeResponse.asObservable();

    private readonly loginForAllUsers = new BehaviorSubject<any>(undefined);
    $loginForAllUsers = this.loginForAllUsers.asObservable();

    private readonly sendFekData = new BehaviorSubject<any>(undefined);
    $sendFekData = this.sendFekData.asObservable();

    private readonly sendEditFekData = new BehaviorSubject<any>(undefined);
    $sendEditFekData = this.sendEditFekData.asObservable();

    private readonly statisticData = new BehaviorSubject<any>(undefined);
    $statisticData = this.statisticData.asObservable();

    private readonly confirmFekData = new BehaviorSubject<any>(undefined);
    $confirmFekData = this.confirmFekData.asObservable();

    private readonly fekList = new BehaviorSubject<any>(undefined);
    $fekList = this.fekList.asObservable();

    private readonly revokeFekResponse = new BehaviorSubject<any>(undefined);
    $revokeFekResponse = this.revokeFekResponse.asObservable();

    private readonly getGemeinden = new BehaviorSubject<any>(undefined);
    $getGemeinden = this.getGemeinden.asObservable();

    private readonly getGemeindeInfos = new BehaviorSubject<any>(undefined);
    $getGemeindeInfos = this.getGemeindeInfos.asObservable();

    private readonly registrationResponse = new BehaviorSubject<any>(undefined);
    $registrationResponse = this.registrationResponse.asObservable();

    private readonly checkUsername = new BehaviorSubject<any>(undefined);
    $checkUsername = this.checkUsername.asObservable();

    private readonly checkMail = new BehaviorSubject<any>(undefined);
    $checkMail = this.checkMail.asObservable();

    private readonly verfiyMailResponse = new BehaviorSubject<any>(undefined);
    $verfiyMailResponse = this.verfiyMailResponse.asObservable();

    private readonly getAnmeldeDatenResponse = new BehaviorSubject<any>(undefined);
    $getAnmeldeDatenResponse = this.getAnmeldeDatenResponse.asObservable();

    private readonly sendRegistrationConfirmationResponse = new BehaviorSubject<any>(undefined);
    $sendRegistrationConfirmationResponse = this.sendRegistrationConfirmationResponse.asObservable();

    private readonly newAgbDsAcceptResponse = new BehaviorSubject<any>(undefined);
    $newAgbDsAcceptResponse = this.newAgbDsAcceptResponse.asObservable();

    private readonly newAgbDeclineResponse = new BehaviorSubject<any>(undefined);
    $newAgbDeclineResponse = this.newAgbDeclineResponse.asObservable();

    private readonly downloadedAgbdsDocAdmin = new BehaviorSubject<any>(undefined);
    $downloadedAgbdsDocAdmin = this.downloadedAgbdsDocAdmin.asObservable();

    private readonly downloadedAddedValuesDocumentPerYear = new BehaviorSubject<any>(undefined);
    $downloadedAddedValuesDocumentPerYear = this.downloadedAddedValuesDocumentPerYear.asObservable();

    private readonly downloadedAgbdsDocUser = new BehaviorSubject<any>(undefined);
    $downloadedAgbdsDocUser = this.downloadedAgbdsDocUser.asObservable();

    private readonly hasNewAgbDsResponse = new BehaviorSubject<any>(undefined);
    $hasNewAgbDsResponse = this.hasNewAgbDsResponse.asObservable();

    // Admin Bereich
    private readonly sendIsAssetErasableResponse = new BehaviorSubject<any>(undefined);
    $sendIsAssetErasableResponse = this.sendIsAssetErasableResponse.asObservable();

    private readonly agbDsData = new BehaviorSubject<any>(undefined);
    $agbDsData = this.agbDsData.asObservable();

    private readonly newAgbDsResponse = new BehaviorSubject<any>(undefined);
    $newAgbDsResponse = this.newAgbDsResponse.asObservable();

    private readonly centralMailListResponse = new BehaviorSubject<any>(undefined);
    $centralMailListResponse = this.centralMailListResponse.asObservable();

    private readonly mailinglistPlaceholdersResponse = new BehaviorSubject<any>(undefined);
    $mailinglistPlaceholdersResponse = this.mailinglistPlaceholdersResponse.asObservable();

    private readonly saveCentralMailResponse = new BehaviorSubject<any>(undefined);
    $saveCentralMailResponse = this.saveCentralMailResponse.asObservable();

    private readonly addedValueResponse = new BehaviorSubject<any>(undefined);
    $addedValueResponse = this.addedValueResponse.asObservable();

    private readonly sendInquiryResponse = new BehaviorSubject<any>(undefined);
    $sendInquiryResponse = this.sendInquiryResponse.asObservable();

    private readonly inquiryTypesResponse = new BehaviorSubject<any>(undefined);
    $inquiryTypesResponse = this.inquiryTypesResponse.asObservable();

    private readonly sendInquiryTypesResponse = new BehaviorSubject<any>(undefined);
    $sendInquiryTypesResponse = this.sendInquiryTypesResponse.asObservable();

    private readonly sendDeleteUserResponse = new BehaviorSubject<any>(undefined);
    $sendDeleteUserResponse = this.sendDeleteUserResponse.asObservable();

    private readonly defaultAssetResponse = new BehaviorSubject<any>(undefined);
    $defaultAssetResponse = this.defaultAssetResponse.asObservable();

    private readonly getDeclinetextResponse = new BehaviorSubject<any>(undefined);
    $getDeclinetextResponse = this.getDeclinetextResponse.asObservable();

    private readonly editDeclinetextResponse = new BehaviorSubject<any>(undefined);
    $editDeclinetextResponse = this.editDeclinetextResponse.asObservable();

    private readonly deleteDeclinetextResponse = new BehaviorSubject<any>(undefined);
    $deleteDeclinetextResponse = this.deleteDeclinetextResponse.asObservable();

    private readonly getTableorderResponse = new BehaviorSubject<any>(undefined);
    $getTableorderResponse = this.getTableorderResponse.asObservable();

    private readonly saveTableorderResponse = new BehaviorSubject<any>(undefined);
    $saveTableorderResponse = this.saveTableorderResponse.asObservable();

    private readonly deleteTableorderResponse = new BehaviorSubject<any>(undefined);
    $deleteTableorderResponse = this.deleteTableorderResponse.asObservable();

    private readonly getFormularDataResponse = new BehaviorSubject<any>(undefined);
    $getFormularDataResponse = this.getFormularDataResponse.asObservable();

    private readonly savedFormularResponse = new BehaviorSubject<any>(undefined);
    $savedFormularResponse = this.savedFormularResponse.asObservable();

    private readonly saveRegistrationForServiceResponse = new BehaviorSubject<any>(undefined);
    $saveRegistrationForServiceResponse = this.saveRegistrationForServiceResponse.asObservable();

    private readonly countingValuesResponse = new BehaviorSubject<any>(undefined);
    $countingValuesResponse = this.countingValuesResponse.asObservable();

    private readonly excelDataCountingValue = new BehaviorSubject<any>(undefined);
    $excelDataCountingValue = this.excelDataCountingValue.asObservable();

    private readonly saveNewCounterReadingsResponse = new BehaviorSubject<any>(undefined);
    $saveNewCounterReadingsResponse = this.saveNewCounterReadingsResponse.asObservable();

    private readonly getMeasureStatusResponse = new BehaviorSubject<any>(undefined);
    $getMeasureStatusResponse = this.getMeasureStatusResponse.asObservable();

    private readonly countingValueAGBTextResponse = new BehaviorSubject<any>(undefined);
    $countingValueAGBTextResponse = this.countingValueAGBTextResponse.asObservable();

    private readonly acceptedOrDeclinedDPResponse = new BehaviorSubject<any>(undefined);
    $acceptedOrDeclinedDPResponse = this.acceptedOrDeclinedDPResponse.asObservable();

    private readonly applicationOptions = new BehaviorSubject<any>(undefined);
    $applicationOptions = this.applicationOptions.asObservable();

    private readonly ustDataResponse = new BehaviorSubject<any>(undefined);
    $ustDataResponse = this.ustDataResponse.asObservable();

    private readonly ustDataCommuneResponse = new BehaviorSubject<any>(undefined);
    $ustDataCommuneResponse = this.ustDataCommuneResponse.asObservable();

    private readonly saveCommuneTextResponse = new BehaviorSubject<any>(undefined);
    $saveCommuneTextResponse = this.saveCommuneTextResponse.asObservable();

    private readonly getCommuneTextResponse = new BehaviorSubject<envCommuneText[]>(undefined);
    $getCommuneTextResponse = this.getCommuneTextResponse.asObservable();

    private readonly deleteCommuneTextResponse = new BehaviorSubject<any>(undefined);
    $deleteCommuneTextResponse = this.deleteCommuneTextResponse.asObservable();

    private readonly communeTextsForType = new BehaviorSubject<any>(undefined);
    $communeTextsForType = this.communeTextsForType.asObservable();

    private readonly communeTextTypes = new BehaviorSubject<envCommuneTextType[]>(undefined);
    $communeTextTypes = this.communeTextTypes.asObservable();

    private userBearerToken: string = '';

    private appId = environment.appId;

    // Subject, um laufende Requests zu canceln
    $cancelPendingRequests = new Subject<void>();

    private httpOptions = {
        headers: new HttpHeaders({
            userBearerToken: '',
        }),
    };

    private readonly restURL = environment.restBaseURL;
    private readonly restURLAdmin = environment.restAdminBaseURL;

    private extractData(res: Response) {
        const body = res;

        return body || {};
    }

    // Derzeit laufende Requests, die takeUntil beinhalten beenden
    cancelCurrentRequests() {
        this.$cancelPendingRequests.next();
    }

    // myCommunities Anfrage zu einem bestimmten username
    getMyCommunitiesData(kp_id?: string, ags?: string): void {
        let url = `${this.restURL}/communityData?appId=${this.appId}`;

        if (kp_id && ags) {
            url += `&kpId=${kp_id}&ags=${ags}&cache=${new Date().getTime()}`;
        }
        this.http
            .get<envCommunity>(url, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                if (data.status === 500) {
                    this.handleHandledError(data.text);
                } else {
                    this.activeCommunityData.next(data);
                }
            })
            .catch((_) => {
                this.handleUnhandledError('myCommunities');
            });
    }

    // Kommunenliste Anfrage zu einem bestimmten username
    getMyCommunitiesList(): void {
        let url = `${this.restURL}/communeList?appId=${this.appId}`;

        this.http
            .get<envCommunity[]>(url, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                if (data.status === 500) {
                    this.handleHandledError(data.text);
                } else {
                    this.myCommunitiesData.next(data);
                }
            })
            .catch((_) => {
                this.handleUnhandledError('communeList');
            });
    }

    // Kommunenliste Anfrage zu einem bestimmten username
    getListOfAllCommunes(): void {
        let url = `${this.restURL}/getListOfAllCommunes?appId=${this.appId}`;

        this.http
            .get<envCommunity[]>(url, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                if (data.status === 500) {
                    this.handleHandledError(data.text);
                } else {
                    this.communicationService.setListOfAllCommunes(data);
                }
            })
            .catch((_) => {
                this.handleUnhandledError('getListOfAllCommunes');
            });
    }

    // Abfrage der aufrufbaren Module
    getModuleList(): void {
        const url = `${this.restURL}/getModuleList?cache=${new Date().getTime()}&appId=${this.appId}`;
        this.http
            .get<envModuleList>(url, this.httpOptions)
            .toPromise()
            .then((data: envModuleList) => {
                this.moduleList.next(data);
            })
            .catch((_) => {
                this.handleUnhandledError('moduleList');
            });
    }

    // gibt Keycloak nutzerolle eines Nutzers wieder
    getUserRoles(): void {
        const url = `${this.restURL}/userRoles?cache=${new Date().getTime()}&appId=${this.appId}`;
        this.http
            .get<any>(url, this.httpOptions)
            .toPromise()
            .then((data: string[]) => {
                this.userRoles.next(data);
            })
            .catch((_) => {
                this.handleUnhandledError('userRoles');
            });
    }

    // Kommunalrabattdokumente zu einem Nutzer und einer Kommune abfragen
    getKorabDocuments(kpId: string) {
        const url = `${this.restURL}/myDocuments?doctype=Kommunalrabatt&kpId=${kpId}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get<envTimelineSegment[]>(url, this.httpOptions)
            .toPromise()
            .then((data: envTimelineSegment[]) => {
                this.korabDocumentsData.next(data);
            })
            .catch((_) => {
                this.handleUnhandledError('getKorabDocuments');
            });
    }

    // Konzessionsabgabendokumente zu einem Nutzer und einer Kommune abfragen
    getKaDocuments(kpId: string) {
        const url = `${this.restURL}/myDocuments?doctype=Konzessionsabgabe&kpId=${kpId}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get<envTimelineSegment[]>(url, this.httpOptions)
            .toPromise()
            .then((data: envTimelineSegment[]) => {
                this.kaDocumentsData.next(data);
            })
            .catch((_) => {
                this.handleUnhandledError('getKaDocuments');
            });
    }

    // Konzessionsabgabendokumente zu einem Nutzer und einer Kommune abfragen
    getDocumentsFromKomda(kpId: string, doctype: string) {
        const url = `${this.restURL}/myDocuments?doctype=${doctype}&kpId=${kpId}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get<envTimelineSegment[]>(url, this.httpOptions)
            .toPromise()
            .then((data: envTimelineSegment[]) => {
                this.documentsData.next(data);
            })
            .catch((_) => {
                this.handleUnhandledError('getDocumentsFromKomda');
            });
    }

    // Angeklicktes Dokument downloaden
    downloadDocument(kpId: string, docId: string, generalFile: boolean, fekId: string) {
        const url = `${
            this.restURL
        }/document?kpId=${kpId}&docId=${docId}&generalFile=${generalFile}&fekId=${fekId}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((docData: envDownloadedDoc) => {
                this.downloadedDoc.next(docData);
            });
    }

    // Angeklicktes Dokument downloaden
    downloadDocumentsAsZip(kpId: string, documents: any[]) {
        const body = documents;

        const url = `${this.restURL}/downloadZip?kpId=${kpId}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((docData: envDownloadedDoc) => {
                this.downloadedZip.next(docData);
            });
    }

    // Variable für nächsten Download resetten
    resetZip() {
        this.downloadedZip.next(undefined);
    }

    // Angeklicktes Dokument downloaden
    getAsset(assetId) {
        const url = `${this.restURL}/document?docId=${assetId}&cache=${new Date().getTime()}&appId=${this.appId}`;

        return this.http.get(url, this.httpOptions).pipe(map(this.extractData));
    }

    // Variable für nächsten Download resetten
    resetDoc() {
        this.downloadedDoc.next(undefined);
    }

    // Statisches Dokument downloaden
    downloadStaticDoc(docId: string) {
        const url = `${this.restURL}/staticDocument?docId=${docId}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url)
            .toPromise()
            .then((docData: envDownloadedDoc) => {
                this.downloadedStaticDoc.next(docData);
            });
    }

    // Variable für statisches Dokument resetten für mehrere Downloads
    resetStaticDoc() {
        this.downloadedStaticDoc.next(undefined);
    }

    // Hard Statisches Dokument downloaden
    downloadHardStaticDoc(docId: string) {
        const url = `${this.restURL}/getStaticDocument?docId=${docId}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((docData: envDownloadedDoc) => {
                this.downloadedHardStaticDoc.next(docData);
            });
    }

    // Variable für statisches Dokument resetten für mehrere Downloads
    resetHardStaticDoc() {
        this.downloadedHardStaticDoc.next(undefined);
    }

    // Seitendaten im eingeloggten Zustand laden
    getOnlineContentData(currSiteName: string, ags: string, kpId: string): any {
        const url = `${this.restURL}/onlineContent?siteName=${currSiteName}&ags=${ags}&kpId=${kpId}&cache=${new Date().getTime()}&appId=${
            this.appId
        }`;

        return this.http.get<envPageData>(url, this.httpOptions);
    }

    // Seitendaten im ausgeloggten Zustand laden
    getOfflineContentData(currSiteName: string, kpId: string): any {
        const url = `${this.restURL}/offlineContent?siteName=${currSiteName}&cache=${new Date().getTime()}&appId=${
            this.appId
        }&kpId=${kpId}`;

        return this.http.get<envPageData>(url, this.httpOptions);
    }

    // Dp Daten an Inubit schicken und erstellte Excel Downloaden
    downloadExcelFromDpData(dpData: envExcelData) {
        const url = `${this.restURL}/downloadExcel?appId=${this.appId}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'userBearerToken': this.userBearerToken,
            }),
        };

        this.http
            .post(url, dpData, httpOptions)
            .toPromise()
            .then((excelData: envDownloadedDoc) => {
                this.excelData.next(excelData);
            })
            .catch((_) => {
                // this.handleUnhandledError("excel")
            });
    }

    // Exceldaten für nächsten Download leeren
    resetExcelDoc() {
        this.excelData.next(undefined);
    }

    // Details aus KomPo Schnittstelle zu einem User laden
    getUserData(kpId: string, getLoginTime: boolean = false) {
        const url = `${this.restURL}/myData?kpId=${kpId}&getLoginTime=${getLoginTime}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: envUserData) => {
                this.userData.next(data);
            })
            .catch((_) => {
                this.handleUnhandledError('getUserData');
            });
    }

    resetUserData() {
        this.userData.next(undefined);
    }

    // Details aus KomPo Schnittstelle zu einem User laden
    getAdminUserData(userName: string, getLoginTime: boolean = false) {
        const url = `${this.restURL}/myData?userName=${userName}&getLoginTime=${getLoginTime}&cache=${new Date().getTime()}&appId=${
            this.appId
        }`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: envUserData) => {
                this.adminUserData.next(data);
            })
            .catch((_) => {
                this.handleUnhandledError('getUserData');
            });
    }

    resetAdminUserData() {
        this.adminUserData.next(undefined);
    }

    // Daten für die Elektro Lieferstellentabelle abfragen
    getDPElectroData(ags: string) {
        const url = `${this.restURL}/deliveryPoints?contenttype=strom&ags=${ags}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: envDeliveryPointItem[]) => {
                this.deliveryPointElectroData.next(data);
            })
            .catch((_) => {
                this.handleUnhandledError('dpElectro');
            });
    }

    resetDPElectroData() {
        this.deliveryPointElectroData.next(undefined);
    }

    // Daten für die Gas Lieferstellentabelle abfragen
    getDPGasData(ags: string) {
        const url = `${this.restURL}/deliveryPoints?contenttype=gas&ags=${ags}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: envDeliveryPointItem[]) => {
                this.deliveryPointGasData.next(data);
            })
            .catch((_) => {
                this.handleUnhandledError('dpGas');
            });
    }

    resetDPGasData() {
        this.deliveryPointGasData.next(undefined);
    }

    getCustomerList() {
        const url = `${this.restURL}/getCustomerList?cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data) => {
                this.customerListData.next(data);
            });
    }

    getCustomerListData() {
        return this.customerListData.getValue();
    }

    getDPChangedData(filter?) {
        const url =
            filter !== undefined
                ? `${this.restURL}/deliveryPointChanges?filter=${filter}&cache=${new Date().getTime()}`
                : `${this.restURL}/deliveryPointChanges?cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: envDeliveryPointChanges[]) => {
                this.deliveryPointsChangedData.next(data);
            })
            .catch((_) => {
                this.handleUnhandledError('dpChanges');
            });
    }

    resetDPChangedData() {
        this.deliveryPointsChangedData.next(undefined);
    }

    // Bei Zurücksetzen des Passworts Email an Backend übergeben
    resetPwForUser(username: string): void {
        const url = `${this.restURL}/resetPassword?username=${username}&appId=${this.appId}`;
        this.http
            .post(url, '', this.httpOptions)
            .toPromise()
            .then((data) => {
                this.resetPwResponse.next(data);
            })
            .catch((_) => {
                this.handleUnhandledError('restPW');
            });
    }

    resetResetPwResponse() {
        this.resetPwResponse.next(undefined);
    }

    // Bei Vergessen des Nutzernamens Email an Backend übergeben
    getUsernameForUser(email: string): void {
        const url = `${this.restURL}/forgotUsername?email=${email}&appId=${this.appId}`;

        this.http
            .post(url, '', this.httpOptions)
            .toPromise()
            .then((data) => {
                this.resetUsernameForUserResponse.next(data);
            })
            .catch((_) => {
                this.handleUnhandledError('forgetUsername');
            });
    }

    resetGetUsernameForUser() {
        this.resetUsernameForUserResponse.next(undefined);
    }

    // Lieferstellendaten an Datenbank zur Überprüfung auf Richtigkeit der Daten
    checkAddressInDataBase(address: envValidAddress) {
        const url = `${this.restURL}/checkAddress?appId=${this.appId}`;

        this.http
            .post(url, address, this.httpOptions)
            .toPromise()
            .then((data: envValidAddressResponse) => {
                this.checkAddressResponse.next(data);
            });
    }

    resetCheckAddressInDataBase() {
        this.checkAddressResponse.next(undefined);
    }

    // Lieferstellen zu einer Lieferstellenänderung abfragen
    getDPCommuneViewItems(changeId: string, type: string) {
        const url = `${this.restURL}/communeViewItems?changeId=${changeId}&itemType=${type}&cache=${new Date().getTime()}&appId=${
            this.appId
        }`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: envAdmDeliveryPointItem[]) => {
                this.resetDPCommuneViewItems();
                this.dpCommuneViewItems.next(data);
            });
    }

    // Items resetten
    resetDPCommuneViewItems() {
        this.dpCommuneViewItems.next(undefined);
    }

    sendAcceptedOrDeclinedDP(item: envAdmDeliveryPointItem) {
        const url = `${this.restURL}/updateDpStatusInDb?appId=${this.appId}`;
        this.http
            .post(url, item, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.acceptedOrDeclinedDPResponse.next(data);
            });
    }

    resetAcceptedOrDeclinedDPResponse() {
        this.acceptedOrDeclinedDPResponse.next(undefined);
    }

    // Liefsertellenänderungen ans Backend senden
    sendDeliveryPointChanges(changes: envDeliveryPointItem[], ags: string, communeName: string) {
        const url = `${this.restURL}/sendDeliveryPointChanges?ags=${ags}&communeName=${communeName}&appId=${this.appId}`;

        this.http
            .post(url, changes, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.deliveryPointsChangeResponse.next(data);
            });
    }

    resetDeliveryPointsChangeResponse() {
        this.deliveryPointsChangeResponse.next(undefined);
    }

    // prüfen ob Cluster noch von einem Admin gespert wurde
    checkClusterBlock(clusterId: string) {
        const url = `${this.restURL}/checkClusterOnBlock?clusterId=${clusterId}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data) => {
                this.checkClusterBlockResponse.next(data);
            });
    }

    resetClusterBlockResponse() {
        this.checkClusterBlockResponse.next(undefined);
    }

    // entsperren eines Clusters
    setUpdatedClusterInDb(cluster: string, action: string) {
        const url = `${this.restURL}/updateClusterInDb?clusterId=${cluster}&clusterAction=unlock&appId=${this.appId}`;

        this.http
            .post(url, '', this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.updateClusterBlockResponse.next(data);
            });
    }

    // Rückgabe leeren
    resetUpdateClusterBlockResponse() {
        this.updateClusterBlockResponse.next(undefined);
    }

    // Passwort zurücksetzen Flag für Nutzer setzen
    setPasswordFlag() {
        const url = `${this.restURL}/setPasswordFlag?appId=${this.appId}`;
        this.http
            .post(url, '', this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.changePasswordFlag.next(data);
            });
    }

    resetPasswordFlag() {
        this.changePasswordFlag.next(undefined);
    }

    // DB mitteilen, dass User neue Lieferstellen eingesehen hat
    sendDpClickEvent(ags: string, sparte: string) {
        const url = `${this.restURL}/deliveryPointsClick?ags=${ags}&contenttype=${sparte}&appId=${this.appId}`;

        this.http.post(url, '', this.httpOptions).toPromise().then();
    }

    // Das Keycloak Token vom Nutzer in die Header einfügen
    getUserBearerToken() {
        this.communicationService.$userBearerToken.subscribe((token: string) => {
            if (token !== undefined) {
                this.userBearerToken = token;

                this.httpOptions = {
                    headers: new HttpHeaders({
                        userBearerToken: this.userBearerToken,
                    }),
                };
            }
        });
    }

    // Dokumente aktualisieren - Auslagerung, damit wenn die Finanzdaten gesperrt sind keine Daten abgefragt werden
    getDocumentChanges(kpId): void {
        this.getKaDocuments(kpId);
        this.getKorabDocuments(kpId);
    }

    // Bei Fehler Weiterleitung auf Maintenance Seite
    handleUnhandledError(type: string) {
        sessionStorage.setItem('errorTypeUnhandled', type);
        //this.authService.logoutAndRoute();
        // this.router.navigate(['/wartung']);
    }

    // Bei Fehler Weiterleitung auf Maintenance Seite
    handleHandledError(type: string) {
        sessionStorage.setItem('errorTypeHandled', type);
        //this.authService.logoutAndRoute();
        // this.router.navigate(['/wartung']);
    }

    // Senden der FEK Formular daten (neue FEK)
    sendFekFormularData(body: any, orgaId: string, orgrId: string) {
        const url = `${this.restURL}/fek.saveNewApplication?orgaId=${orgaId}&orgrId=${orgrId}&appId=${this.appId}`;
        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.sendFekData.next(data);
            });
    }

    resetFekFormularData() {
        this.sendFekData.next(undefined);
    }

    // Senden der FEK Formular daten (editiertes FEK)
    sendEditFekFormularData(body: any, orgaId: string, orgrId: string) {
        const url = `${this.restURL}/fek.editApplication?orgaId=${orgaId}&orgrId=${orgrId}&appId=${this.appId}`;
        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.sendEditFekData.next(data);
            });
    }

    resetEditFekFormularData() {
        this.sendEditFekData.next(undefined);
    }

    // Senden der FEK Formular daten
    sendFekConfirmation(body: any, kpId: string) {
        const url = `${this.restURL}/fek.bestaetigung?kpId=${kpId}&appId=${this.appId}`;
        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.confirmFekData.next(data);
            });
    }

    resetFekConfirmation() {
        this.confirmFekData.next(undefined);
    }

    // Senden der FEK Formular daten
    getFekList(kpId: string) {
        const url = `${this.restURL}/fek.getFekList?kpId=${kpId}&cache=${new Date().getTime()}&appId=${this.appId}`;
        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.fekList.next(data);
            });
    }

    resetFeklist() {
        this.fekList.next(undefined);
    }

    // Fek Antrag zurücksetzten
    revokeFek(orgaId: string, orgrId: string, body: any) {
        const url = `${this.restURL}/fek.revokeApplication?orgaId=${orgaId}&orgrId=${orgrId}&appId=${this.appId}`;
        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.revokeFekResponse.next(data);
            });
    }

    resetRevokeFekResponse() {
        this.revokeFekResponse.next(undefined);
    }

    // senden einer neuen agb oder ds
    hasNewAgbDs() {
        const url = this.restURL + `/agbds.get?cache=${new Date().getTime()}&appId=${this.appId}`;
        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data) => {
                this.hasNewAgbDsResponse.next(data);
            });
    }

    resetHasNewAgbDs() {
        this.hasNewAgbDsResponse.next(undefined);
    }

    getAddedValue(orgaId, orgrId) {
        const url = `${this.restURL}/wertschoepfung.getList?orgaId=${orgaId}&orgrId=${orgrId}&cache=${new Date().getTime()}&appId=${
            this.appId
        }`;
        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data) => {
                this.addedValueResponse.next(data);
            });
    }

    resetAddedValue() {
        this.addedValueResponse.next(undefined);
    }

    // Admin-Services
    getAgbDsData(category: string) {
        const url = `${this.restURLAdmin}/agbds.getList?category=${category}&cache=${new Date().getTime()}&appId=${this.appId}`;
        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data) => {
                this.agbDsData.next(data);
            });
    }

    resetAgbDsData() {
        this.agbDsData.next(undefined);
    }

    // löschen von agbs und ds
    deleteAgbDsData(ags: string) {
        const url = `${this.restURLAdmin}/agbds.delete?id=${ags}&cache=${new Date().getTime()}&appId=${this.appId}`;
        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => this.sendDeleteResponse.next(data));
    }

    resetDeleteAgbDsData() {
        this.sendDeleteResponse.next(undefined);
    }

    // senden einer neuen agb oder ds
    sendNewAgbDs(body: any) {
        const url = `${this.restURLAdmin}/agbds.save?appId=${this.appId}`;
        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data) => {
                this.newAgbDsResponse.next(data);
            });
    }

    resetNewAgbDs() {
        this.newAgbDsResponse.next(undefined);
    }

    // git die letzte login zeit aller nutzer als excel wieder
    getLoginForAllUsers() {
        const url = `${this.restURL}/getAllUserLogins?cache=${new Date().getTime()}&appId=${this.appId}`;
        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.loginForAllUsers.next(data);
            });
    }

    // Variable für nächsten Download resetten
    resetLoginForAllUsers() {
        this.loginForAllUsers.next(undefined);
    }

    // global
    getAdminKeyvisuals(): Observable<any> {
        const url = `${this.restURLAdmin}/keyvisuals?cache=${new Date().getTime()}&appId=${this.appId}`;

        return this.http.get(url, this.httpOptions).pipe(map(this.extractData));
    }

    getAdminContactPictures(): Observable<any> {
        const url = `${this.restURLAdmin}/contactpictures?cache=${new Date().getTime()}&appId=${this.appId}`;

        return this.http.get(url, this.httpOptions).pipe(map(this.extractData));
    }

    getAdminSubjectAreaPictures(): Observable<any> {
        const url = `${this.restURLAdmin}/subjectareapictures?cache=${new Date().getTime()}&appId=${this.appId}`;

        return this.http.get(url, this.httpOptions).pipe(map(this.extractData));
    }

    getAdminDownloads(): Observable<any> {
        const url = `${this.restURLAdmin}/documents?cache=${new Date().getTime()}&appId=${this.appId}`;

        return this.http.get(url, this.httpOptions).pipe(map(this.extractData));
    }

    // test upload
    uploadAssets(file: any, type: string) {
        const url = `${this.restURLAdmin}/uploadAssets?assetType=${type}&appId=${this.appId}`;

        this.http
            .post(url, file, this.httpOptions)
            .toPromise()
            .then((data: any) => this.sendUploadResponse.next(data));
    }

    resetUploadResponse() {
        this.sendUploadResponse.next(undefined);
    }

    deleteAsset(id: string) {
        const url = `${this.restURLAdmin}/deleteAsset?assetId=${id}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => this.sendDeleteResponse.next(data));
    }

    resetDeleteResponse() {
        this.sendDeleteResponse.next(undefined);
    }

    isAssetErasable(id: string, type: string) {
        const url = `${this.restURLAdmin}/isAssetErasable?assetId=${id}&assetType=${type}&cache=${new Date().getTime()}&appId=${
            this.appId
        }`;
        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => this.sendIsAssetErasableResponse.next(data));
    }

    resetIsAssetErasableResponse() {
        this.sendIsAssetErasableResponse.next(undefined);
    }

    // Liefert alle allgemienen Termine für
    getIndividualDates(pageName: string): Observable<any> {
        const url = `${this.restURLAdmin}/individualDates?pageName=${pageName}&cache=${new Date().getTime()}&appId=${this.appId}`;

        return this.http.get(url, this.httpOptions).pipe(map(this.extractData));
    }

    setIndividualDates(pageName: string, individualDates: any): Observable<any> {
        const url = `${this.restURLAdmin}/individualDates?pageName=${pageName}&appId=${this.appId}`;

        return this.http.post(url, individualDates, this.httpOptions).pipe(map(this.extractData));
    }

    // Seite Startseite

    setAdminPageData(data: envPageData, pageName: string): Observable<any> {
        const url = `${this.restURLAdmin}/pageData?pageName=${pageName}&appId=${this.appId}`;

        return this.http.post(url, data, this.httpOptions).pipe(map(this.extractData));
    }

    getAdminPageData(pageName: string): Observable<any> {
        const url = `${this.restURLAdmin}/pageData?pageName=${pageName}&cache=${new Date().getTime()}&appId=${this.appId}`;

        return this.http.get(url, this.httpOptions).pipe(map(this.extractData));
    }

    // Gitb  Städte und Gemeinden  wieder (druch suchfilter einschränkabr und durch type aussteuerbar für digan und mailversand)
    getGemeindeList(search: string, typ: string) {
        const url = `${this.restURL}/getCommuneList?search=${search}&typ=${typ}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data) => {
                this.getGemeinden.next(data);
            });
    }

    resetGetGemeindeListResponse() {
        this.getGemeinden.next(undefined);
    }

    // Gitb alle Informationen zu einer ausgewählten gemeinde für digan
    getClickedGemeindeInfos(ags: string, orgaId: string, parentOrgaId: string) {
        const url = `${
            this.restURL
        }/getCommuneList?ags=${ags}&orgaId=${orgaId}&parentOrgaId=${parentOrgaId}&typ=digan&cache=${new Date().getTime()}&appId=${
            this.appId
        }`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data) => {
                this.getGemeindeInfos.next(data);
            });
    }

    resetGetClickedGemeindeInfosResponse() {
        this.getGemeindeInfos.next(undefined);
    }

    // Gitb alle Städte und Gemeinden für das Anmeldemodal wieder
    checkUsernameExists(username) {
        const url = `${this.restURL}/digan.checkIfUsernameExists?username=${username}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data) => {
                this.checkUsername.next(data);
            });
    }

    resetCheckUsernameExistsResponse() {
        this.checkUsername.next(undefined);
    }

    // Gitb alle Städte und Gemeinden für das Anmeldemodal wieder
    checkMailExists(mail) {
        const url = `${this.restURL}/digan.checkIfEmailExists?email=${mail}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data) => {
                this.checkMail.next(data);
            });
    }

    resetCheckMailExistsResponse() {
        this.checkMail.next(undefined);
    }

    // Gibt ergebniss der Email verifizierung weider
    getVerifyMail(key: string) {
        const url = `${this.restURL}/digan.verifyMail?key=${key}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data) => {
                this.verfiyMailResponse.next(data);
            });
    }

    resetGetVerifyMailResponse() {
        this.verfiyMailResponse.next(undefined);
    }

    // Gibt anmeldadaten für registrierungbestätigung wieder
    getAnmeldeDaten(key: string) {
        const url = `${this.restURL}/digan.anmeldeDaten?key=${key}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data) => {
                this.getAnmeldeDatenResponse.next(data);
            });
    }

    resetGetAnmeldeDatenResponse() {
        this.getAnmeldeDatenResponse.next(undefined);
    }

    // anmledebestätigungsergebniss versenden
    sendRegistrationConfirmation(body: any) {
        const url = `${this.restURL}/digan.acknowledgeRegistration?appId=${this.appId}`;

        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data) => {
                this.sendRegistrationConfirmationResponse.next(data);
            });
    }

    resetsendRegistrationConfirmationResponse() {
        this.sendRegistrationConfirmationResponse.next(undefined);
    }

    // Registrierung abschicken
    sendRegistration(body: any) {
        const url = `${this.restURL}/digan.SaveToGemis?appId=${this.appId}`;
        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => this.registrationResponse.next(data));
    }

    resetSendRegistrationResponse() {
        this.registrationResponse.next(undefined);
    }

    // Funktionsliste für zentralen Mailversand
    getDiganFunctionList() {
        const url = `${this.restURLAdmin}/digan.getFunctionList?cache=${new Date().getTime()}&appId=${this.appId}`;

        return this.http.get(url, this.httpOptions);
    }

    // Neue AGB und DS akzeptieren abschicken
    newAgbDsAccept(body: any, pId: string) {
        const url = `${this.restURL}/agbds.accept?pId=${pId}&appId=${this.appId}`;

        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => this.newAgbDsAcceptResponse.next(data));
    }

    resetNewAgbDsAcceptResponse() {
        this.newAgbDsAcceptResponse.next(undefined);
    }

    // agbds Dokument für admins downloaden
    downloadAgbdsDocAdmin(docId: string, category: string = 'KomPo') {
        const url = `${this.restURLAdmin}/agbds.getDocument?docId=${docId}&category=${category}&cache=${new Date().getTime()}&appId=${
            this.appId
        }`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((docData: envDownloadedDoc) => {
                this.downloadedAgbdsDocAdmin.next(docData);
            });
    }

    // Variable für statisches Dokument resetten für mehrere Downloads
    resetAgbdsDocAdmin() {
        this.downloadedAgbdsDocAdmin.next(undefined);
    }

    // Wertschöpfung - Dokument pro Jahr downloaden
    downloadAddedValuesDocumentPerYear(kpId: string, year: string) {
        const url = `${this.restURL}/wertschoepfung.getDocument?kpId=${kpId}&year=${year}&cache=${new Date().getTime()}&appId=${
            this.appId
        }`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((docData: envDownloadedDoc) => {
                this.downloadedAddedValuesDocumentPerYear.next(docData);
            });
    }

    // Variable für statisches Dokument resetten für mehrere Downloads
    resetAddedValuesDocumentPerYear() {
        this.downloadedAddedValuesDocumentPerYear.next(undefined);
    }

    // agbds Dokument für user downloaden
    downloadAgbdsDocUser(docId: string, category: string = 'KomPo') {
        const url = `${this.restURL}/agbds.getDocument?docId=${docId}&category=${category}&cache=${new Date().getTime()}&appId=${
            this.appId
        }`;

        this.http
            .get(url)
            .toPromise()
            .then((docData: envDownloadedDoc) => {
                this.downloadedAgbdsDocUser.next(docData);
            });
    }

    // Variable für statisches Dokument resetten für mehrere Downloads
    resetAgbdsDocUser() {
        this.downloadedAgbdsDocUser.next(undefined);
    }

    // Neue AGB und DS Ablehnen abschicken
    newAgbDecline(pId) {
        const url = `${this.restURL}/agbds.decline?pId=${pId}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => this.newAgbDeclineResponse.next(data));
    }

    resetNewAgbDeclineResponse() {
        this.newAgbDeclineResponse.next(undefined);
    }

    // gibt Liste der platzhalter für mails
    getMailinglistPlaceholders() {
        const url = `${this.restURLAdmin}/zentralerMailversand.getMailinglistPlaceholders?cache=${new Date().getTime()}&appId=${
            this.appId
        }`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => this.mailinglistPlaceholdersResponse.next(data));
    }

    resetMailinglistPlaceholdersResponse() {
        this.mailinglistPlaceholdersResponse.next(undefined);
    }

    // gibt Liste der versendeten mail im zentralen mailversadn wieder
    getCentralMailList() {
        const url = `${this.restURLAdmin}/zentralerMailversand.getList?cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => this.centralMailListResponse.next(data));
    }

    resetCentralMailListResponse() {
        this.centralMailListResponse.next(undefined);
    }

    // gibt Liste der versendeten mail im zentralen mailversadn wieder
    getReciverCount(body: any): Observable<any> {
        const url = `${this.restURLAdmin}/zentralerMailversand.getReceiverCount?appId=${this.appId}`;

        return this.http.post(url, body, this.httpOptions).pipe(map(this.extractData));
    }

    // speichert einen neuen eintrag in der mailing list
    saveCentralMail(body: any) {
        const url = `${this.restURLAdmin}/zentralerMailversand.save?appId=${this.appId}`;

        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => this.saveCentralMailResponse.next(data));
    }

    resetSaveCentralMail() {
        this.saveCentralMailResponse.next(undefined);
    }

    // sendet eine neue anfrage los
    sendInquiry(orgrId: string, orgaId: string, body: any) {
        const url = `${this.restURL}/anfragen.saveInquiry?orgaId=${orgaId}&orgrId=${orgrId}&appId=${this.appId}`;

        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => this.sendInquiryResponse.next(data));
    }

    resetSendInquiryResponse() {
        this.sendInquiryResponse.next(undefined);
    }

    // gibt Liste der Anfragen typen wieder
    getInquiryTypes() {
        const url = `${this.restURLAdmin}/anfragen.readInquiryTypes?cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => this.inquiryTypesResponse.next(data));
    }

    resetInquiryTypesResponse() {
        this.inquiryTypesResponse.next(undefined);
    }

    // speichert einen neuen eintrag in der Anfragen list
    sendInquiryTypes(body: any) {
        const url = `${this.restURLAdmin}/anfragen.saveInquiryTypes?appId=${this.appId}`;

        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => this.sendInquiryTypesResponse.next(data));
    }

    reseteSendInquiryTypesResponse() {
        this.sendInquiryTypesResponse.next(undefined);
    }

    // speichert einen neuen eintrag in der mailing list
    sendDeleteUser(pId: any, username: string) {
        const url = `${this.restURL}/digab.SaveToGemis?pId=${pId}&username=${username}&appId=${this.appId}`;

        this.http
            .post(url, undefined, this.httpOptions)
            .toPromise()
            .then((data: any) => this.sendDeleteUserResponse.next(data));
    }

    resetSendDeleteUserResponse() {
        this.sendDeleteUserResponse.next(undefined);
    }

    // gibt ein Default asset wieder
    getDefaultAsset(id) {
        const url = `${this.restURLAdmin}/getDefaultValues?id=${id}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => this.defaultAssetResponse.next(data));
    }

    resetDefaultAssetResponse() {
        this.defaultAssetResponse.next(undefined);
    }

    // gibt die ablehungstexte wieder
    getDeclinetexts() {
        const url = `${this.restURLAdmin}/ablehnungstext.get?cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => this.getDeclinetextResponse.next(data));
    }

    resetGetDeclinetextResponse() {
        this.getDeclinetextResponse.next(undefined);
    }

    // speichert die ablehungstexte wieder
    editDeclinetexts(body: any) {
        const url = `${this.restURLAdmin}/ablehnungstext.save?appId=${this.appId}`;

        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => this.editDeclinetextResponse.next(data));
    }

    resetEditDeclinetextResponse() {
        this.editDeclinetextResponse.next(undefined);
    }

    // löscht die ablehungstexte wieder
    deleteDeclinetexts(id) {
        const url = `${this.restURLAdmin}/ablehnungstext.delete?id=${id}&cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => this.deleteDeclinetextResponse.next(data));
    }

    resetDeleteDeclinetextResponse() {
        this.deleteDeclinetextResponse.next(undefined);
    }

    // holt die Sortierung der Tabellen für die Liefertsllen redakture
    getTableorder() {
        const url = `${this.restURLAdmin}/tableorder.get?cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => this.getTableorderResponse.next(data));
    }

    resetGetTableorderResponse() {
        this.getTableorderResponse.next(undefined);
    }

    // Speichert die Sortierung der Tabellen für die Liefertsllen redakture
    saveTableorder(sortKey: string, sortOrder: string, tableId: string) {
        const url = `${this.restURLAdmin}/tableorder.save?sortKey=${sortKey}&sortOrder=${sortOrder}&tableId=${tableId}&appId=${this.appId}`;
        const body: any = {};

        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => this.saveTableorderResponse.next(data));
    }

    // Löscht die Sortierung der Tabellen für die Liefertsllen redakture
    deleteTableorder() {
        const url = `${this.restURLAdmin}/tableorder.delete?cache=${new Date().getTime()}&appId=${this.appId}`;

        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => this.deleteTableorderResponse.next(data));
    }

    // Laden der benötigten Daten eines Formulars
    getFormularData(id: string) {
        const url = `${this.restURL}/subjectareaFormular?subjectAreaAction=${id}&cache=${new Date().getTime()}&appId=${this.appId}`;
        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.getFormularDataResponse.next(data);
            });
    }

    resetGetFormularData() {
        this.getFormularDataResponse.next(undefined);
    }

    // Senden der Formular daten
    saveFormularData(body: any, id: string, orgaId: string, orgrId: string) {
        const url = `${this.restURL}/subjectareaFormular?subjectAreaAction=${id}&orgrId=${orgrId}&orgaId=${orgaId}&appId=${this.appId}`;
        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.savedFormularResponse.next(data);
            });
    }

    resetSaveFormularData() {
        this.savedFormularResponse.next(undefined);
    }

    // Holt die Formular daten für Measure Status
    getMeasureStatus(id: string, orgaId: string, orgrId: string) {
        const url = `${
            this.restURL
        }/subjectareaFormular?subjectAreaAction=${id}.list&orgrId=${orgrId}&orgaId=${orgaId}&cache=${new Date().getTime()}&appId=${
            this.appId
        }`;
        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.getMeasureStatusResponse.next(data);
            });
    }

    resetGetMeasureStatus() {
        this.getMeasureStatusResponse.next(undefined);
    }

    // Senden der Formular daten (registrirung für zum beispiel zälerstandsablesung)
    saveRegistrationForService(body: any) {
        const url = `${this.restURL}/saveRegistrationForService?appId=${this.appId}`;
        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.saveRegistrationForServiceResponse.next(data);
            });
    }

    resetRegistrationForServiceResponse() {
        this.saveRegistrationForServiceResponse.next(undefined);
    }

    // Laden der Zählerstandsinformationen
    getCountingValues(ags: string, sparte: string, malo: string = '') {
        const url = `${this.restURL}/zaehlerstaende.get?ags=${ags}&sparte=${sparte}&malo=${malo}&cache=${new Date().getTime()}&appId=${
            this.appId
        }`;
        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.countingValuesResponse.next(data);
            });
    }

    resetCountingValues() {
        this.countingValuesResponse.next(undefined);
    }

    // Zählerstellen an inubit schicken und erstellte Excel Downloaden
    downloadExcelFromCountingValue(body: any) {
        const url = `${this.restURL}/zaehlerstaende.excel?appId=${this.appId}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'userBearerToken': this.userBearerToken,
            }),
        };

        this.http
            .post(url, body, httpOptions)
            .toPromise()
            .then((data) => {
                this.excelDataCountingValue.next(data);
            })
            .catch((_) => {
                // this.handleUnhandledError("excel")
            });
    }

    // Exceldaten für nächsten Download leeren
    resetExcelDocCountingValue() {
        this.excelDataCountingValue.next(undefined);
    }

    // Senden der Neue Zählerstände ab
    saveNewCounterReadings(body: any, ags: string, sparte: string, mandt: string) {
        const url = `${this.restURL}/zaehlerstaende.save?ags=${ags}&sparte=${sparte}&mandt=${mandt}&appId=${this.appId}`;
        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.saveNewCounterReadingsResponse.next(data);
            });
    }

    resetNewCounterReadingsResponse() {
        this.saveNewCounterReadingsResponse.next(undefined);
    }

    // Laden der AGB Texte für die registriungsButtons
    getCountingValueAGBText(category: string) {
        const url = `${this.restURL}/registrationButtons.getAgbText?category=${category}&cache=${new Date().getTime()}&appId=${this.appId}`;
        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.countingValueAGBTextResponse.next(data);
            });
    }

    restCountingValueAGBText() {
        this.countingValueAGBTextResponse.next(undefined);
    }

    // Laden die einstellungen der Seite und prüft auf Wartungsmodus
    getApplicationOptions() {
        const url = `${this.restURL}/getApplicationOptions?cache=${new Date().getTime()}&appId=${this.appId}`;
        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.applicationOptions.next(data);
            });
    }

    resetApplicationOptions() {
        this.applicationOptions.next(undefined);
    }

    // speichert die UmsatzsteuerDaten im Gemis
    saveUstData(body) {
        const url = `${this.restURL}/ustData.sendToGemis?cache=${new Date().getTime()}&appId=${this.appId}`;
        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.ustDataResponse.next(data);
            });
    }

    resetSaveUstDataResponse() {
        this.ustDataResponse.next(undefined);
    }
    // speichert die UmsatzsteuerDaten im Gemis
    saveUstDataCommune(body) {
        const url = `${this.restURL}/saveUstDataCommune?cache=${new Date().getTime()}&appId=${this.appId}`;
        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.ustDataCommuneResponse.next(data);
            });
    }

    resetSaveUstDataCommuneResponse() {
        this.ustDataCommuneResponse.next(undefined);
    }

    // SaveCommuneText
    saveCommuneText(body) {
        const url = `${this.restURLAdmin}/saveCommuneText?cache=${new Date().getTime()}&appId=${this.appId}`;
        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.saveCommuneTextResponse.next(data);
            });
    }

    resetSaveCommuneTextResponse() {
        this.saveCommuneTextResponse.next(undefined);
    }

    // SaveCommuneText
    getCommuneTexts() {
        const url = `${this.restURLAdmin}/getCommuneTexts?cache=${new Date().getTime()}&appId=${this.appId}`;
        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.getCommuneTextResponse.next(data);
            });
    }

    resetGetCommuneTextResponse() {
        this.getCommuneTextResponse.next(undefined);
    }

    // SaveCommuneText
    deleteCommuneTexts(id) {
        const url = `${this.restURLAdmin}/deleteCommuneTexts?cache=${new Date().getTime()}&appId=${this.appId}`;
        this.http
            .post(url, id, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.deleteCommuneTextResponse.next(data);
            });
    }

    resetDeleteCommuneTextResponse() {
        this.deleteCommuneTextResponse.next(undefined);
    }

    getCommuneTextsForType(body) {
        const url = `${this.restURL}/getCommuneTextsForType?cache=${new Date().getTime()}&appId=${this.appId}`;
        this.http
            .post(url, body, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.communeTextsForType.next(data);
            });
    }

    resetCommuneTextsForType() {
        this.communeTextsForType.next(undefined);
    }

    getCommuneTextTypes() {
        const url = `${this.restURLAdmin}/getCommuneTextTypes?cache=${new Date().getTime()}&appId=${this.appId}`;
        this.http
            .get(url, this.httpOptions)
            .toPromise()
            .then((data: envCommuneTextType[]) => {
                this.communeTextTypes.next(data);
            });
    }

    resetCommuneTextTypes() {
        this.communeTextTypes.next(undefined);
    }

    getStatisticEnergyNetworkDataData(ags:string) {
        let url = `${this.restURL}/getStatisticData?cache=${new Date().getTime()}&ags=${ags}&appId=${this.appId}`;
        this.http
            .post(url, {"type": "Energiewirtschaftliche Netzdaten"}, this.httpOptions)
            .toPromise()
            .then((data: any) => {
                this.statisticData.next(data);
            });
    }
}