import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { RestService } from '@services/rest.service';
import { AuthService } from '@auth/auth.service';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';

import { ModalConfirmComponent } from '@modals/modal-confirm/modal-confirm.component';
import { CommunicationService } from 'src/app/shared/services/communication.service';

@Component({
    selector: 'app-confirm-registration',
    templateUrl: './confirm-registration.component.html',
    styleUrls: ['./confirm-registration.component.sass'],
})
export class ConfirmRegistrationComponent implements OnInit, OnDestroy {
    // Variablen
    key: string = undefined;
    registrationData: any = undefined;
    errorTexts: string = undefined;
    reason: string = undefined;
    isLoading: boolean = false;
    initialLoading: boolean = true;

    constructor(
        public dialog: MatDialog,
        private readonly authService: AuthService,
        private readonly restService: RestService,
        private readonly communicationService: CommunicationService,
        private readonly keycloakService: KeycloakService,
        private readonly toastr: ToastrService,
        private readonly router: Router
    ) {}

    // Initialer aufruf durch Nscale
    ngOnInit() {
        this.getLoggingStatus();
        this.communicationService.setBlockerStatus(false);
        this.key = this.getQueryParamy('key');
        this.restService.getAnmeldeDaten(this.key);
        this.restService.$getAnmeldeDatenResponse.subscribe((data: any) => {
            if (data !== undefined) {
                this.initialLoading = false;
                this.registrationData = data;
                this.restService.resetGetAnmeldeDatenResponse();
            }
        });
    }

    // Logging Status aus Service abfragen
    // Eingeloggt --> Umleitung auf "Meine Kommune"
    // Ausgeloggt --> Blocker entfernen
    async getLoggingStatus() {
        this.communicationService.setLoginStatus(await this.keycloakService.isLoggedIn());
        if (this.authService.checkUserPermission([], true)) {
            this.communicationService.setBlockerStatus(false);
        }
    }

    // beenden aller subscription beim destroy
    ngOnDestroy() {}

    // Regex zum heraussuchen von QueryParams
    getQueryParamy(name: string) {
        const url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    // Öffnet Dialog für Ablehngrund
    openConfirmDialog() {
        const dialogRef = this.dialog.open(ModalConfirmComponent, {
            width: 'auto',
            disableClose: true,
            data: {
                headline: 'Möchten Sie die Registrierung wirklich ablehnen?',
                text: '',
                hasReason: true,
                isReasonMandatory: true,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                if (result.decision === true) {
                    this.reason = result.reason;
                    this.confirmRegistration(false);
                }
            }
        });
    }

    // Senden der Entscheidung
    confirmRegistration(decision: boolean) {
        const body: any = {
            key: this.key,
            zugestimmt: decision,
            grund: this.reason,
        };
        this.restService.sendRegistrationConfirmation(body);
        this.isLoading = true;
        this.restService.$sendRegistrationConfirmationResponse.subscribe((data: any) => {
            if (data !== undefined) {
                this.isLoading = false;
                if (data.status === 201) {
                    this.toastr.success('Bearbeitung erfolgreich abgeschlossen.', '');
                    this.restService.resetsendRegistrationConfirmationResponse();
                    this.router.navigate(['/meinekommune']);
                } else {
                    this.toastr.success(data.text, 'Fehler');
                    this.restService.resetsendRegistrationConfirmationResponse();
                }
            }
        });
    }
}
