import { Component, OnInit, OnDestroy, ElementRef, Input } from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
	selector: 'app-modal-big',
	templateUrl: './modal-big.component.html',
	styleUrls: ['./modal-big.component.sass'],
})
// Komponente für ganzseitige Modale
export class ModalBigComponent implements OnInit, OnDestroy {
	// Variablen
	@Input() id: string;
	private readonly element: any;
	closeModalBig: boolean = false;

	constructor(private readonly modalService: ModalService, private readonly el: ElementRef) {
		this.element = el.nativeElement;
	}

	ngOnInit(): void {
		// Überprüfe, ob ID existiert
		if (!this.id) {
			console.error('Modal muss eine ID haben');

			return;
		}

		// Element ans Ende vom Body
		document.body.appendChild(this.element);

		// Instanz zum Service hinzufügen
		this.modalService.add(this);
	}

	// Instanz vom Service entfernen
	ngOnDestroy(): void {
		this.modalService.remove(this.id);
		document.body.removeChild(this.element);
	}

	wait(ms): void {
		const start = new Date().getTime();
		let end = start;
		while (end < start + ms) {
			end = new Date().getTime();
		}
	}

	// Modal öffnen
	open(): void {
		this.element.style.display = 'block';
		document.body.classList.add('envAppModalOpen');
	}

	// Modal schließen
	close(): void {
		this.closeModalBig = true;
		this.wait(500);
		this.element.style.display = 'none';
		document.body.classList.remove('envAppModalOpen');
	}
}
