import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@auth/auth.service';
import { RestService } from '@services/rest.service';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import { CommunicationService } from 'src/app/shared/services/communication.service';

@Component({
    selector: 'app-forgot-access-data',
    templateUrl: './forgot-access-data.component.html',
    styleUrls: ['./forgot-access-data.component.sass'],
})
export class ForgotAccessDataComponent implements OnInit, OnDestroy {
    // Variablen
    envMailTo: string = environment.incidentMailAddress;
    envMailSubject: string = 'Benutzername vergessen';
    // envMailBody: string = "";
    // mailTemplate: string = "<a href='mailto:" + this.envMailTo + "?subject=" + this.envMailSubject + "&body=" + this.envMailBody + "'>Ansprechpartner des KommunalPortals</a>";

    loggingStatus: boolean = false;
    wasEmailSend: boolean = false;
    userIsLocked: boolean = false;
    isUsernameFieldEmpty: boolean = false;
    isMailFieldEmpty: boolean = false;

    username: string = '';
    mail: string = '';

    constructor(
        private readonly authService: AuthService,
        private readonly restService: RestService,
        private readonly communicationService: CommunicationService,
        private readonly keycloakService: KeycloakService,
        private readonly router: Router
    ) {}

    // Logging Status aus Service abfragen
    // Eingeloggt --> Umleitung auf "Meine Kommune"
    // Ausgeloggt --> Blocker entfernen
    async getLoggingStatus() {
        this.communicationService.setLoginStatus(await this.keycloakService.isLoggedIn());
        if (this.authService.checkUserPermission([], true)) {
            this.communicationService.setBlockerStatus(false);
        }
    }

    // Mail Adresse an CommunicationService übergeben
    setInput(type: string) {
        // Bei Eingabe des Nutzernamen
        if (type === 'username') {
            if (this.username !== '') {
                // PW für Nutzer zurücksetzen
                this.restService.resetPwForUser(this.encodeUsername(this.username));
                this.restService.$resetPwResponse.subscribe((data) => {
                    if (data !== undefined) {
                        if (data.isLocked) {
                            this.userIsLocked = true; // Wenn Nutzer gesperrt ist
                        } else {
                            this.wasEmailSend = true; // E-Mail wurde versendet
                        }
                        this.restService.resetResetPwResponse();
                    }
                });
            } else {
                this.isUsernameFieldEmpty = true;
            }
        } else {
            // Wenn Mail angegeben wird
            if (this.mail !== '') {
                // Nutzernamen ermitteln
                this.restService.getUsernameForUser(this.encodeUsername(this.mail));
                this.restService.$resetUsernameForUserResponse.subscribe((data: any) => {
                    if (data !== undefined) {
                        if (data.isLocked) {
                            this.userIsLocked = true; // Wenn Nutzer gesperrt ist
                        } else {
                            this.wasEmailSend = true; // E-Mail wurde versendet
                        }
                        this.restService.resetGetUsernameForUser();
                    }
                });
            } else {
                this.isMailFieldEmpty = true;
            }
        }
    }

    // Username in Base64 umwandeln wegen IE
    encodeUsername(name: string): string {
        return btoa(encodeURIComponent(name));
    }

    ngOnInit() {
        this.getLoggingStatus();
    }

    // Blocker zurücksetzten
    ngOnDestroy() {
        this.userIsLocked = false;
        this.wasEmailSend = false;
        // this.communicationService.setBlockerStatus(true);
    }
}
