import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DownloadDocumentService } from '@services/download-document.service';

@Component({
    selector: 'app-modal-info',
    templateUrl: './modal-info.component.html',
    styleUrls: ['./modal-info.component.sass'],
})
export class ModalInfoComponent implements OnInit {
    // Variablen
    headline: string = '';
    bodyText: string = '';
    buttonText: string = '';
    additionalFunction: string = '';

    // Holen der Übergabe variablen
    constructor(
        public dialogRef: MatDialogRef<any>,
        private readonly downloadDocumentService: DownloadDocumentService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    // Initialer aufruf (Variablen belegen)
    ngOnInit() {
        this.headline = this.data.headline;
        this.bodyText = this.data.bodyText;
        this.buttonText = this.data.buttonText !== undefined ? this.data.buttonText : 'Schließen';
        this.additionalFunction = this.data.additionalFunction !== undefined ? this.data.additionalFunction : '';
    }

    // Download des angeklickten Dokuments
    downloadDoc(type: string) {
        this.downloadDocumentService.downloadDocument(undefined, type, 'hardStaticDocument', undefined, undefined);
    }

    // Dialog schließen
    closeDialog(): void {
        this.dialogRef.close();
    }
}
