import '../polyfills';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { initializer } from '@authConfig/initializer/initializer';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MyCommuneComponent } from './start-pages/my-commune/my-commune.component';
import { ForgotAccessDataComponent } from './start-pages/forgot-access-data/forgot-access-data.component';
import { MaintenanceModeComponent } from './start-pages/maintenance-mode/maintenance-mode.component';
import { VerifyMailComponent } from './start-pages/verify-mail/verify-mail.component';
import { ConfirmRegistrationComponent } from './start-pages/confirm-registration/confirm-registration.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { LayoutModule } from '@angular/cdk/layout';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// Shared
import { ModalsModule } from './modals/modals.module';
import { SharedModule } from './shared/shared.module';
import { DataTablesModule } from './data-tables/data-tables.module';
import { QuillModule } from 'ngx-quill';
import { MatCardModule } from '@angular/material/card';

@NgModule({ declarations: [
        AppComponent,
        MyCommuneComponent,
        ForgotAccessDataComponent,
        MaintenanceModeComponent,
        VerifyMailComponent,
        ConfirmRegistrationComponent
    ],
    // providers: [
    //     {
    //         provide: APP_INITIALIZER,
    //         useFactory: initializer,
    //         multi: true,
    //         deps: [KeycloakService],
    //     },
    // ],
    bootstrap: [AppComponent], 
    imports: [
        ModalsModule,
        SharedModule,
        BrowserModule,
        KeycloakAngularModule,
        FormsModule,
        BrowserAnimationsModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        LayoutModule,
        DataTablesModule,
        MatProgressBarModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
            countDuplicates: true,
            resetTimeoutOnDuplicate: true,
            maxOpened: 1,
            autoDismiss: true,
            newestOnTop: true,
            progressBar: true,
            timeOut: 10000,
        }),
        AppRoutingModule,
        MatCardModule,
        QuillModule.forRoot()], 
        providers: [provideHttpClient(withInterceptorsFromDi())] })

export class AppModule {}