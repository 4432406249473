<div class="orderingArea">
  @for (acc of accordionData; track acc; let i = $index) {
    <div class="component" id="{{ i }}" [ngClass]="getClass()">
      <app-accordion-edit [(pageData)]="pageData" [(communeLists)]="communeLists" [accordionData]="acc" [type]="type" class="editor" [(defaultAsset)]="defaultAsset"></app-accordion-edit>
      @if (isShown('sort')) {
        <div class="btn-group-vertical">
          @if (acc.type !== 'dateType' && acc.category !== 'subjectArea' && acc.category !== 'registrationButtons') {
            <button
              type="button"
              class="btn btn-danger button-delete"
              (click)="deleteAccordion(acc)"
              >
              <i class="icon-minus-circle"></i>
            </button>
          }
          <button type="button" class="btn btn-secondary button-position-up" [disabled]="acc.position === 0" (click)="voteAccordionUp(acc)"><i class="icon-angle-up"></i></button>
          <button type="button" class="btn btn-secondary button-position-down" [disabled]="acc.position === accordionData.length - 1" (click)="voteAccordionDown(acc)">
            <i class="icon-angle-down"></i>
          </button>
        </div>
      }
    </div>
  }
  @if (isShown('new')) {
    <div class="component" [ngClass]="type === 'subjectArea' ? 'envSmallSizeComponentAdd' : ''">
      <div class="headline btn">{{ newTitle }}</div>
      <div class="button">
        <button class="btn btn-success button-add" (click)="addAccordion()"><i class="icon-plus-circle"></i></button>
      </div>
    </div>
  }
</div>
