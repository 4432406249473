import { Breakpoints } from '@angular/cdk/layout';

export const responsiveSachsenEnergieConfig = {
    desktopVersion: {
        showCommuneImage: true, //Großes Bild der Kommune auf Startseite und Anmeldeseite
    },
    tabletVersion: {
        showCommuneImage: true,
    },
    mobileVersionLandscape: {
        useConfig: Breakpoints.HandsetLandscape,
        showCommuneImage: false,
    },
    mobileVersionPortrait: {
        useConfig: Breakpoints.HandsetPortrait,
        showCommuneImage: false,
    },
};
