<div class="envDialogWrapper">
    <div [ngClass]="isMobilePortraitView ? 'dialogContentWrapper center' : 'dialogContentWrapper'">
        <div class="envCommunitiesModalText">
            Sie sind für den Zugang zu mehreren Kommunen zugelassen, bitte wählen Sie die gewünschte aus:
        </div>
        <div class="envModalRow1">
            <div class="envLoginbarCommunities">
                @if (activeCommune !== '') {
                <div class="envModalDropdown" (click)="changeDropdown()" [ngClass]="isModalDropdownActive ? 'activeDropdown' : ''">
                    {{ activeCommune }}
                    <span class="envDropdownImage" [ngClass]="isModalDropdownActive ? 'icon-angle-up' : 'icon-angle-down'"></span>
                </div>
                } @else {
                <div class="envModalDropdown" (click)="changeDropdown()" [ngClass]="isModalDropdownActive ? 'activeDropdown' : ''">
                    Bitte eine Kommune auswählen...
                    <span class="envDropdownImage" [ngClass]="isModalDropdownActive ? 'icon-angle-up' : 'icon-angle-down'"></span>
                </div>
                } @if (isModalDropdownActive) {
                <div class="envDropdownCloseWrapper" (click)="changeDropdown()"></div>
                }
                <ul class="envDropdownList" [ngClass]="isModalDropdownActive ? 'activeList' : 'inactiveList'">
                    @for (myCommune of myCommunities; track myCommune) {
                    <li (click)="setActiveCommune(myCommune)" class="envDropdownText">
                        {{ myCommune }}
                    </li>
                    }
                </ul>
            </div>
        </div>
        <div class="envModalRow2">
            <div class="envCommunitiesModalButtonWrapper">
                @if (!isMobilePortraitView) {
                <button class="envLoginBarResetButton" (click)="closeDialog(undefined)">Abmelden</button>
                }
                <button class="envStandardButton" (click)="checkActiveCommune()">Auswahl bestätigen</button>
            </div>
        </div>
    </div>
</div>
