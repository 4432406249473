@if (isLoading) {
<div
    [ngClass]="{
        envLoadingPageWrapperMobilePortrait: isMobilePortraitView,
        envLoadingPageWrapperMobileLandscape: isMobileLandscapeView,
        envLoadingPageWrapperTabletPortrait: isTabletPortraitView,
        envLoadingPageWrapperTabletLandscape: isTabletLandscapeView,
        envLoadingPageWrapper: isDesktopLandscapeView
    }">
    <mat-spinner class="envLoadSpinner" [diameter]="80"></mat-spinner>
</div>
} @if (!isLoading) {
<div>
    <div class="envContentHeader">
        <div class="envContentHeaderWrapper">
            <div [ngClass]="isMobilePortraitView ? 'envContentHeaderTextMobile' : 'envContentHeaderText'">
                {{ headline }}
                @if (subheadline !== '') {
                <div class="envContentSubheaderText">
                    {{ subheadline }}
                </div>
                }
            </div>
            @if (!isUserAdmin && showImage) {
            <div class="envContentHeaderImageWrapper" [ngClass]="keyVisual.type === 'WAPPEN' ? 'envContentHeaderWappenWrapper' : ''">
                <img [ngClass]="keyVisual.type === 'WAPPEN' ? 'envContentHeaderWappen' : 'envContentHeaderImage'" [src]="keyVisual.src" />
            </div>
            } @if (contentHeadline || additionalDescription || description) {
            <div class="envContentHeaderDescriptionContainer">
                <div class="envContentHeaderSubheadline">{{ contentHeadline }}</div>
                <div class="envContentHeaderAdditionalDescription">{{ additionalDescription }}</div>
                <div class="envContentHeaderDescription" [innerHTML]="description"></div>
            </div>
            }
        </div>
    </div>
</div>
}
