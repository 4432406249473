<app-content-header (isContentHeaderLoading)="isContentHeaderLoading = $event"></app-content-header>
@if (!isLoading && !isContentHeaderLoading) {
<div>
    <div class="envContentMyCommunes">
        <div class="envContentMyCommunesGrid">
            <div [ngClass]="boxesForMobileVersion ? '' : 'row'">
                @for (navButton of navButtons; track navButton) {
                <div [ngClass]="boxesForMobileVersion ? '' : 'envContentMyCommune col-md-4'">
                    @if (navButton.isLocked) {
                    <div class="envContentMyCommuneLocked">
                        <div class="envContentMyCommuneLockedBackground"></div>
                        <span class="icon-lock1 envContentMyCommuneLockedImage"></span>
                    </div>
                    <div
                        [ngClass]="
                            boxesForMobileVersion
                                ? 'envContentMyCommuneMobile {{ navButton.className }}'
                                : 'envContentMyCommuneContent {{ navButton.className }}'
                        ">
                        >
                        <div class="envContentMyCommuneText">{{ navButton.name }}</div>
                        <span class="icon-chevron-right envContentMyCommuneImage"></span>
                        @if (isNotify(navButton)) {
                        <div class="envContentMyCommuneNotificationWrapper">
                            @if (isKorabOrKa(navButton)) {
                            <img class="envContentMyCommuneNotification" [src]="newIcon" />
                            } @if (!isKorabOrKa(navButton)) {
                            <img class="envContentMyCommuneNotification" [src]="newIcon" />
                            }
                        </div>
                        }
                    </div>
                    } @else {
                    <a routerLink="{{ navButton.url }}">
                        <div
                            class="{{ navButton.className }}"
                            [ngClass]="
                                boxesForMobileVersion
                                    ? 'envContentMyCommuneMobile envContentMyCommuneContentHover'
                                    : 'envContentMyCommuneContent envContentMyCommuneContentHover'
                            ">
                            <div
                                class="{{ navButton.useLinebreaks ? 'envPre' : '' }}"
                                [ngClass]="boxesForMobileVersion ? 'envContentMyCommuneTextMobile' : 'envContentMyCommuneText'">
                                {{ navButton.name }}
                            </div>
                            <span
                                [ngClass]="
                                    boxesForMobileVersion
                                        ? 'icon-chevron-right envContentMyCommuneImageMobile'
                                        : 'icon-chevron-right envContentMyCommuneImage'
                                "></span>
                            @if (isNotify(navButton)) {
                            <div class="envContentMyCommuneNotificationWrapper">
                                @if (isKorabOrKa(navButton)) {
                                <img class="envContentMyCommuneNotification" [src]="newIcon" />
                                } @if (!isKorabOrKa(navButton)) {
                                <img class="envContentMyCommuneNotification" [src]="newIcon" />
                                }
                            </div>
                            }
                        </div>
                    </a>
                    }
                </div>
                }
            </div>
        </div>
    </div>
</div>
}
