import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { envDocument } from '@classes/envDocument';
import { envPageData } from '@classes/envPageData';
import { RestService } from '@services/rest.service';
import { CommunicationService } from '@services/communication.service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AdmModalAssetsComponent } from '@modals/adm-modal-assets/adm-modal-assets.component';
import { ModalPromptComponent } from '@modals/modal-prompt/modal-prompt.component';

@Component({
    selector: 'app-download-edit-manager',
    templateUrl: './download-edit-manager.component.html',
    styleUrls: ['./download-edit-manager.component.sass'],
})
export class DownloadEditManagerComponent implements OnInit {
    // @Input() pageData: envPageData;
    @Input() downloadHeadline: string;
    @Output() downloadHeadlineChange = new EventEmitter();

    @Input() pageData: envPageData;
    @Input() downloads: envDocument[];

    // nur als Platzhalter, selectedDownload kommt eigentlich aus dem Down
    selectedDownload: envDocument;
    private selectSubject: Subject<any>;

    constructor(
        private readonly restService: RestService,
        private readonly communicationService: CommunicationService,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        this.downloads = this.downloads || new Array<envDocument>();
        this.reorderDownloadArray();
    }

    emitDownloadHeadline(headline: string) {
        this.downloadHeadlineChange.emit(headline);
    }

    reorderDownloadArray() {
        if (this.downloads.length < 1) {
            return;
        }
        this.downloads.sort((e1, e2) => {
            if (e1.position > e2.position) {
                return 1;
            }
            if (e1.position < e2.position) {
                return -1;
            }

            return 0;
        });
        this.downloads.forEach((el, i) => {
            el.position = i;
        });
    }

    deleteDownload(el: envDocument): void {
        const dialogRef = this.dialog.open(ModalPromptComponent, {
            width: 'auto',
            disableClose: true,
            data: {
                title: 'Download wirklich löschen?',
                bodyText: '',
                buttonText1: 'abbrechen',
                buttonClass1: 'btn btn-secondary',
                buttonText2: 'löschen',
                buttonClass2: 'btn btn-danger',
                isDecision: true,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined && result === 2) {
                this.deleteCallback(el);
            }
        });
    }

    deleteCallback(el: envDocument): void {
        const index: number = this.downloads.indexOf(el, 0);
        if (index > -1) {
            this.downloads.splice(index, 1);
        }
        this.reorderDownloadArray();
    }

    voteDownloadUp(el: envDocument): void {
        const currPos = el.position; // old position value
        const newPos = currPos - 1; // new position value
        const oldEl = this.downloads[newPos]; // old element on new position
        const newEl = el; // current element on old position
        // reset position flags for reordering in fn reorderAccordionArray
        oldEl.position = currPos;
        newEl.position = newPos;

        this.reorderDownloadArray();
    }
    voteDownloadDown(el: envDocument): void {
        const currPos = el.position; // old position value
        const newPos = currPos + 1; // new position value
        const oldEl = this.downloads[newPos]; // old element on new position
        const newEl = el; // current element on old position
        // reset position flags for reordering in fn reorderAccordionArray
        oldEl.position = currPos;
        newEl.position = newPos;

        this.reorderDownloadArray();
    }

    // Neuen Download hinzufügen
    addDownload(): void {
        const download = {
            name: '',
            id: null,
            type: '',
            date: '',
            position: this.downloads.length,
            isUnread: false,
        };
        this.downloads.push(download);
    }

    // Funktion zum auswählen des Dokuments oder anderer Assets
    editDownload(curFile: envDocument, type: string, downloadPosition: number): void {
        this.selectSubject = new Subject<any>();
        const currentFile = curFile;
        this.communicationService.setCurrentfile(curFile);

        // subscribe to event
        this.selectSubject.subscribe({
            next: (file) => {
                if (typeof file !== 'undefined') {
                    // copy some props:
                    currentFile.id = file.id;
                    currentFile.url = file.url;
                    currentFile.type = file.type;
                    currentFile.date = file.date;
                    this.selectSubject.unsubscribe();
                }
            },
        });

        // Öffnet Asset dialog
        const dialogRef = this.dialog.open(AdmModalAssetsComponent, {
            width: 'auto',
            data: {
                type,
                currentFile: curFile,
                headlineText: 'Dokument auswählen',
                useSimpleUpload: false,
            },
        });

        // Ausgewähltes Asset hinterlegen
        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                if (result.type === 'DOWNLOAD') {
                    this.downloads[downloadPosition] = result.selectedFile;
                    this.downloads[downloadPosition].position = downloadPosition;
                }
                // this.toastr.error('Die hochgeladene Datei hat nicht die selbe Dateiendung.')
            }
        });
    }
}
