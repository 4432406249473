<div class="envInputFieldWrapper">
  <div class="envDropdownWrapper">
    @if (type == 'default') {
      <div
				[ngClass]="{
					envDropdownBox: size == 'default',
					envDropdownBoxSmall: size == 'small',
					envDropdownBoxSmallPlus: size == 'small+',
					invalidField: invalidField,
					envInvalidInput: envInvalidInput,
					envDropdownDisabled: _disabled === true
				}"
        (click)="changeDropdown('angle', selectedName)"
        >
        {{ selectedItem ? selectedItem[anzeigevalue] + (this.additionalValue !== undefined ? ' (' + selectedItem[this.additionalValue] + ')' : '') : placeholder }}
        <span class="envDropdownImage" [ngClass]="isDropdownActive ? 'icon-angle-up' : 'icon-angle-down'"></span>
      </div>
    }

    @if (type == 'multiselect') {
      <div
				[ngClass]="{
					envDropdownBox: size == 'default',
					envDropdownBoxSmall: size == 'small',
					envDropdownBoxSmallPlus: size == 'small+',
					invalidField: invalidField,
					envInvalidInput: envInvalidInput,
					envDropdownDisabled: _disabled === true
				}"
        (click)="changeDropdown('angle', selectedName)"
        >
        {{ selectedItem ? selectedItem.length + (this.additionalValue !== undefined ? ' (' + selectedItem[this.additionalValue] + ')' : '') + ' ausgewählt' : placeholder }}
        <span class="envDropdownImage" [ngClass]="isDropdownActive ? 'icon-angle-up' : 'icon-angle-down'"></span>
      </div>
    }

    @if (type == 'searchable') {
      <div class="envInputFieldWrapper">
        <input
					[ngClass]="{
						envDropdownBox: size == 'default',
						envDropdownBoxSmall: size == 'small',
						envDropdownBoxSmallPlus: size == 'small+',
						invalidField: invalidField,
						envInvalidInput: envInvalidInput,
						envDropdownDisabled: _disabled === true
					}"
          (click)="changeDropdown('angle', selectedName)"
          placeholder="{{ placeholder }}"
          (ngModelChange)="dropdownInputSearch($event)"
          [(ngModel)]="searchItem"
          [value]="this.additionalValue !== undefined && selectedItem !== undefined ? ' (' + selectedItem[this.additionalValue] + ')' : ''"
          type="text"
          />
          <span class="envDropdownSearchImage" [ngClass]="isDropdownActive ? 'icon-angle-up' : 'icon-angle-down'"></span>
        </div>
      }

      @if (isDropdownActive) {
        <div class="envDropdownCloseWrapper" (click)="changeDropdown('CloseWrapper')"></div>
      }
      <ul
			[ngClass]="{
				envDataTableDropdownListSmall: size == 'small',
				envDataTableDropdownListSmallPlus: size == 'small+',
				envDataTableDropdownList: size == 'default',
				activeList: isDropdownActive,
				inactiveList: !isDropdownActive
			}"
        >
        @if (type != 'multiselect') {
          @for (item of dropdownList; track item) {
            <li (click)="setActiveDropdown(item)" class="envDropdownText">
              {{ item[anzeigevalue] + (this.additionalValue !== undefined ? ' (' + item[this.additionalValue] + ')' : '') }}
            </li>
          }
        } @else {
          @for (item of dropdownList; track item) {
            <li (click)="setActiveDropdown(item)" [title]="item[anzeigevalue]" class="envDropdownText">
              <span class="envInputButtonBox envRemoveTop">
                <input class="envInputButtonRemover" type="checkbox" />
                @if (checkmultiselect(item)) {
                  <span class="envInputButtonCheck"></span>
                }
              </span>
              {{ item[anzeigevalue] + (this.additionalValue !== undefined ? ' (' + item[this.additionalValue] + ')' : '') }}
            </li>
          }
        }
      </ul>
    </div>
  </div>
