import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { SharedModule } from '../shared/shared.module';

// Modale importieren
import { ModalAgbComponent } from './modal-agb/modal-agb.component';
import { ModalRegistrationComponent } from './modal-registration/modal-registration.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { ModalDeleteUserComponent } from './modal-delete-user/modal-delete-user.component';
import { ModalFekConfirmationComponent } from './modal-fek-confirmation/modal-fek-confirmation.component';
import { ModalFekFormularComponent } from './modal-fek-formular/modal-fek-formular.component';
import { ModalInfoComponent } from './modal-info/modal-info.component';
import { ModalSubjectAreaButtonComponent } from './modal-subject-area-button/modal-subject-area-button.component';
import { ModalBigComponent } from './modal-big/modal-big.component';
import { DataTableModalComponent } from './data-table-modal/data-table-modal.component';
import { ModalSubjectAreaFormularComponent } from './modal-subject-area-formular/modal-subject-area-formular.component';
import { ModalBouncyCastleComponent } from './modal-bouncy-castle/modal-bouncy-castle.component';
import { ModalEditCountingValueComponent } from './modal-edit-counting-value/modal-edit-counting-value.component';
import { ModalCountingValueRegistrationComponent } from './modal-counting-value-registration/modal-counting-value-registration.component';
import { ModalMeasurementInfoComponent } from './modal-measurement-info/modal-measurement-info.component';
import { ModalSelectReadingOrderComponent } from './modal-select-reading-order/modal-select-reading-order.component';

// Adm
import { AdmModalCreateAgbdsComponent } from './adm-modal-create-agbds/adm-modal-create-agbds.component';
import { AdmModalDpCommentComponent } from './adm-modal-dp-comment/adm-modal-dp-comment.component';
import { AdmModalMailInfoComponent } from './adm-modal-mail-info/adm-modal-mail-info.component';
import { AdmModalMailSendComponent } from './adm-modal-mail-send/adm-modal-mail-send.component';
import { AdmModalSubjectAreaButtonComponent } from './adm-modal-subject-area-button/adm-modal-subject-area-button.component';
import { AdmModalSubjectAreaButtonListComponent } from './adm-modal-subject-area-button-list/adm-modal-subject-area-button-list.component';
import { AdmDataTableModalComponent } from './adm-data-table-modal/adm-data-table-modal.component';
import { AdmModalAssetsComponent } from './adm-modal-assets/adm-modal-assets.component';
import { ModalPromptComponent } from './modal-prompt/modal-prompt.component';
import { AdmModalValidationErrorComponent } from './adm-modal-validation-error/adm-modal-validation-error.component';
import { ModalCommunitiesComponent } from './modal-communities/modal-communities.component';
import { ModalHandleDpChangeComponent } from './modal-handle-dp-change/modal-handle-dp-change.component';
import { AdmModalDeleteFileComponent } from './adm-modal-delete-file/adm-modal-delete-file.component';
import { AdmModalAssetTextsComponent } from './adm-modal-asset-texts/adm-modal-asset-texts.component';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
    declarations: [
        ModalAgbComponent,
        ModalRegistrationComponent,
        ModalConfirmComponent,
        ModalDeleteUserComponent,
        ModalFekConfirmationComponent,
        ModalFekFormularComponent,
        ModalInfoComponent,
        ModalSubjectAreaButtonComponent,
        ModalSubjectAreaFormularComponent,
        ModalBigComponent,
        DataTableModalComponent,
        ModalBouncyCastleComponent,
        ModalEditCountingValueComponent,
        ModalCountingValueRegistrationComponent,
        ModalMeasurementInfoComponent,
        // Adm
        AdmModalCreateAgbdsComponent,
        AdmModalDpCommentComponent,
        AdmModalMailInfoComponent,
        AdmModalMailSendComponent,
        AdmModalSubjectAreaButtonComponent,
        AdmModalSubjectAreaButtonListComponent,
        AdmDataTableModalComponent,
        AdmModalAssetsComponent,
        ModalPromptComponent,
        AdmModalValidationErrorComponent,
        ModalCommunitiesComponent,
        ModalHandleDpChangeComponent,
        AdmModalDeleteFileComponent,
        ModalSelectReadingOrderComponent,
        AdmModalAssetTextsComponent,
    ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        FormsModule,
        SharedModule,
        MomentModule.forRoot({
            relativeTimeThresholdOptions: {
                m: 59,
            },
        }),
        NgbModule,
        FileUploadModule,
        MatCheckboxModule
    ],
    exports: [
        ModalAgbComponent,
        ModalRegistrationComponent,
        ModalConfirmComponent,
        ModalDeleteUserComponent,
        ModalFekConfirmationComponent,
        ModalFekFormularComponent,
        ModalInfoComponent,
        ModalSubjectAreaButtonComponent,
        ModalSubjectAreaFormularComponent,
        ModalBigComponent,
        DataTableModalComponent,
        ModalBouncyCastleComponent,
        ModalEditCountingValueComponent,
        ModalCountingValueRegistrationComponent,
        ModalMeasurementInfoComponent,
        // Adm
        AdmModalCreateAgbdsComponent,
        AdmModalDpCommentComponent,
        AdmModalMailInfoComponent,
        AdmModalMailSendComponent,
        AdmModalSubjectAreaButtonComponent,
        AdmModalSubjectAreaButtonListComponent,
        AdmDataTableModalComponent,
    ],
})
export class ModalsModule {}
