import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ResponsiveService {
    //Responsive Variablen

    //Boxen für Navigation schmaler
    private readonly boxesForMobileVersion = new BehaviorSubject<boolean>(undefined);
    $boxesForMobileVersion = this.boxesForMobileVersion.asObservable();

    //Anzeige des blauen Logouttimerbalkens
    private readonly showLogoutTimerBar = new BehaviorSubject<boolean>(undefined);
    $showLogoutTimerBar = this.showLogoutTimerBar.asObservable();

    //Anzeigen des Logout Icons
    private readonly showLogoutIcon = new BehaviorSubject<boolean>(undefined);
    $showLogoutIcon = this.showLogoutIcon.asObservable();

    //Anordnung der Kommunenauswahl und des Logouts für Mobile
    private readonly centerLogoutAndCommmuneSelect = new BehaviorSubject<boolean>(undefined);
    $centerLogoutAndCommmuneSelect = this.centerLogoutAndCommmuneSelect.asObservable();

    //Margin für Logoutbutton
    private readonly logoutButtonWithMargin = new BehaviorSubject<boolean>(undefined);
    $logoutButtonWithMargin = this.logoutButtonWithMargin.asObservable();

    //Wenn es sich um die Mobile Porträt-Ansicht handelt
    private readonly isMobilePortraitView = new BehaviorSubject<boolean>(undefined);
    $isMobilePortraitView = this.isMobilePortraitView.asObservable();

    //Wenn es sich um die Mobile Landscape-Ansicht handelt
    private readonly isMobileLandscapeView = new BehaviorSubject<boolean>(undefined);
    $isMobileLandscapeView = this.isMobileLandscapeView.asObservable();

    //Wenn es sich um die Tablet Porträt-Ansicht handelt
    private readonly isTabletPortraitView = new BehaviorSubject<boolean>(undefined);
    $isTabletPortraitView = this.isTabletPortraitView.asObservable();

    //Wenn es sich um die Tablet Landscape-Ansicht handelt
    private readonly isTabletLandscapeView = new BehaviorSubject<boolean>(undefined);
    $isTabletLandscapeView = this.isTabletLandscapeView.asObservable();

    //Wenn es sich um die Desktop Landscape-Ansicht handelt
    private readonly isDesktopLandscapeView = new BehaviorSubject<boolean>(undefined);
    $isDesktopLandscapeView = this.isDesktopLandscapeView.asObservable();

    //Image im Header anzeigen
    private readonly showHeaderImage = new BehaviorSubject<boolean>(undefined);
    $showHeaderImage = this.showHeaderImage.asObservable();

    //Unternehmenslogo anzeigen
    private readonly showCompanyLogo = new BehaviorSubject<boolean>(undefined);
    $showCompanyLogo = this.showCompanyLogo.asObservable();

    //Top Navigationsbar anzeigen
    private readonly showTopNavbar = new BehaviorSubject<boolean>(undefined);
    $showTopNavbar = this.showTopNavbar.asObservable();

    //Sign In Button-Anordnung für mobile Endgeräte
    private readonly signInForMobile = new BehaviorSubject<boolean>(undefined);
    $signInForMobile = this.signInForMobile.asObservable();

    //Navigationsmenü anzeigen
    private readonly showNavigationMenu = new BehaviorSubject<boolean>(undefined);
    $showNavigationMenu = this.showNavigationMenu.asObservable();

    constructor(responsive: BreakpointObserver) {
        responsive
            .observe([
                Breakpoints.HandsetPortrait,
                Breakpoints.HandsetLandscape,
                Breakpoints.TabletPortrait,
                Breakpoints.TabletLandscape,
                Breakpoints.WebLandscape,
            ])
            .subscribe((result) => {
                const breakpoints = result.breakpoints;
                //Mobile Porträt-Modus
                if (breakpoints[Breakpoints.HandsetPortrait]) {
                    this.isMobilePortraitView.next(true);
                    this.isMobileLandscapeView.next(false);
                    this.isTabletPortraitView.next(false);
                    this.isTabletLandscapeView.next(false);
                    this.isDesktopLandscapeView.next(false);
                    this.showLogoutTimerBar.next(false);
                    this.showLogoutIcon.next(false);
                    this.centerLogoutAndCommmuneSelect.next(true);
                    this.logoutButtonWithMargin.next(false);
                    this.showHeaderImage.next(false);
                    this.showCompanyLogo.next(false);
                    this.showTopNavbar.next(false);
                    this.boxesForMobileVersion.next(true);
                }
                //Mobile Landscape-Modus
                if (breakpoints[Breakpoints.HandsetLandscape]) {
                    this.isMobilePortraitView.next(false);
                    this.isMobileLandscapeView.next(true);
                    this.isTabletPortraitView.next(false);
                    this.isTabletLandscapeView.next(false);
                    this.isDesktopLandscapeView.next(false);
                    this.showLogoutTimerBar.next(false);
                    this.showLogoutIcon.next(false);
                    this.showHeaderImage.next(false);
                    this.centerLogoutAndCommmuneSelect.next(true);
                    this.showCompanyLogo.next(false);
                    this.showTopNavbar.next(false);
                    this.boxesForMobileVersion.next(true);
                }
                //Tablet Porträt-Modus
                if (breakpoints[Breakpoints.TabletPortrait]) {
                    this.isMobilePortraitView.next(false);
                    this.isMobileLandscapeView.next(false);
                    this.isTabletPortraitView.next(true);
                    this.isTabletLandscapeView.next(false);
                    this.isDesktopLandscapeView.next(false);
                    this.showLogoutTimerBar.next(false);
                    this.showLogoutIcon.next(false);
                    this.showHeaderImage.next(false);
                    this.centerLogoutAndCommmuneSelect.next(false);
                    this.showCompanyLogo.next(true);
                    this.showTopNavbar.next(false);
                    this.boxesForMobileVersion.next(false);
                }
                //Tablet Landscape-Modus
                if (breakpoints[Breakpoints.TabletLandscape]) {
                    this.isMobilePortraitView.next(false);
                    this.isMobileLandscapeView.next(false);
                    this.isTabletPortraitView.next(false);
                    this.isTabletLandscapeView.next(true);
                    this.isDesktopLandscapeView.next(false);
                    this.showLogoutTimerBar.next(false);
                    this.showLogoutIcon.next(false);
                    this.showHeaderImage.next(false);
                    this.centerLogoutAndCommmuneSelect.next(false);
                    this.showCompanyLogo.next(false);
                    this.showTopNavbar.next(false);
                    this.boxesForMobileVersion.next(true);
                }
                //Desktop Landscape-Modus
                if (breakpoints[Breakpoints.WebLandscape]) {
                    this.isMobilePortraitView.next(false);
                    this.isMobileLandscapeView.next(false);
                    this.isTabletPortraitView.next(false);
                    this.isTabletLandscapeView.next(false);
                    this.isDesktopLandscapeView.next(true);
                    this.showLogoutTimerBar.next(true);
                    this.showLogoutIcon.next(true);
                    this.centerLogoutAndCommmuneSelect.next(false);
                    this.logoutButtonWithMargin.next(true);
                    this.isMobilePortraitView.next(false);
                    this.showHeaderImage.next(true);
                    this.isTabletPortraitView.next(false);
                    this.showCompanyLogo.next(true);
                    this.showTopNavbar.next(true);
                    this.boxesForMobileVersion.next(false);
                }
            });
    }
}
