<div class="envDialogWrapper">
    <div class="envModalHeadline">
        {{ headlineText }}
    </div>
    <div class="envWrapper row">
        @if (!useSimpleUpload) {
        <div class="files envFileWrapper col" [ngClass]="isReadyToSend ? '' : 'envRedBorder'">
            @if (loadingFiles) {
            <div class="envLoadingAssetsWrapper">
                <span class="icon-loop2 envLoadingAssets"></span>
            </div>
            } @for (file of files; track file) {
            <div class="file row" (click)="setSelectetFile(file)" [ngClass]="isFileSelected(file, selectedFile) ? 'envSelected' : ''">
                @if (type === 'DOWNLOAD') {
                <div class="col-1">
                    <span class="envAssetDownload marked icon-file-text2"></span>
                </div>
                } @if (type !== 'DOWNLOAD') {
                <div class="col-3">
                    <img [ngClass]="checkType(type)" src="{{ restURL + assetPathBackend + file.url }}" />
                </div>
                }
                <div class="envAssetLableText col" title="{{ file.name }}">
                    {{ file.name }}
                    @if (file.id === currentFile.id) {
                    <a> (Aktuell verwendet)</a>
                    }
                </div>
            </div>
            }
        </div>
        }
        <div class="envUploadWrapper col">
            @if (!areFilesValid) {
            <div class="envError envHint">
                Bitte beachten Sie die erlaubten Dateitypen:
                <div class="row">
                    @for (uploadFilter of uploadFilters[this.type]; track uploadFilter) {
                    <p class="col">
                        {{ uploadFilter.filter }}
                    </p>
                    }
                </div>
            </div>
            }
            <div class="envInputButtonWrapper">
                <input type="file" id="file" (change)="handleFileInput($event.target.files)" class="envFileInput" />
                <label for="file">
                    <span class="icon-upload"></span>
                    <span id="envLabelText"> Datei auswählen</span>
                </label>
            </div>
            <div class="envUploadedFilesWrapper">
                @for (uploadedFile of uploadedFiles; track uploadedFile; let i = $index) {
                <div class="row">
                    <span class="col">{{ uploadedFile.name }}</span>
                    <span class="col-2 envDeleteIcon" (click)="deleteFileFromArray(i)">X</span>
                </div>
                }
            </div>
            @if (type === 'KEYVISUAL') {
            <div class="files envHint">
                Die Keyvisuals müssen eine Größe von 1920px zu 480px (Seitenverhältnis 4:1) haben. Wenn dieses Format nicht eingehalten
                wird, kommt es in der Anzeige zu Verzerrungen.
            </div>
            } @if (type === 'CONTACT') {
            <div class="files envHint">
                Die Kontaktbilder müssen eine Größe von 200px zu 200px (Seitenverhältnis 1:1) haben. Wenn dieses Format nicht eingehalten
                wird, kommt es in der Anzeige zu Verzerrungen.
            </div>
            } @if (type === 'SUBJECTAREAPICTURE') {
            <div class="files envHint">
                Die Themenbilder sollten eine Breite von mindestens 500px (Seitenverhältnis 4:2) haben. Wenn dieses Format nicht eingehalten
                wird, kommt es in der Anzeige zu Verzerrungen.
            </div>
            } @if (type === 'DOWNLOAD') {
            <div class="files envHint">Die Downloads müssen als PDF bereit gestellt werden.</div>
            } @if (!useSimpleUpload) {
            <div class="envButtonWrapperModal row">
                <button class="envStandardButton envUploadButton" (click)="uploadData()">Dateien hochladen</button>
            </div>
            }
        </div>
    </div>
    @if (isLoading) {
    <div class="envLoadSpinnerContainer">
        <mat-spinner class="envLoadSpinner" [diameter]="80"></mat-spinner>
    </div>
    } @if (!isLoading) {
    <div class="envButtonWrapper row">
        <div class="col">
            <button class="envStandardButton envCloseButton" (click)="closeDialog(undefined)">Abbrechen</button>
            @if (!useSimpleUpload) {
            <button class="envStandardButton envSaveButton" (click)="saveSelection()">Auswählen</button>
            } @if (useSimpleUpload) {
            <button class="envStandardButton envSaveButton" (click)="uploadData()">Hochladen</button>
            }
        </div>
    </div>
    }
</div>
