import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { RestService } from '@services/rest.service';

import { envDocument } from '@classes/envDocument';
import { envPicture } from '@classes/envPicture';
import { environment } from '@environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-adm-modal-assets',
    templateUrl: './adm-modal-assets.component.html',
    styleUrls: ['./adm-modal-assets.component.sass'],
})
export class AdmModalAssetsComponent implements OnInit {
    constructor(
        public toastr: ToastrService,
        public dialogRef: MatDialogRef<AdmModalAssetsComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        private readonly restService: RestService
    ) {}

    restURL = environment.restBaseURL;
    assetPathBackend = environment.assetPathBackend;

    uploadedFiles: any[] = [];

    files: envDocument[] | envPicture[];
    selectedFile: envDocument;
    currentFile: envDocument | envPicture;
    type: string;
    isReadyToSend: boolean = true;
    loadingFiles: boolean = true;
    useSimpleUpload: boolean = false;
    isLoading: boolean = false;
    areFilesValid: boolean = true;
    isFileValid: boolean = false;

    subsciption: Subscription;

    headlineText = '';

    dataSource: Function;

    uploadFilters: any = {
        KEYVISUAL: [{ filter: '.jpg' }, { filter: '.png' }],
        CONTACT: [{ filter: '.jpg' }, { filter: '.png' }],
        SUBJECTAREAPICTURE: [{ filter: '.jpg' }, { filter: '.png' }],
        DOWNLOAD: [{ filter: '.pdf' }],
    };

    currUploadedFile: File;
    isUploadValid: boolean = false;
    errorText: string = '';
    base64Data: string = '';

    ngOnInit(): void {
        this.initializeValues();
    }

    initializeValues() {
        this.type = this.dialogData.type;
        this.currentFile = this.dialogData.currentFile;
        this.headlineText = this.dialogData.headlineText;
        this.useSimpleUpload = this.dialogData.useSimpleUpload;

        this.setTypeVariables();
    }

    setTypeVariables() {
        switch (this.type) {
            case 'CONTACT':
                this.dataSource = this.restService.getAdminContactPictures;
                break;
            case 'DOWNLOAD':
                this.dataSource = this.restService.getAdminDownloads;
                break;
            case 'SUBJECTAREAPICTURE':
                this.dataSource = this.restService.getAdminSubjectAreaPictures;
                break;
            case 'KEYVISUAL':
                this.dataSource = this.restService.getAdminKeyvisuals;
                break;
            default:
        }

        this.getData();
    }

    private getData(): void {
        this.loadingFiles = true;
        this.dataSource.call(this.restService).subscribe((data) => {
            if (data !== undefined) {
                this.loadingFiles = false;
                this.files = data;
            }
        });
    }

    sendSelectedFile(): any {
        if (this.selectedFile !== undefined) {
            this.isReadyToSend = true;
            this.closeDialog(this.selectedFile); // publish data
        } else {
            this.isReadyToSend = false;
        }
    }

    // Nach Hochladen des Files auf Variable speichern und Namen in Button anzeigen
    handleFileInput(files: FileList) {
        if (files.item(0) !== null) {
            this.isLoading = true;
            this.currUploadedFile = files.item(0);
            this.isLoading = false;
            if (this.checkUpload()) {
                this.saveDocument();
            }
        } else {
            this.currUploadedFile = null;
        }
    }

    checkUpload() {
        this.errorText = '';
        if (this.currUploadedFile.name !== '' && this.currUploadedFile.name !== undefined) {
            if (!this.checkFileType(this.currUploadedFile)) {
                this.isUploadValid = false;
                this.errorText = 'Der Dateityp der hochgeladenen Datei ' + this.currUploadedFile.name + ' wird nicht unterstützt.';
            } else {
                this.isUploadValid = true;
            }
        }

        return this.isUploadValid;
    }

    // Dokument zu Base64 umwandeln
    saveDocument(): void {
        this.isLoading = true;
        const fileReader: FileReader = new FileReader();
        fileReader.onload = this.handleFileReader.bind(this);
        fileReader.readAsDataURL(this.currUploadedFile);
    }

    // Base64 Dokument auf variable legen
    handleFileReader(file) {
        const reader = file.target;
        this.base64Data = reader.result;
        this.returnDocument();
    }

    // Dokumentenstruktur zusammenbauen und Dialog schließen
    returnDocument() {
        if (this.base64Data !== '') {
            const responseDocument = {
                name: this.currUploadedFile.name,
                size: this.currUploadedFile.size,
                data: this.base64Data,
            };
            this.isLoading = false;
            this.uploadedFiles.push(responseDocument);
            this.currUploadedFile = null;
        }
    }

    checkFileType(file): boolean {
        this.isFileValid = false;
        for (const currFilter of this.uploadFilters[this.type]) {
            if (file.name.toLocaleUpperCase().indexOf(currFilter.filter.toUpperCase()) !== -1) {
                this.isFileValid = true;
            }
        }

        return this.isFileValid;
    }

    checkType(type: string) {
        switch (type) {
            case 'KEYVISUAL':
                return 'envKeyvisalThumbnail';
            case 'CONTACT':
                return 'envContactThumbnail';
            case 'SUBJECTAREAPICTURE':
                return 'envSubjectAreaThumbnail';
            default:
                return '';
        }
    }

    setSelectetFile(file): void {
        this.selectedFile = file;
    }

    isFileSelected(file, selection): boolean {
        if (file !== undefined && selection !== undefined) {
            return file.id === selection.id;
        }

        return false;
    }

    // funktion lädt files hoch und schließt das dialog
    // uploadData() {
    // 	this.uploadDocument() ? this.closeDialog(undefined): "Fehler!";
    // }

    // funktion lädt files hoch
    uploadData() {
        // Daten hochladen
        if (this.uploadedFiles.length > 0) {
            this.isLoading = true;
            this.restService.uploadAssets(this.uploadedFiles, this.type);
            this.subsciption = this.restService.$sendUploadResponse.subscribe((data: any) => {
                if (data !== undefined) {
                    if (data.status === '200') {
                        this.subsciption.unsubscribe();
                        this.restService.resetUploadResponse();
                        this.toastr.success('Die Daten wurden erfolgreich hochgeladen.');
                        this.isLoading = false;
                        this.closeDialog('upload');
                    } else {
                        this.subsciption.unsubscribe();
                        this.restService.resetUploadResponse();
                        this.toastr.error('Die Daten konnten nicht hochgeladenwerden.', data.text);
                        this.isLoading = false;
                    }
                }
            });
        }
    }

    deleteFileFromArray(index) {
        this.uploadedFiles.splice(index, 1);
    }

    // funktion zum speichern der auswahl
    saveSelection() {
        const item = {
            selectedFile: this.selectedFile ? this.selectedFile : this.currentFile,
            type: this.type,
        };

        this.closeDialog(item);
    }

    // Schließen des Dialogs
    closeDialog(item) {
        this.dialogRef.close(item);
    }
}
