<div class="envMainPage">
    @if (isMobilePortraitView) {
    <mat-progress-bar mode="determinate" [value]="tokenTimer * 3.333"></mat-progress-bar>
    }
    <div
        [ngClass]="{
            envNavbarMobilePortrait: isMobilePortraitView,
            envNavbarMobileLandscape: isMobileLandscapeView,
            envNavbar: isDesktopLandscapeView || isTabletLandscapeView,
            envNavbarTablet: isTabletPortraitView
        }">
        @if (showTopNavbar) {
        <div class="envTopNavbar">
            <div class="envTopNavbarList">
                <div class="envTopNavbarTextLink" routerLink="/">KommunalPortal</div>
                <div class="envTopNavbarTextDescription">
                    <a class="envTopNavbarTextInfo" [href]="topNavBarInfoUrl"> {{ topNavBarInfoText }} </a>
                </div>
            </div>
        </div>
        }
        <div
            [ngClass]="{
                envSubNavBarMobile: isMobilePortraitView,
                envSubNavbar: isMobileLandscapeView || isTabletLandscapeView || isTabletPortraitView || isDesktopLandscapeView
            }">
            <div [ngClass]="isMobilePortraitView ? 'envSubNavbarMenuWrapper flex' : 'envSubNavbarMenuWrapper'">
                <div class="d-flex flex-row">
                    <button class="envMenuButton" (click)="changeBurgerMenu()">
                        <span class="envMenuIcon" [ngClass]="!menuOpen ? 'icon-bars' : 'icon-close'"></span>
                    </button>
                    @if (!menuOpen && !isMobilePortraitView) {
                    <div class="envMenuText">Menü</div>
                    }
                </div>
                @if (isMobilePortraitView && myCommunities.length >= 2) {
                <div [ngClass]="logoutButtonWithMargin ? 'envLoginbarCommunities' : 'envLoginbarCommunitiesMobile'">
                    <div
                        class="envDropdownBox"
                        (click)="changeDropdown('commune')"
                        [ngClass]="isCommuneDropdownActive ? 'activeDropdown' : ''">
                        {{ activeCommune }}
                        <span class="envDropdownImage" [ngClass]="isCommuneDropdownActive ? 'icon-angle-up' : 'icon-angle-down'"></span>
                    </div>
                    <ul class="envDropdownList" [ngClass]="isCommuneDropdownActive ? 'activeList' : 'inactiveList'">
                        @for (myCommune of myCommunities; track myCommune) {
                        <li (click)="setActiveCommune(myCommune, 'commune')" class="envDropdownText">
                            {{ myCommune }}
                        </li>
                        }
                    </ul>
                </div>
                }
            </div>
            @if (showCompanyLogo) {
            <div class="envSubNavbarLogoWrapper">
                <img
                    class="envSubNavbarLogo"
                    [routerLink]="isUserAdmin ? ['/admin/cockpit'] : ['/']"
                    src="assets/images/content/companyLogo.png" />
            </div>
            }
            <div class="envSubNavbarButtonWrapper"></div>
        </div>
    </div>
    @if (!currentlyLoggedIn) {
    <div
        [ngClass]="{
            envLoginBarMobile: isMobilePortraitView || isMobileLandscapeView,
            envLoginBarTablet: isTabletPortraitView || isTabletLandscapeView,
            envLoginBar: isDesktopLandscapeView
        }"
        class="d-flex"
        [class.flex-row]="isDesktopLandscapeView"
        [class.flex-col]="isMobilePortraitView || isTabletPortraitView">
        @if (!isMobilePortraitView && !isTabletPortraitView) {
        <div class="envLoginBarLeft">
            <a [routerLink]="'/'">
                <span class="icon-home envLoginBarHome"></span>
            </a>
        </div>
        }
        <div
            [ngClass]="{
                envLoginBarMiddlePortrait: isTabletPortraitView || isMobilePortraitView,
                envLoginBarMiddleLandscape: isTabletLandscapeView || isMobileLandscapeView,
                envLoginBarMiddleDesktopLandscape: isDesktopLandscapeView
            }">
            <div
                [ngClass]="
                    isMobilePortraitView
                        ? 'd-flex flex-row justify-content-center align-items-center'
                        : 'd-flex flex-row justify-content-center align-items-center'
                ">
                @if (!isMobilePortraitView && !isTabletPortraitView) {
                <span class="icon-lock envLoginBarImageBig envLoginBarLockedOut"></span>
                }
                <button
                    [ngClass]="{
                        envLoginButton: isDesktopLandscapeView || isMobilePortraitView || isTabletLandscapeView || isMobileLandscapeView,
                        envLoginButtonBig: isTabletPortraitView
                    }"
                    (click)="login()">
                    Anmelden
                </button>
                <!-- <button
                    [class.envRegistrationButton]="
                        isMobilePortraitView || isDesktopLandscapeView || isTabletLandscapeView || isMobileLandscapeView
                    "
                    [class.envRegistrationButtonBig]="isTabletPortraitView"
                    [ngClass]="disableRegistrationButton === true ? 'envDisabledButton' : ''"
                    (click)="openModalRegistration()"
                    [disabled]="disableRegistrationButton">
                    Jetzt registrieren
                </button> -->
            </div>
        </div>
        <div class="envLoginBarRight">
            <ul class="envLoginLinkList">
                <li class="envLoginLink" routerLink="/zugangsdatenvergessen">
                    <span
                        class="icon-arrow-right"
                        [ngClass]="{
                            envLoginLinkButtonMobile: isMobilePortraitView || isTabletPortraitView,
                            envLoginLinkButton: isDesktopLandscapeView
                        }"></span>
                    <span [class.envLoginLinkBigText]="isTabletPortraitView">Zugangsdaten vergessen</span>
                </li>
                <li class="envLoginLink">
                    <span
                        class="icon-arrow-right"
                        [ngClass]="{
                            envLoginLinkButtonMobile: isMobilePortraitView || isTabletPortraitView,
                            envLoginLinkButton: isDesktopLandscapeView
                        }"></span>
                    <a class="envLoginLinkMail" [href]="incidentMail" [class.envLoginLinkBigText]="isTabletPortraitView">Störung melden</a>
                </li>
            </ul>
        </div>
    </div>
    } @else { @if (!isMobilePortraitView) {
    <div [ngClass]="isMobilePortraitView ? 'envLoginBarMobile d-flex flex-col' : 'envLoginBar d-flex flex-row'">
        <div class="envLoginBarLeft">
            @if (!isMobilePortraitView) {
            <a [routerLink]="isUserAdmin ? ['/admin/cockpit'] : ['/']">
                <span class="icon-home envLoginBarHome"></span>
            </a>
            }
        </div>
        <div [ngClass]="centerLogoutAndCommmuneSelect ? '' : 'envLoginBarMiddleLoggedIn'">
            @if (showLogoutIcon) {
            <span class="icon-unlock envLoginBarImageBig"></span>
            } @if (showLogoutTimerBar) {
            <progress max="30" value="{{ tokenTimer }}">
                <progress max="30" value="{{ tokenTimer }}" class="html5">
                    <div class="progress-bar">
                        <span></span>
                    </div>
                </progress>
            </progress>
            } @if (!isMobilePortraitView) {
            <div class="envLoginBarInfoText">Automatischer Logout in {{ tokenTimer }} Minuten</div>
            } @if (!isUserAdmin) {
            <div>
                @if (myCommunities.length == 1) {
                <div class="envLoginbarCommune">
                    {{ myCommunities }}
                </div>
                } @if (!isMobilePortraitView && myCommunities.length >= 2) {
                <div [ngClass]="logoutButtonWithMargin ? 'envLoginbarCommunities' : 'envLoginbarCommunitiesMobile'">
                    <div
                        class="envDropdownBox"
                        (click)="changeDropdown('commune')"
                        [ngClass]="isCommuneDropdownActive ? 'activeDropdown' : ''">
                        {{ activeCommune }}
                        <span class="envDropdownImage" [ngClass]="isCommuneDropdownActive ? 'icon-angle-up' : 'icon-angle-down'"></span>
                    </div>
                    <ul class="envDropdownList" [ngClass]="isCommuneDropdownActive ? 'activeList' : 'inactiveList'">
                        @for (myCommune of myCommunities; track myCommune) {
                        <li (click)="setActiveCommune(myCommune, 'commune')" class="envDropdownText">
                            {{ myCommune }}
                        </li>
                        }
                    </ul>
                </div>
                }
            </div>
            } @if (!isMobilePortraitView) {
            <button class="envLoginButton" id="envLoginLogoutButton" (click)="isLogoutAllowed()">Abmelden</button>
            }
            <div class="envLoginBarLeft"></div>
        </div>
        @if (isDesktopLandscapeView) {
        <div class="envLoginBarRight"></div>
        }
    </div>
    } }
    <div
        [ngClass]="{
            envContentPage: isDesktopLandscapeView || isMobileLandscapeView || isTabletLandscapeView,
            envContentPageTabletPortrait: isTabletPortraitView,
            envContentPageMobilePortrait: isMobilePortraitView
        }">
        <div>
            <div
                [ngClass]="!menuPageBlocker ? 'envDisplayNone' : 'envDisplayYes'"
                class="envMenuPageBlocker"
                (click)="changeBurgerMenu()"></div>
            <nav id="envNavgation" class="envMenuNavbar">
                <div class="envMenuNavbarContent">
                    <ul>
                        @if (isMobilePortraitView && currentlyLoggedIn) {
                        <li class="envMenuNavbarItemWrapper">
                            <a class="envMenuNavbarItem envNavbarLink" (click)="isLogoutAllowed()" id="signout">Abmelden</a>
                        </li>
                        } @for (menuItem of menuItems; track menuItem) {
                        <li class="envMenuNavbarItemWrapper">
                            <a
                                class="envMenuNavbarItem envNavbarLink"
                                routerLink="{{ menuItem.url }}"
                                [ngClass]="checkCurrentActivePage(menuItem.url) ? 'envMenuCurrentActivePage' : ''"
                                (click)="changeBurgerMenu()"
                                attr.id="{{ menuItem.url }}"
                                >{{ menuItem.name }}</a
                            >
                            @if (menuItem.unterseiten) {
                            <span
                                id="{{ menuItem.name }}Icon"
                                class="menuItemIcon icon-chevron-down"
                                (click)="changeMenuSubItem(menuItem.name, menuItem.url)"></span>
                            } @if (menuItem.unterseiten) {
                            <ul class="envMenuNavbarSubItemWrapper" id="{{ menuItem.name }}">
                                @for (unterseite of menuItem.unterseiten; track unterseite) {
                                <li class="envMenuNavbarSubItem">
                                    <a
                                        class="envNavbarLink"
                                        [ngClass]="checkCurrentActivePage(unterseite.url) ? 'envMenuCurrentActivePage' : ''"
                                        routerLink="{{ unterseite.url }}"
                                        [queryParams]="{ category: unterseite.queryParam }"
                                        queryParamsHandling="merge"
                                        (click)="changeBurgerMenu()">
                                        {{ unterseite.name }}
                                    </a>
                                </li>
                                }
                            </ul>
                            }
                        </li>
                        }
                    </ul>
                </div>
            </nav>
        </div>
        <div [ngClass]="!isViewBlocked ? 'envDisplayNone' : 'envDisplayYes'" class="envPageBlocker"></div>
        @if (showLoadingIndicator) {
        <app-loading-indicator class="envLoadingOverlay"> </app-loading-indicator>
        } @if (!showLoadingIndicator) {
        <router-outlet></router-outlet>
        }
    </div>
    <div
        [ngClass]="{
            envFooterWrapper: isDesktopLandscapeView || isTabletLandscapeView || isTabletPortraitView,
            envFooterWrapperMobilePortrait: isMobilePortraitView,
            envFooterWrapperMobileLandscape: isMobileLandscapeView
        }">
        <div class="envFooterTop"></div>
        <div class="envFooterBottom">
            <div class="envBrand"></div>
            <ul class="envFooterList">
                <li class="envFooterListItem">
                    <a [class.envFontSize6]="isTabletPortraitView" class="envFooterLink" target="_blank" [href]="footerBar.imprint">
                        Impressum
                    </a>
                </li>
                <li class="envFooterListItem">
                    <a [class.envFontSize6]="isTabletPortraitView" class="envFooterLink" target="_blank" [href]="footerBar.dataPrivacy">
                        Datenschutzerklärung
                    </a>
                </li>
                <li class="envFooterListItem">
                    <a
                        [class.envFontSize6]="isTabletPortraitView"
                        class="envFooterLink"
                        target="_blank"
                        [href]="footerBar.generalRegulations">
                        Allgemeine Regelungen
                    </a>
                </li>
                <li class="envFooterListItem">
                    <a
                        [class.envFontSize6]="isTabletPortraitView"
                        class="envFooterLink"
                        target="_blank"
                        [href]="footerBar.legalInformation">
                        Rechtliche Hinweise
                    </a>
                </li>
                <li class="envFooterListItem">
                    <a [class.envFontSize6]="isTabletPortraitView" class="envFooterLink" target="_blank" [href]="footerBar.contact">
                        Kontakt
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
<app-modal-big id="envLoadingModal">
    <div class="envSubNavbarLogoWrapper">
        <img class="envSubNavbarLogo" routerLink="/" src="assets/images/content/companyLogo.png" alt="Unternehmenslogo" />
    </div>
    <div class="envLodingText">KommunalPortal</div>
</app-modal-big>
