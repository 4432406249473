import { KeycloakService } from 'keycloak-angular';

export class EnviaKeycloakFunctions {
    config: any;
    keycloak: KeycloakService;

    constructor(config) {
        this.config = config;
    }

    login() {
        this.keycloak.login();
    }

    logout() {
        this.keycloak.logout();
    }

    getUserInfo() {
        this.keycloak.loadUserProfile();
    }

    getAccessToken() {
        this.keycloak.getKeycloakInstance();
    }

    isUserLoggedIn() {
        return this.getAccessToken() !== null;
    }

    updateToken(time) {
        this.keycloak.updateToken(1800);
    }
}
