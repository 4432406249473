import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { DataTableComponent } from './data-table/data-table.component';
import { DataTableFekOverviewComponent } from './data-table-fek-overview/data-table-fek-overview.component';
import { SponsoringDataTableComponent } from './sponsoring-data-table/sponsoring-data-table.component';
import { DataTableCountingValueComponent } from './data-table-counting-value/data-table-counting-value.component';
import { AdmDataTableAgbComponent } from './adm-data-table-agb/adm-data-table-agb.component';
import { AdmDataTableClusterComponent } from './adm-data-table-cluster/adm-data-table-cluster.component';
import { AdmDataTableDpComponent } from './adm-data-table-dp/adm-data-table-dp.component';
import { AdmDataTableMailCentralComponent } from './adm-data-table-mail-central/adm-data-table-mail-central.component';
import { AdmDataTableDpCommentComponent } from './adm-data-table-dp-comment/adm-data-table-dp-comment.component';
import { DataTableMeasurmentComponent } from './data-table-measurment/data-table-measurment.component';
import { AddedValueDataTableComponent } from './added-value-data-table/added-value-data-table.component';
import { DataTableEnergyNetworkComponent } from './data-table-energy-network-data/data-table-energy-network.component';

import { ModalsModule } from '../modals/modals.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        DataTableComponent,
        DataTableFekOverviewComponent,
        SponsoringDataTableComponent,
        DataTableCountingValueComponent,
        AdmDataTableAgbComponent,
        AdmDataTableClusterComponent,
        AdmDataTableDpComponent,
        AdmDataTableMailCentralComponent,
        AdmDataTableDpCommentComponent,
        DataTableMeasurmentComponent,
        AddedValueDataTableComponent,
        DataTableEnergyNetworkComponent
    ],
    imports: [CommonModule, FormsModule, ModalsModule, MatProgressSpinnerModule, SharedModule, NgxChartsModule],
    exports: [
        DataTableComponent,
        DataTableFekOverviewComponent,
        SponsoringDataTableComponent,
        DataTableCountingValueComponent,
        AdmDataTableAgbComponent,
        AdmDataTableClusterComponent,
        AdmDataTableDpComponent,
        AdmDataTableMailCentralComponent,
        AdmDataTableDpCommentComponent,
        DataTableMeasurmentComponent,
        AddedValueDataTableComponent,
        DataTableEnergyNetworkComponent
    ],
})
export class DataTablesModule {}
