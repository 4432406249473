<div class="element" [ngClass]="type === 'subjectArea' ? 'envSmallSizeComponent' : ''">
    <div class="row justify-content-md-center">
        <div class="col-3">
            <input type="checkbox" class="form-check-input" [(ngModel)]="accordionData.isOpen" name="isopen" />
            <label class="form-check-label">Klappbox initial geöffnet</label>
        </div>
    </div>
    <div class="envAccordionHeadline">
        <input
            type="text"
            class="form-control"
            [ngClass]="accordionData.type !== 'contactType' ? 'envAccordionHeader' : 'envContactAccordionHead'"
            placeholder="Headline"
            [(ngModel)]="accordionData.headline"
            name="headline" />
    </div>
    @if (accordionData.type !== 'contactType') {
    <div class="envAccordionWrapper">
        @if (accordionData.type === 'html') {
        <div class="row">
            <div class="col-2 envPlaceholderLable">Platzhalter:</div>
            @for (p of placeholder; track p) {
            <div class="col envPlaceholderText">
                <ul>
                    <li>
                        [[{{ p.name }}@for (a of p.args; track a) {
                        <span> {{ a }}=""</span>
                        }]]
                    </li>
                </ul>
            </div>
            }
        </div>
        } @if (accordionData.type !== 'dateType') {
        <div class="envClearEditorButtonWrapper">
            <button
                class="envClearEditorButton"
                (click)="clearEditor(accordionData.position)"
                [ngClass]="type === 'subjectArea' ? 'envClearEditorButtonSmall' : ''"
                title="Vollständiges leeren des Textfeldes">
                Editor leeren
            </button>
        </div>
        } @if (accordionData.type !== 'dateType') {
        <app-quill-wrapper [(body)]="accordionData.body" (modelChange)="getModelChangeItem($event)"></app-quill-wrapper>
        }
    </div>
    } @if (accordionData.type === 'contactType') {
    <div class="orderingArea">
        @for (contact of contacts; track contact; let contactPosition = $index) {
        <div class="envAccordionWrapper component row">
            <div class="row">
                <div class="col-11">
                    <div class="envContact row">
                        <div class="col-3 envContactImgWrapper envPictureEditorWapper">
                            <button
                                type="button"
                                class="btn btn-secondary button-edit envPictureEditButton"
                                (click)="editFiles(contact.picture, 'CONTACT', contactPosition)">
                                <i class="icon-pencil"></i>
                            </button>
                            <img class="envPictureEditor" src="{{ restURL + contact.picture.url }}" />
                        </div>
                        <div class="col">
                            <div class="envContactTextWrapper row">
                                <label class="envContactLable col"> Name:</label>
                                <input
                                    type="text"
                                    class="envContactText form-control col-8"
                                    placeholder="Name"
                                    [(ngModel)]="contact.name"
                                    name="subHeadline" />
                            </div>
                            <div class="envContactTextWrapper row">
                                <label class="envContactLable col"> Adressangaben:</label>
                                <textarea
                                    rows="4"
                                    type="text"
                                    class="envContactText form-control col-8"
                                    placeholder="Adressangaben"
                                    [(ngModel)]="contact.addressdata"
                                    name="subHeadline">
                                </textarea>
                            </div>
                            <div class="envContactTextWrapper row">
                                <label class="envContactLable col"> E-Mail:</label>
                                <input
                                    type="text"
                                    class="envContactText form-control col-8"
                                    placeholder="E-Mail"
                                    [(ngModel)]="contact.email"
                                    name="subHeadline" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-1">
                    <div class="btn-group-vertical">
                        <button type="button" class="btn btn-danger button-delete envDeleteButton" (click)="deleteContact(contact)">
                            <i class="icon-minus-circle"></i>
                        </button>
                        <button
                            type="button"
                            class="btn btn-secondary button-position-up envUpButton"
                            [disabled]="contact.position === 0"
                            (click)="voteContactUp(contact)">
                            <i class="icon-angle-up"></i>
                        </button>
                        <button
                            type="button"
                            class="btn btn-secondary button-position-down envDownButton"
                            [disabled]="contact.position === contacts.length - 1"
                            (click)="voteContactDown(contact)">
                            <i class="icon-angle-down"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        }
        <div class="orderingArea">
            <div class="component">
                <div class="headline btn">Neuen Ansprechpartner anlegen</div>
                <div class="button">
                    <button class="btn btn-success button-add envAddButton" (click)="addContact()"><i class="icon-plus-circle"></i></button>
                </div>
            </div>
        </div>
    </div>
    }
</div>

@if (accordionData.type === 'dateType') {
<div class="row">
    <div class="col envCommuneList">
        <div class="envDropdownWrapper">
            <div id="customer" class="envDropdownBox" (click)="changeDropdown()">
                <div>
                    {{ selectedItem }}
                    <span class="envDropdownImage" [ngClass]="isDropdownActive ? 'icon-angle-up' : 'icon-angle-down'"></span>
                </div>
            </div>
            <ul class="envDataTableDropdownList" id="envCustomer">
                @for (communeList of communeLists; track communeList) {
                <li (click)="setActiveDropdown(communeList.communeName)" class="envDropdownText">
                    @if (communeList.ags !== '') {
                    <span>{{ communeList.ags }} - </span>
                    }{{ communeList.communeName }}
                </li>
                }
            </ul>
        </div>
    </div>
</div>
} @if (accordionData.type !== 'contactType') {
<div class="row justify-content-md-center">
    <div class="col-3">
        <input type="checkbox" class="form-check-input" [(ngModel)]="accordionData.hasDownloads" name="hasDownloads" />
        <label class="form-check-label">Downloads in der Klappbox</label>
    </div>
</div>
} @if (accordionData.category === 'subjectArea') {
<div>
    <app-adm-subject-area-edit
        [(pageData)]="pageData"
        [(subjectAreaData)]="accordionData.subjectAreas"
        [justEdit]="true"
        [usesInquiryTypes]="false"
        [category]="accordionData.category"
        inquiryTypeList="undefined"
        [newTitle]="'neue Themengruppe anlegen'"></app-adm-subject-area-edit>
</div>
} @if (accordionData.hasDownloads) {
<div [ngClass]="pageData.siteName === 'propertyManagement' ? 'envDownloadsInAccordionSmall' : 'envDownloadsInAccordion'">
    <!--  <app-download-edit-manager [(pageData)]="pageData"  [(downloadHeadline)]="pageData.downloadHeadline" [(downloads)]="pageData.downloads"></app-download-edit-manager> -->
    <app-download-edit-manager
        [(downloadHeadline)]="accordionData.downloadHeadline"
        [(pageData)]="pageData"
        [(downloads)]="accordionData.downloads"></app-download-edit-manager>
</div>
}
