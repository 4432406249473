import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { CommunicationService } from '@services/communication.service';
import { EnviaKeycloakFunctions } from '@auth/classes/EnviaKeycloakFunctions';
import { SachsenEnergieCidaasFunctions } from '@auth/classes/SachsenEnergieCidaasFunctions';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    idpFunctions: any; // Funktionen vom Idp

    constructor(private readonly router: Router, private readonly communcationService: CommunicationService) {
        const idpConfig = environment.idp.idpConfig;
        const idp = environment.idp.idpName;
        const mandant = environment.idp.mandant;

        if (idp === 'keycloak' && mandant === 'enviaM') {
            this.idpFunctions = new EnviaKeycloakFunctions(idpConfig);
        }
        if (idp === 'cidaas' && mandant === 'SachsenEnergie') {
            this.idpFunctions = new SachsenEnergieCidaasFunctions(idpConfig, router, communcationService);
        }
    }

    setAccessToken(accessToken) {
        this.communcationService.setUserBearerToken(accessToken);
    }

    login() {
        this.idpFunctions.login();
    }

    loginCallBack() {
        this.idpFunctions.loginCallBack();
    }

    async isLoggedInRoutine() {
        await this.idpFunctions.isLoggedInRoutine();
    }

    logout() {
        this.idpFunctions.logout();
    }

    logoutAndRoute() {
        this.idpFunctions.logoutAndRoute();
    }

    routeToMainPage() {
        this.idpFunctions.routeToMainPage();
    }

    checkUserPermission(adminType: string[], hasToBeLoggedOut: boolean) {
        return this.idpFunctions.checkUserPermission(adminType, hasToBeLoggedOut);
    }

    startLoginFlow() {
        this.login();
        //this.idpFunctions.afterLoginFlow();
    }

    startLogoutFlow() {
        this.logout();
        //this.idpFunctions.afterLogoutFlow();
    }

    getUserInfo() {
        return this.idpFunctions.getUserInfo();
    }

    getAccessToken() {
        return this.idpFunctions.getAccessToken();
    }

    updateTokenWithTimeSpan(timeSpan?: number) {
        return this.idpFunctions.updateTokenWithTimeSpan(timeSpan);
    }

    updateToken() {
        return this.idpFunctions.updateToken();
    }

    checkTokenValidity() {
        this.idpFunctions.checkTokenValidity();
    }

    isUserLoggedIn() {
        return this.idpFunctions.isUserLoggedIn();
    }

    checkUserLogin() {
        return this.idpFunctions.checkUserLogin();
    }
}
