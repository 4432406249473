import { Component, OnInit, Inject } from '@angular/core';
import { DownloadDocumentService } from '@services/download-document.service';
import { AuthService } from '@auth/auth.service';
import { RestService } from '@services/rest.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { CommunicationService } from 'src/app/shared/services/communication.service';

@Component({
    selector: 'app-modal-agb',
    templateUrl: './modal-agb.component.html',
    styleUrls: ['./modal-agb.component.sass'],
})
export class ModalAgbComponent implements OnInit {
    agb: any = undefined;
    initialLoading: boolean = true;
    isLoading: boolean = false;
    hasNewAgb: boolean = false;
    pId: string = undefined;
    // hasNewDs: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ModalAgbComponent>,
        private readonly authService: AuthService,
        private readonly communicationService: CommunicationService,
        private readonly restService: RestService,
        private readonly toastr: ToastrService,
        private readonly downloadDocumentService: DownloadDocumentService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.hasNewAgb = this.data.agbds.agb;
        this.restService.$myCommunitiesData.subscribe((data: any) => {
            if (data !== undefined) {
                this.pId = data.pId;
                this.initialLoading = false;
            }
        });

        // this.hasNewDs = this.data.agbds.ds;
    }

    downloadDoc(type: string) {
        this.downloadDocumentService.downloadDocument(undefined, type, 'agbDsUser', undefined, 'KomPo');
    }

    acceptNewAgbDs() {
        this.isLoading = true;
        this.restService.newAgbDsAccept(this.data.agbds, this.pId);
        this.restService.$newAgbDsAcceptResponse.subscribe((data: any) => {
            if (data !== undefined) {
                this.isLoading = false;
                if (data.status >= 200 && data.status < 300) {
                    this.toastr.success('Die neuen Richtlinien wurden akzeptiert.', '');
                    this.restService.resetNewAgbDsAcceptResponse();
                    this.closeDialog(201);
                } else {
                    this.toastr.error(data.text, 'Fehlgeschlagen');
                    this.restService.resetNewAgbDsAcceptResponse();
                }
            }
        });
    }

    declineNewAgb() {
        const dialogRef = this.dialog.open(ModalConfirmComponent, {
            width: 'auto',
            disableClose: true,
            data: {
                headline: '',
                text: 'Sie haben die geänderten Allgemeinen Regelungen zur Nutzung des KommunalPortals der enviaM-Gruppe nicht bestätigt.\n Bei Ablehnung der Allgemeinen Regelungen ist kein Zugang zum KommunalPortal der enviaM-Gruppe mehr möglich und Ihre Zugangsdaten werden gelöscht.',
                reasonText: '',
                hasReason: false,
                isReasonMandatory: false,
                sure: true,
                type: '',
                buttonTextNo: 'Nein, abbrechen',
                buttonTextYes: 'Ja, löschen',
                action: 'löschen',
            },
        });

        // auswertung der decision
        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                if (result.decision === true) {
                    this.isLoading = true;
                    this.restService.newAgbDecline(this.pId);
                    this.restService.$newAgbDeclineResponse.subscribe((data: any) => {
                        if (data !== undefined) {
                            this.isLoading = false;
                            if (data.status >= 200 && data.status < 300) {
                                this.toastr.success('Die neuen Richtlinien wurden erfolgreich abgelehnt.', '');
                                this.restService.resetNewAgbDeclineResponse();
                                this.closeDialog(202);
                                this.logoutUser();
                            } else {
                                this.toastr.error(data.text, 'Fehlgeschlagen');
                                this.restService.resetNewAgbDeclineResponse();
                            }
                        }
                    });
                }
            }
        });
    }

    closeDialog(tmpItem: any) {
        this.dialogRef.close(tmpItem);
    }

    async logoutUser() {
        this.communicationService.setLoginStatus(false);
        sessionStorage.clear();
        await this.authService.logout();
    }
}
