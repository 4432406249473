<div class="envDialogWrapper">
  <div class="dialogContentWrapper">
    <div class="envDialogHeadline">{{ title }}</div>
    <p class="dialogText">{{ bodyText }}</p>
    <div class="buttonWrapper">
      @if (!isDecision) {
        <button class="envCloseButton envCenterButton" [ngClass]="buttonClass1" (click)="closeDialog(1)">
          {{ buttonText1 }}
        </button>
      }
      @if (isDecision) {
        <button class="envCloseButton" [ngClass]="buttonClass1" (click)="closeDialog(1)">{{ buttonText1 }}</button>
      }
      @if (isDecision) {
        <button
          class="envPositiveDesicisionButton envStandardButton"
          [ngClass]="buttonClass2"
          (click)="closeDialog(2)">
          {{ buttonText2 }}
        </button>
      }
    </div>
  </div>
</div>
