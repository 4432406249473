import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RestService } from '@services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { DeepCopy } from '@utils/deepCopy';

@Component({
    selector: 'app-adm-modal-subject-area-button',
    templateUrl: './adm-modal-subject-area-button.component.html',
    styleUrls: ['./adm-modal-subject-area-button.component.sass'],
})
export class AdmModalSubjectAreaButtonComponent implements OnInit {
    headline: string = undefined;
    selectedItem: any = undefined;
    item: any = undefined;

    workingSubjectArea: any = undefined;
    originalSubjectArea: any = undefined;

    domElement: HTMLElement;

    isMailValid: boolean = false;
    isMailValidErrorText: string = '';

    constructor(
        public dialogRef: MatDialogRef<AdmModalSubjectAreaButtonComponent>,
        private readonly restService: RestService,
        private readonly toastr: ToastrService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.initializeData();
    }

    initializeData() {
        this.workingSubjectArea = DeepCopy.copy(this.data.item);
        this.originalSubjectArea = this.data.item;
        this.headline = 'Anfrage zur ' + this.data.item.headline;
    }

    // Schließen des Dialogs
    closeDialog(tmpItem: any) {
        this.dialogRef.close(tmpItem);
    }

    checkMail(mail) {
        this.domElement = document.getElementById('mailInput') as HTMLInputElement;
        const errorText = document.getElementById('envMailError') as HTMLInputElement;

        // check if there is whitespace in mail (KOM-194)
        if (mail.indexOf(' ') !== -1) {
            this.isMailValid = false;
            errorText.style.visibility = 'visible';
            errorText.style.display = 'block';
            this.isMailValidErrorText = 'Die angegebene E-Mail enthält ein Leerzeichen.';

            return;
        }

        if (mail.match('.+[@]{1}.+[.]{1}.+') === null) {
            this.isMailValid = false;
            errorText.style.visibility = 'visible';
            errorText.style.display = 'block';
            this.isMailValidErrorText = 'Die angegebene E-Mail ist nicht valide.';
        }

        // check if mail exists in KeyCloak
        if (mail.length < 6) {
            this.isMailValid = false;
            errorText.style.visibility = 'visible';
            errorText.style.display = 'block';
            this.isMailValidErrorText = 'Die angegebene E-Mail ist zu kurz.';
        }

        if (mail.length > 5 && mail.length <= 255 && mail.match('.+[@]{1}.+[.]{1}.+') !== null) {
            this.isMailValid = true;
            errorText.style.visibility = 'hidden';
            errorText.style.display = 'none';
            this.isMailValidErrorText = '';
        }
    }

    setSelectetItems(selectetItems) {
        for (const inquiryType of this.workingSubjectArea.inquiryTypes) {
            inquiryType.checked = false;
        }
        for (const inquiryType of this.workingSubjectArea.inquiryTypes) {
            for (const selectetItem of selectetItems) {
                if (selectetItem.id === inquiryType.id) {
                    inquiryType.checked = true;
                }
            }
        }
    }

    isModalValid() {
        let isModalValid = false;
        let isInqueryTypeChecked = false;
        for (const inqueryType of this.workingSubjectArea.inquiryTypes) {
            if (inqueryType.checked) {
                isInqueryTypeChecked = true;
            }
        }
        isModalValid = isInqueryTypeChecked && this.isMailValid ? true : false;

        return isModalValid;
    }

    getSelectedIndices() {
        const selectedIndices: any = [];
        for (let i = 0; i < this.workingSubjectArea.inquiryTypes.length; i += 1) {
            if (this.workingSubjectArea.inquiryTypes[i].checked) {
                selectedIndices.push(i);
            }
        }

        return selectedIndices;
    }

    // alle informationen des Formulares speichern
    saveFormular() {
        if (this.isModalValid()) {
            this.dialogRef.close(this.workingSubjectArea);
        }
    }
}
