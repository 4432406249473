import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { KeycloakProfile } from 'keycloak-js';

import { ModalService } from '@services/modal.service';
import { AuthService } from '@auth/auth.service';
import { CommunicationService } from '@services/communication.service';
import { RestService } from '@services/rest.service';
import { ModularizationService } from '@services/modularization.service';
import { SiteDataService } from '@services/site-data.service';

import { envUserData } from '@classes/envUserData';
import { envDeliveryPointItem } from '@classes/envDeliveryPointItem';
import { envModuleList } from '@classes/envModuleList';
import { envCommunity } from '@classes/envCommunity';

import { Navigation } from '@utils/navigation';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';

import { ModalAgbComponent } from '@modals/modal-agb/modal-agb.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { ModalRegistrationComponent } from '@modals/modal-registration/modal-registration.component';
import { ModalInfoComponent } from '@modals/modal-info/modal-info.component';
import { ModalPromptComponent } from '@modals/modal-prompt/modal-prompt.component';
import { ModalCommunitiesComponent } from '@modals/modal-communities/modal-communities.component';
import { ResponsiveService } from '@services/responsive.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {
    // Variablen
    private subscriptionUserData: Subscription;
    private activeCommunitySubscription: Subscription;

    // User Variablen
    userDetails: KeycloakProfile;
    userData: envUserData = undefined;
    username: string = '';
    userEmailAddress: string = '';
    userRoles: string[] = [];
    completeName: string = '';

    // Kommunen Variablen
    myCommunities: string[] = [];
    activeCommune: string = '';
    currCommuneData: envCommunity[] = undefined;
    communeAGS: string = '';
    kpId: string = '';

    // Boolean Variablen
    currentlyLoggedIn: boolean = false;
    isViewBlocked: boolean = true;
    menuPageBlocker: boolean = false;
    isCommuneDropdownActive: boolean = false;
    isModalDropdownActive: boolean = false;
    isMenuSubItemActive: boolean = false;
    showLoadingIndicator: boolean = false;
    isUserAdmin: boolean = false;
    menuOpen: boolean = false;
    disableRegistrationButton: boolean = false;

    tokenTimer: number;
    forgottenItems: envDeliveryPointItem[] = undefined;
    menuItems: any[] = [];
    incidentMail = environment.incidentMail;
    topNavBarInfoText = environment.topNavBarInfoText;
    topNavBarInfoUrl = environment.topNavBarInfoUrl;
    footerBar = environment.footerBar;
    domElement: HTMLElement;
    private userBearerToken: string = '';

    getIdsFromServicesSubscription: Subscription;
    moduleListSubscription: Subscription;

    //Responsive Variables
    showLogoutTimerBar = undefined;
    showLogoutIcon = undefined;
    showCompanyLogo = undefined;
    centerLogoutAndCommmuneSelect = undefined;
    logoutButtonWithMargin = undefined;
    isMobilePortraitView = undefined;
    isMobileLandscapeView = undefined;
    isTabletPortraitView = undefined;
    isDesktopLandscapeView = undefined;
    isTabletLandscapeView = undefined;
    showNavigationMenu = undefined;
    showTopNavbar = undefined;

    constructor(
        public toastr: ToastrService,
        private readonly authService: AuthService,
        private readonly modalService: ModalService,
        private readonly restService: RestService,
        private readonly communicationService: CommunicationService,
        private readonly modService: ModularizationService,
        public dialog: MatDialog,
        private readonly siteDataService: SiteDataService,
        private readonly router: Router,
        private responsive: ResponsiveService
    ) {}

    async ngOnInit() {
        // Wenn der Nutzer nach 5 Minuten für weitere 25 Minuten nicht mehr klickt --> abmelden
        document.addEventListener('click', (_) => {
            if (this.tokenTimer < 25) {
                this.resetTimer();
            }
        });

        // this.authService.checkUserLogin();
        // this.communicationService.isLoggedIn$.subscribe((res) => {
        //     console.log(res);
        // });

        //Prüfe ob Nutzer eingeloggt ist
        await this.authService.isLoggedInRoutine();

        //Responsivität
        this.setResponsiveVariables();

        // Subscribe auf Viewblock
        this.getViewBlock();

        // Subscription auf Router initialisieren
        this.siteDataService.initializeRouteCheck();

        // Wenn Passwort vor Aufruf der Seite geändert wurde -> erneut Einloggen und Sessionstorage zurücksetzen
        if (sessionStorage.getItem('wasPasswordChanged') === 'true') {
            sessionStorage.setItem('wasPasswordChanged', 'false');
            this.authService.login();
        }

        // Wenn im vorherigen Aufruf ein Fehler aufgetreten ist -> Wartungsseite aufrufen und Modale schließen
        if (sessionStorage.getItem('errorTypeHandled') !== null || sessionStorage.getItem('errorTypeUnhandled')) {
            this.modalService.close('envLoadingModal');
            this.router.navigate(['/wartung']);
        } else {
            // Falls der User schon eingeloggt ist, Details laden, abgesofteten Bereich entfernen, LoggingService mitteilen
            if (await this.authService.isUserLoggedIn()) {
                // Daten initialisieren
                this.initializeRestData();
            } else {
                this.loggedOutRouting();
            }
            this.communicationService.setBlockerStatus(true);
        }
    }

    loggedOutRouting() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Im Fall von registierungbestaetigen URL-Parameter für Überprüfung bereinigen
                let href = this.router.url;
                if (href.includes('?')) {
                    href = href.substring(0, href.indexOf('?'));
                }

                // Im Ausgeloggten Zustand nur meinekommune und zugangsdatenvergessen zulassen und email verifizieren
                if (
                    this.router.url !== '/meinekommune' &&
                    this.router.url !== '/zugangsdatenvergessen' &&
                    this.router.url !== '/wartung' &&
                    href !== '/registierungbestaetigen' &&
                    this.router.url.indexOf('/verifyMail') === -1 &&
                    this.router.url.indexOf('/verifyMail?key=') === -1
                ) {
                    this.router.navigate(['/meinekommune']);
                }

                // Browser ermitteln und OfflineContent abfragen
                this.startBrowserDetection();
                this.menuItems = Navigation.getOfflineNavigation();
            }
        });
    }

    // beenden aller subscription beim destroy
    ngOnDestroy() {
        if (this.getIdsFromServicesSubscription) {
            this.getIdsFromServicesSubscription.unsubscribe();
        }
        if (this.moduleListSubscription) {
            this.moduleListSubscription.unsubscribe();
        }
    }

    setResponsiveVariables() {
        this.responsive.$showLogoutTimerBar.subscribe((data) => {
            if (data !== undefined) {
                this.showLogoutTimerBar = data;
            }
        });
        this.responsive.$centerLogoutAndCommmuneSelect.subscribe((data) => {
            if (data !== undefined) {
                this.centerLogoutAndCommmuneSelect = data;
            }
        });
        this.responsive.$showLogoutIcon.subscribe((data) => {
            if (data !== undefined) {
                this.showLogoutIcon = data;
            }
        });
        this.responsive.$logoutButtonWithMargin.subscribe((data) => {
            if (data !== undefined) {
                this.logoutButtonWithMargin = data;
            }
        });
        this.responsive.$isMobilePortraitView.subscribe((data) => {
            if (data !== undefined) {
                this.isMobilePortraitView = data;
            }
        });
        this.responsive.$isMobileLandscapeView.subscribe((data) => {
            if (data !== undefined) {
                this.isMobileLandscapeView = data;
            }
        });
        this.responsive.$isTabletPortraitView.subscribe((data) => {
            if (data !== undefined) {
                this.isTabletPortraitView = data;
            }
        });
        this.responsive.$isTabletLandscapeView.subscribe((data) => {
            if (data !== undefined) {
                this.isTabletLandscapeView = data;
            }
        });
        this.responsive.$isDesktopLandscapeView.subscribe((data) => {
            if (data !== undefined) {
                this.isDesktopLandscapeView = data;
            }
        });
        this.responsive.$showCompanyLogo.subscribe((data) => {
            if (data !== undefined) {
                this.showCompanyLogo = data;
            }
        });
        this.responsive.$showTopNavbar.subscribe((data) => {
            if (data !== undefined) {
                this.showTopNavbar = data;
            }
        });
    }

    // Zeit der Session und Token zurücksetzten
    resetTimer() {
        this.tokenTimer = 30;
        this.resetAccessToken();
    }

    // Zum ViewBlock subscriben
    getViewBlock() {
        this.communicationService.isViewBlocked$.subscribe((blockedView) => {
            this.isViewBlocked = blockedView;
        });
    }

    // GemIS Daten zu der vom UserData zurückgelieferten ID abfragen / Weiterleitung an Variableninitialisierung
    async initializeRestData() {
        // Nutzer Details + Token von Service holen
        this.userDetails = await this.authService.getUserInfo();
        this.userBearerToken = this.authService.getAccessToken();
        this.communicationService.setUserBearerToken(this.userBearerToken); // BearerToken an Service geben
        this.communicationService.setLoginStatus(true);
        this.restService.getUserBearerToken(); // Subscribe auf Token initialisieren

        // Blocker entfernen
        this.communicationService.setBlockerStatus(false);
        // Abspeichern der Usernamen aus IDP Details
        this.username = this.userDetails.email;
        this.userEmailAddress = this.userDetails.email;

        // Rollen zu einem User abfragen
        this.restService.getUserRoles();
        await this.getModuleList(); // Modulliste von Backend holen

        this.restService.$userRoles.subscribe((roleData) => {
            this.openModal('envLoadingModal'); // Lademodal öffnen

            if (roleData !== undefined) {
                // Wenn Rollen vorhanden
                if (roleData.length !== 0) {
                    // Wenn nur Rolle "Nutzer" vorhanden
                    if (roleData.length === 1 && roleData[0] === 'Nutzer') {
                        this.checkAgbDs(); // AgbDS abfragen
                    } else if (
                        roleData[0] === 'Lieferstellenredakteur' ||
                        roleData[0] === 'KomPo-Administrator' ||
                        roleData[0] === 'KomPo-Superadministrator'
                    ) {
                        // ansonsten Adminaussteuerung
                        this.userRoles = roleData; // Rollen speichern
                        this.isUserAdmin = true; // Adminstatus setzen
                        const fullname = this.userDetails.firstName + ' ' + this.userDetails.lastName; // Namen setzen

                        // Daten an CommunicationService weiterreichen
                        this.communicationService.setAdmFullName(fullname);
                        this.communicationService.setUserGroups(this.userRoles);

                        this.initializeValues();

                        // Auf Adminbereich weiterleiten
                        this.closeModal('envLoadingModal');
                        this.router.navigate(['/admin/cockpit']);
                    } else {
                        // Kenn keine der genannten Rollen vorhanden Fehler werfen
                        this.closeModal('envLoadingModal');
                        sessionStorage.setItem('errorTypeHandled', 'unknownUserRole');
                        this.router.navigate(['/wartung']);
                    }
                } else {
                    // Wenn keine Rollen vorhanden Fehler werfen
                    this.closeModal('envLoadingModal');
                    sessionStorage.setItem('errorTypeHandled', 'noUserRole');
                    this.router.navigate(['/wartung']);
                }
            }
        });
    }

    // prüft ob nutzer agb oder ds noch zustimmen muss
    checkAgbDs() {
        // MyCommunities Daten holen
        this.restService.getMyCommunitiesList();
        this.restService.$myCommunitiesData.subscribe((comuneData) => {
            if (comuneData !== undefined) {
                this.currCommuneData = comuneData;
                // AbgDs Status abfragen
                this.restService.hasNewAgbDs();
                this.restService.$hasNewAgbDsResponse.subscribe((data: any) => {
                    if (data !== undefined) {
                        // wenn neue AgbDs vorhanden Modal öffnen (true heißt neue agb vorhanden)
                        if (data.agb) {
                            this.openAgbModal(data);
                        } else {
                            this.controlCommunityFlow();
                        }
                        this.restService.resetNewAgbDs();
                    }
                });
            }
        });
    }

    // Modal öffnen
    openModal(id: string) {
        this.modalService.open(id);
    }

    // Modal schließen
    closeModal(id: string) {
        this.modalService.close(id);
    }

    // Je Nach Fall aktive Kommune setzten, oder Modal öffnen
    async controlCommunityFlow() {
        // Keine Kommunen vorhanden
        if (this.currCommuneData.length === 0) {
            // Fehlerseite mit KF23
            this.closeModal('envLoadingModal');
            sessionStorage.setItem('errorTypeHandled', 'noCommunesExist');
            await this.authService.logout();
            // this.router.navigate(['/wartung']);
        } else {
            // Ausgelesene Kommunen in Array pushen
            for (const currCommune of this.currCommuneData) {
                this.myCommunities.push(currCommune.name);
            }

            // Modal öffen, wenn mehr als eine Kommune verwaltet wird und Sessionstorage leer ist
            if (this.myCommunities.length > 1 && (sessionStorage.getItem('commune') === null || sessionStorage.getItem('commune') === '')) {
                this.closeModal('envLoadingModal');
                this.showLoadingIndicator = true;
                this.openCommunitiesDialog();
            } else if (this.myCommunities.length === 1) {
                // Bei nur einer Kommune diese als aktiv setzen und Daten initialisieren
                this.activeCommune = this.myCommunities[0];
                this.initializeValues();
            } else {
                // Ansonsten Daten aus sessionStorage abfragen und setzen
                this.activeCommune = sessionStorage.getItem('commune');
                this.communeAGS = sessionStorage.getItem('ags');
                this.initializeValues();
            }
        }
    }

    // Modal zur Auswahl einer initialen Kommune
    openCommunitiesDialog() {
        // Aufruf Modal mit Liste an Kommunen
        const dialogRef = this.dialog.open(ModalCommunitiesComponent, {
            width: 'auto',
            disableClose: true,
            data: {
                myCommunities: this.myCommunities,
            },
        });
        // Auswahl auswerten
        dialogRef.afterClosed().subscribe((item) => {
            if (item !== undefined) {
                // aktive Kommune setzten und Initialisierung fortsetzen
                this.activeCommune = item;
                if (this.activeCommune !== '') {
                    this.initializeValues();
                    this.showLoadingIndicator = false;
                }
            } else {
                // Ausloggen, wenn keine Kommune ausgewählt wurde
                this.logout();
            }
        });
    }

    // ModuleList von Backend holen und an Service weitergeben
    async getModuleList() {
        this.restService.getModuleList();
        this.restService.$moduleList.subscribe((list: envModuleList) => {
            if (list !== undefined) {
                this.modService.setModuleList(list);
                this.modService.initializeSessionStorage();
            }
        });
    }

    // Lokale Variablen initialisieren
    initializeValues(): void {
        this.openModal('envLoadingModal');
        this.getForgottenDpItems(); // Nicht abgeschickte Lieferstellendaten überprüfen
        this.isViewBlocked = false; // Blocker entfernen
        this.tokenTimer = 30; // TokenTimer initialisieren
        this.currentlyLoggedIn = true; // LogIn Status setzen

        // Usernamen und Email an Service für andere Komponenten mitgeben
        this.communicationService.setUserEmail(this.userEmailAddress);

        const userGroups = this.communicationService.getUserGroups();
        this.moduleListSubscription = this.modService.$moduleList.subscribe((data) => {
            if (data !== undefined) {
                const currModules = data;
                // switch navbar by user group:
                // group: Lieferstellenredakteur
                if (
                    userGroups.indexOf('Lieferstellenredakteur') > -1 ||
                    userGroups.indexOf('KomPo-Administrator') > -1 ||
                    userGroups.indexOf('KomPo-Superadministrator') > -1
                ) {
                    this.menuItems = Navigation.buildAdminNavigation(userGroups, currModules);
                    // Admindaten laden
                    // this.restService.getAdminUserData(this.kpId, false);
                    // this.restService.resetAdminUserData();

                    this.restService.getListOfAllCommunes();
                } else {
                    this.communicationService.setActiveCommuneName(this.activeCommune); // Aktive Kommune an Service weitergeben
                    this.setActiveCommuneDataInService(); // Daten der Aktiven Kommune setzten

                    // Hambuerger/Home Menü Punkte
                    this.menuItems = Navigation.getNavigation(currModules);
                }

                this.closeModal('envLoadingModal');

                // TokenTimer runterzählen, bei 0 User abmelden und alle 5 Minuten Token resetten
                setInterval((_) => {
                    this.tokenTimer -= 1;
                    if (this.tokenTimer === 0) {
                        this.logout();
                    }
                }, 60000);
            }
        });
    }

    // Daten der aktiven Kommune an Service übermitteln
    setActiveCommuneDataInService(forceCacheUpdate: boolean = false) {
        for (const currCommune of this.currCommuneData) {
            // Wenn bei Iteration über alle Kommunen die aktive gefunden wurde
            if (currCommune.name === this.activeCommune) {
                // Daten von Backend holen
                this.restService.getMyCommunitiesData(currCommune.kpId, currCommune.ags);
                if (!this.activeCommunitySubscription) {
                    this.activeCommunitySubscription = this.restService.$activeCommunityData.subscribe((data: any) => {
                        if (data !== undefined) {
                            if (data.kpId) {
                                this.kpId = data.kpId;
                                const activeCommune = data;
                                // Kommunendaten und AGS und kpId an communicationService weiterleiten
                                this.communicationService.setActiveCommuneData(activeCommune);
                                this.communicationService.setActiveCommuneAGS(activeCommune.ags);
                                this.communicationService.setActiveCommuneKpId(this.kpId);

                                this.communeAGS = activeCommune.ags; // derzeitigen AGS setzten
                                sessionStorage.setItem('commune', this.activeCommune); // Aktive Kommune im sessionstorage abspeichern
                                sessionStorage.setItem('ags', this.communeAGS); // Aktive ags im sessionstorage abspeichern
                                this.initializeUserData();
                                this.showLoadingIndicator = false;
                                if (forceCacheUpdate) {
                                    this.forceCacheUpdate(); //lädt die onlinedata Schnistlle neu (force Cache Update) damit nach kommunenwechsel die richtige ags gezogen werden kann
                                }
                            } else {
                                this.toastr.error('Es gab einen Fehler, bitte versuchen Sie es später erneut', data.text);
                                sessionStorage.setItem('errorTypeUnhandled', 'myCommunities');
                                this.authService.logoutAndRoute(); // Bei Fehlern Nutzer ausloggen
                            }
                        }
                    });
                }
            }
        }
    }

    // Falls Lieferstellenänderungen nicht zur Prüfung gesendet wurden hier abfangen
    getForgottenDpItems() {
        this.communicationService.$forgottenItems.subscribe((data: envDeliveryPointItem[]) => {
            if (data !== undefined) {
                this.forgottenItems = data;
                this.openForgotToSendChangesModal(); // Modal öffnen
                data = undefined;
            }
        });
    }

    // Sendet die vergessenen Lieferstellenänderungen an das Backend
    sendForgottenItems() {
        this.restService.sendDeliveryPointChanges(this.forgottenItems, this.communeAGS, this.encodeString(this.activeCommune));
        this.forgottenItems = undefined;
    }

    // String in Base64 umwandeln
    encodeString(name: string): string {
        return btoa(encodeURIComponent(name));
    }

    // Nutzerdaten zum angemeldeten Nutzer abfragen
    initializeUserData() {
        // Daten zum Usernamen abfragen
        this.restService.getUserData(this.kpId, false);
        this.subscriptionUserData = this.restService.$userData.subscribe((data: any) => {
            if (data !== undefined) {
                this.userData = data;
                this.completeName =
                    this.userData.salutation + ' ' + this.userData.title + ' ' + this.userData.firstname + ' ' + this.userData.lastname; // kompletten Usernamen speichern
                // Daten an communicationService weitergeben
                this.communicationService.setUserData(data);
                this.communicationService.setFekData(data.fek);
                this.communicationService.setBankData(data.bankdaten);

                this.restService.resetUserData(); // Subject zurücksetzen
                this.subscriptionUserData.unsubscribe(); // Unsubscribe auf userData
                this.closeModal('envLoadingModal');
            }
        });
    }

    // Bei Klick in Kommunendropdown aktive Kommune anpassen
    setActiveCommune(clickedCommune: string, location: string) {
        this.showLoadingIndicator = true;
        this.authService.checkTokenValidity(); // Token überprüfen
        this.activeCommune = clickedCommune; // geklickte Kommune als aktiv setzen
        this.communicationService.setActiveCommuneName(clickedCommune); // aktive Kommune an Service weiterleiten
        this.setActiveCommuneDataInService(true); // Daten der Kommune holen
        this.changeDropdown(location);
    }

    //lädt die onlinedata Schnistlle neu (force Cache Update) damit nach kommunenwechsel die richtige ags gezogen werden kann
    forceCacheUpdate() {
        this.siteDataService.forceCacheUpdate(); // bei Kommunen Wechsel den SiteDataService Cache zwingen neu zu laden durch reset
    }

    // Dropdown schließen / öffnen
    changeDropdown(location: string) {
        // Je nach Dropdown unterschiedliche Variable setzten
        switch (location) {
            case 'commune':
                this.isCommuneDropdownActive = !this.isCommuneDropdownActive;
                break;
            case 'modal':
                this.isModalDropdownActive = !this.isModalDropdownActive;
                break;
            default:
        }
    }

    // Status der Burgermenüs ändern
    changeBurgerMenu() {
        this.menuOpen = !this.menuOpen;
        this.menuPageBlocker = this.menuOpen;
        this.domElement = document.getElementById('envNavgation');

        if (this.menuOpen) {
            this.domElement.classList.remove('inactiveMenu');
            this.domElement.classList.add('activeMenu');
        } else {
            this.domElement.classList.remove('activeMenu');
            this.domElement.classList.add('inactiveMenu');
        }
    }

    // Logik zum Anzeigen von Submenü-Items des Burgermenüs
    changeMenuSubItem(menuItem, link) {
        this.isMenuSubItemActive = !this.isMenuSubItemActive;
        let domElementUl: HTMLElement = document.getElementById(menuItem);
        const domElementIcon: HTMLElement = document.getElementById(menuItem + 'Icon');

        if (this.isMenuSubItemActive) {
            domElementUl.classList.remove('inactiveSubMenu');
            domElementUl.classList.add('activeSubMenu');
            domElementUl = document.getElementById(link);
            domElementUl.classList.add('envMenuNavbarItemActive');
            domElementIcon.classList.add('icon-chevron-up');
            domElementIcon.classList.remove('icon-chevron-down');
        } else {
            domElementUl.classList.remove('activeSubMenu');
            domElementUl.classList.add('inactiveSubMenu');
            domElementUl = document.getElementById(link);
            domElementUl.classList.remove('envMenuNavbarItemActive');
            domElementIcon.classList.remove('icon-chevron-up');
            domElementIcon.classList.add('icon-chevron-down');
        }
    }

    // Token erneuern
    resetAccessToken() {
        this.authService.updateTokenWithTimeSpan(1800).catch((_) => {
            // alert('Failed to refresh the token, or the session has expired');
        });

        this.userBearerToken = this.authService.getAccessToken().token;
        this.communicationService.setUserBearerToken(this.userBearerToken); // BearerToken an Service geben
    }

    // Prüfung, ob der Abmeldebutton angezeigt werden soll
    isLogoutAllowed() {
        // Wenn Nutzer im Adminbereich Lieferstellenlisten anpasst
        if (this.router.url === '/admin/lieferstellenlisten/einzelkommunenansicht') {
            const clusterId = this.communicationService.getClusterId(); // ClusterID für Lieferstellen abfragen
            if (clusterId !== '') {
                this.restService.setUpdatedClusterInDb(clusterId, 'unlock'); // Cluster bei Abmeldung freigeben
                this.restService.$updateClusterBlockResponse.subscribe((data) => {
                    if (data !== undefined) {
                        this.communicationService.setClusterId('');
                        this.logout(); // Wenn kein Fehler abmelden
                    }
                });
            } else {
                this.logout();
            }
        } else {
            this.logout();
        }
    }

    // Ausloggen vom Keycloak und sessionstorage leeren
    logout() {
        this.authService.logoutAndRoute();
    }

    // Einloggen
    async login() {
        await this.authService.login();
    }

    // Prüfung ob übergebener Link derzeitige Seite ist
    checkCurrentActivePage(link) {
        return link === this.router.url;
    }

    // FEK Formular Dialog öffnen
    openAgbModal(data: any) {
        const dialogRef = this.dialog.open(ModalAgbComponent, {
            width: 'auto',
            disableClose: true,
            data: {
                dialogHeadline: '',
                dialogText: '',
                type: '',
                agbds: data,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                if (result === 201) {
                    this.controlCommunityFlow(); // Kommunenaussteuerung aufrufen
                } else {
                    this.logout();
                }
            }
        });
    }

    // DIGAN Formular Dialog öffnen
    openModalRegistration() {
        const dialogRef = this.dialog.open(ModalRegistrationComponent, {
            width: 'auto',
            disableClose: true,
            data: {
                dialogHeadline: '',
                dialogText: '',
                type: 'registration',
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                if (result === 201) {
                    this.disableRegistrationButton = true;
                    this.openModalRegistrationResponse();
                }
            }
        });
    }

    // DIGAN Formular Dialog öffnen
    openModalRegistrationResponse() {
        const dialogRef = this.dialog.open(ModalInfoComponent, {
            width: 'auto',
            disableClose: true,
            data: {
                headline: 'Antrag auf Anmeldung',
                bodyText: 'Ihre Anmeldung für das KommunalPortal der enviaM-Gruppe haben wir erhalten und prüfen diese.',
                buttonText: 'OK',
            },
        });
    }

    // Vergessene Lieferstellenänderungen Dialog öffnen
    openForgotToSendChangesModal() {
        const dialogRef = this.dialog.open(ModalPromptComponent, {
            width: 'auto',
            disableClose: true,
            data: {
                title: 'Lieferstellenänderungen',
                bodyText: 'Sie haben Ihre Lieferstellenänderungen noch nicht an die enviaService zur Prüfung gesendet.',
                buttonText1: 'Abbrechen',
                buttonClass1: 'envCloseModalButton',
                buttonText2: 'Änderungen zur Prüfung senden',
                buttonClass2: 'envSendChangeButton',
                isDecision: true,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                switch (result) {
                    case 1:
                        this.forgottenItems = undefined;
                        break;
                    case 2:
                        this.sendForgottenItems(); // Bei Bestätigung Änderungen senden
                        break;
                    default:
                }
            }
        });
    }

    // Genutzten Browser ermitteln / Ausgabe Hinweis
    startBrowserDetection() {
        if (navigator.vendor !== undefined && navigator.vendor.indexOf('Google Inc.')) {
            const dialogRef = this.dialog.open(ModalInfoComponent, {
                width: 'auto',
                disableClose: true,
                data: {
                    headline: 'Information',
                    bodyText: ' Das KommunalPortal wurde für den Google Chrome Browser optimiert.',
                    buttonText: 'Schließen',
                },
            });
        }
    }

    // Scroll bei Aufruf im router outlet anpassen
    // onActivate(event) {
    //     const scrollToTop = window.setInterval(() => {
    //         const pos = window.pageYOffset;
    //         if (pos > 0) {
    //             window.scrollTo(0, pos - 200); // how far to scroll on each step
    //         } else {
    //             window.clearInterval(scrollToTop);
    //         }
    //     }, 16);
    // }
}
